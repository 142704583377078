import {
  VOLUNTEER_REPORT,
  FACILITATOR_REPORT,
  CBTBC_REPORT,
  AIS_REPORT,
  VOT_REPORT,
} from "../type";

const initialState = {
  volunteerReport: {},
  facilitatorReport: {},
  CBTBCReport: {},
  AISReport: {},
  VOTReport: [],
};

const report = (state = initialState, action) => {
  switch (action.type) {
    case VOLUNTEER_REPORT:
      return {
        ...state,
        volunteerReport: action.payload.data,
      };
    case FACILITATOR_REPORT:
      return {
        ...state,
        facilitatorReport: action.payload.data,
      };
    case CBTBC_REPORT:
      return {
        ...state,
        CBTBCReport: action.payload.data,
      };
    case AIS_REPORT:
      return {
        ...state,
        AISReport: action.payload.data,
      };
    case VOT_REPORT:
      return {
        ...state,
        VOTReport: action.payload.data,
      };
    default:
      return state;
  }
};

export default report;
