import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import {
  Button,
  Form,
  Row,
  Col,
  CardBody,
  CardFooter,
  CardTitle,
} from 'reactstrap'
import CustomSelect from 'components/Inputs/CustomSelect'
import { dones } from 'variables/options'
import CustomDateInput from 'components/Inputs/CustomDateInput'
import { currentDate } from 'utils/currentDate'
import { sputumCultureResults } from 'variables/options'
import { connect } from 'react-redux'
import { updatePatient } from 'store/actions'
import { useParams } from 'react-router'
import { useQuery } from 'hooks/useQuery'

const SputumCulture = ({ patient, updatePatient }) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm()

  const { id } = useParams()

  const fromTBPatient = useQuery("from_TB_patient");

  const [loading, setLoading] = useState(false)
  const [sputumCultureDoneNotDone, setSputumCultureDoneNotDone] = useState(null)
  const [sputumCultureResult, setSputumCultureResult] = useState(null)

  useEffect(() => {
    const {
      sputum_culture_done_or_not_done,
      sputum_culture_examination_date,
      sputum_culture_result,
    } = patient.patient

    setValue(
      'sputum_culture_done_not_done',
      sputum_culture_done_or_not_done === null
        ? null
        : {
            value: sputum_culture_done_or_not_done,
            label: sputum_culture_done_or_not_done,
          },
    )
    setSputumCultureDoneNotDone(
      sputum_culture_done_or_not_done === null
        ? null
        : {
            value: sputum_culture_done_or_not_done,
            label: sputum_culture_done_or_not_done,
          },
    )
    setValue('sputum_culture_examination_date', sputum_culture_examination_date)
    setValue(
      'sputum_culture_result',
      sputum_culture_result === null
        ? null
        : { value: sputum_culture_result, label: sputum_culture_result },
    )
    setSputumCultureResult(
      sputum_culture_result === null
        ? null
        : { value: sputum_culture_result, label: sputum_culture_result },
    )
  }, [setValue, patient.patient])

  const onSubmit = async (data) => {
    setLoading(true)
    await updatePatient(
      id,
      {
        sputum_culture_done_or_not_done:
          data.sputum_culture_done_not_done?.value,
        sputum_culture_examination_date:
          data.sputum_culture_done_not_done?.value === 'Not Done'
            ? null
            : data.sputum_culture_examination_date,
        sputum_culture_result:
          data.sputum_culture_done_not_done?.value === 'Not Done'
            ? null
            : data.sputum_culture_result?.value,
      },
      'sputum-culture',
      'Sputum Culture',
    )
    setLoading(false)
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <CardBody>
        <CardTitle>
          <h3>Sputum Culture Examination</h3>
        </CardTitle>
        <Row>
          <Col md={4}>
            <CustomSelect
              id="sputum_culture_done_not_done"
              label="Sputum Culture Done or Not Done"
              control={control}
              rules={{
                required: 'Sputum Culture Done or Not Done is required!',
              }}
              options={dones}
              value={sputumCultureDoneNotDone}
              setData={setSputumCultureDoneNotDone}
              placeholder="Sputum Culture Done or Not Done"
              isRequired={true}
            />
          </Col>
          <Col md={4}>
            <CustomDateInput
              id="sputum_culture_examination_date"
              label="Sputum Culture Examination Date"
              register={{
                ...register('sputum_culture_examination_date', {
                  required:
                    sputumCultureDoneNotDone?.value === 'Done'
                      ? 'Sputum Culture Examination Date is required!'
                      : false,
                }),
              }}
              placeholder="Sputum Culture Examination Date"
              errors={errors}
              max={currentDate()}
              disabled={
                sputumCultureDoneNotDone === null ||
                sputumCultureDoneNotDone?.value !== 'Done'
              }
              isRequired={sputumCultureDoneNotDone?.value === 'Done'}
            />
          </Col>
          <Col md={4}>
            <CustomSelect
              id="sputum_culture_result"
              label="Sputum Culture Result"
              control={control}
              rules={{
                required:
                  sputumCultureDoneNotDone?.value === 'Done'
                    ? 'Sputum Culture Result is required!'
                    : false,
              }}
              options={sputumCultureResults}
              value={sputumCultureResult}
              setData={setSputumCultureResult}
              placeholder="Sputum Culture Result"
              isRequired={sputumCultureDoneNotDone?.value === 'Done'}
              isDisabled={
                sputumCultureDoneNotDone === null ||
                sputumCultureDoneNotDone?.value !== 'Done'
              }
            />
          </Col>
        </Row>
      </CardBody>
      <CardFooter className="text-right">
      {fromTBPatient && (
          <Button color="primary" size="sm" disabled={loading} type="submit">
            {loading ? "Loading" : "Update"}
          </Button>
        )}
      </CardFooter>
    </Form>
  )
}

const mapStateToProps = (store) => ({
  patient: store.patient,
})

export default connect(mapStateToProps, { updatePatient })(SputumCulture)
