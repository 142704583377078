import React, { useEffect, useState } from "react";
import Select from "react-select";
import CustomTable from "components/Table/Table";
import { Button, Card, CardHeader, Container, Row, Col } from "reactstrap";
import { useHistory } from "react-router";
import { connect } from "react-redux";
import { getTownships, getVolunteers, getPatients } from "store/actions";
import FullScreenLoading from "components/FullScreenLoading/FullScreenLoading";
import {
  patientMiddleware,
  isVolunteerSupervisor,
  EXPORT,
} from "utils/middleware";
import BackBtn from "utils/backBtn";
import queryString from "query-string";
import CustomPagination from "components/Pagination/Pagination";
import { paginationLimit } from "variables/limits";
import { excelExport } from "utils/excelExport";
import { NotificationManager } from "react-notifications";
import { patientExport } from "utils/patientExport";
import { call } from "services/api";
import { DOTWorking } from "utils/DOTWorking";
import { host } from "services/api";

const DSTBScreenedPatientList = ({
  status,
  auth,
  township,
  patient,
  getPatients,
  getTownships,
  getVolunteers,
}) => {
  const { role } = auth.user;

  const router = useHistory();

  const [exportLoading, setExportLoading] = useState(false);
  const [exportDOTsLoading, setExportDOTsLoading] = useState(false);

  const [selectedPatient, setSelectedPatient] = useState(null);
  const [selectedTownship, setSelectedTownship] = useState(null);
  const [selectedProject, setSelectedTProject] = useState(null);

  const patients = patient.patients.map((element) => ({
    value: element.id,
    label: element.name,
  }));

  const townships = township.townships.map((element) => ({
    value: element.id,
    label: element.name,
  }));

  const handleFilter = () => {
    const query = queryString.parse(router.location.search);
    query.page = 1;
    if (selectedPatient) {
      query.patient_id = selectedPatient.value;
    } else {
      delete query["patient_id"];
    }

    if (selectedTownship) {
      query.township_id = selectedTownship.value;
    } else {
      delete query["township_id"];
    }

    if (selectedProject) {
      query.project = selectedProject.value;
    } else {
      delete query["project"];
    }

    router.push(`${router.location.pathname}?${queryString.stringify(query)}`);
  };

  useEffect(() => {
    const query = queryString.parse(router.location.search);
    query.screened = true;
    if (!("page" in query)) {
      query.page = 1;
    }
    getPatients(query);
  }, [getPatients, router.location.search]);

  useEffect(() => {
    if (role !== undefined && !isVolunteerSupervisor(role)) {
      getTownships();
    }
  }, [getTownships, role]);

  useEffect(() => {
    getVolunteers();
  }, [getVolunteers]);

  if (status.loading) {
    return <FullScreenLoading />;
  }

  const handleExport = async () => {
    setExportLoading(true);
    try {
      const query = queryString.parse(router.location.search);
      query.screened = true;
      const { response, result } = await patientExport(query);

      let fileName = "Presumptive-TB-Patients";
      if (selectedPatient) {
        fileName += `-${selectedPatient.label}`;
      }
      if (selectedTownship) {
        fileName += `-${selectedTownship.label}`;
      }

      if (response.status === "success") {
        excelExport(result, fileName);
      }
    } catch (_) {
      NotificationManager.error("Please try again!");
    }
    setExportLoading(false);
  };

  const handleAllDOTExport = async () => {
    setExportDOTsLoading(true);
    try {
      const query = queryString.parse(router.location.search);
      const response = await call(
        "get",
        `export-all-patient-DOTs?${new URLSearchParams(query).toString()}`
      );
      const result = response.data.map((data, index) => ({
        ID: index + 1,
        Patient_Name: data.patient.name,
        Township: data.township.name,
        Volunteer_Name: data.volunteer
          ? `${data.volunteer?.name}(${data.volunteer?.code})`
          : null,
        Date: data.date,
        Work: DOTWorking[data.work],
        Work_Status: data.work_status ? "အောင်မြင်ပါသည်" : "မအောင်မြင်ပါ",
        Minor_Side_Effects: data.minor_side_effects,
        Major_Side_Effects: data.major_side_effects,
        Actions: data.actions,
        Video_Link: data.work === 3 ? `${host}/DOT-video/${data.uuid}` : null,
        VOT_Video_Link: data.work === 2 ? data.video_url : null,
        Patient_Sign: data.work === 1 ? data.sign_url : null,
        Remark: data.remark,
        Late_Remark: data.late_remark,
        Type: data.change_volunteer?.type,
      }));

      let fileName = "All-Patient-VOTs";
      if (selectedPatient) {
        fileName += `-${selectedPatient.label}`;
      }
      if (selectedTownship) {
        fileName += `-${selectedTownship.label}`;
      }

      if (response.status === "success") {
        excelExport(result, fileName);
      }
    } catch (_) {
      NotificationManager.error("Please try again!");
    }
    setExportDOTsLoading(false);
  };

  return (
    <>
      <Container className="mt-3" fluid>
        <BackBtn />
        <Card>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-3">Screened Patient List</h3>
              </Col>
              <Col className="text-right" xs="6">
                {patientMiddleware(role, EXPORT) && (
                  <>
                    <Button
                      size="sm"
                      color="info"
                      onClick={handleExport}
                      disabled={exportLoading}
                    >
                      {exportLoading ? "Loading" : "Export"}
                    </Button>
                    <Button
                      size="sm"
                      color="info"
                      onClick={handleAllDOTExport}
                      disabled={exportDOTsLoading}
                    >
                      {exportDOTsLoading ? "Loading" : "VOT Export"}
                    </Button>
                  </>
                )}
              </Col>
            </Row>
            <Row>
              <Col sm={3}>
                <Select
                  className="mt-3"
                  options={patients}
                  value={selectedPatient}
                  onChange={(value) => setSelectedPatient(value)}
                  placeholder="Filter by patient..."
                  isSearchable={true}
                  isClearable={true}
                />
              </Col>
              {!isVolunteerSupervisor(role) && (
                <Col sm={3}>
                  <Select
                    className="mt-3"
                    options={townships}
                    value={selectedTownship}
                    onChange={(value) => setSelectedTownship(value)}
                    placeholder="Filter by township..."
                    isSearchable={true}
                    isClearable={true}
                  />
                </Col>
              )}
              <Col sm={3}>
                <Select
                  className="mt-3"
                  options={[
                    { label: "AIS", value: "AIS" },
                    { label: "GF", value: "GF" },
                    { label: "MMA", value: "MMA" },
                  ]}
                  value={selectedProject}
                  onChange={(value) => setSelectedTProject(value)}
                  placeholder="Filter by project..."
                  isSearchable={true}
                  isClearable={true}
                />
              </Col>
              <Col sm={3}>
                <Button
                  size="sm"
                  color="success"
                  onClick={handleFilter}
                  className="mt-3"
                >
                  <i className="fa fa-search" />
                </Button>
              </Col>
            </Row>
          </CardHeader>

          <CustomTable
            header={
              <tr>
                <th className="fixed-cell left" scope="col">
                  ID
                </th>
                <th className="fixed-cell left" scope="col">
                  Name
                </th>
                <th className="fixed-cell left" scope="col">
                  Project
                </th>
                <th scope="col">Code</th>
                <th scope="col">Password</th>
                <th scope="col">Age</th>
                <th scope="col">Sex</th>
                <th scope="col">TB Status</th>
                <th scope="col">TB/DRTB Code</th>
                <th scope="col">Township</th>
                <th scope="col">Action</th>
              </tr>
            }
            body={patient.patients.map((patient, index) => (
              <tr
                key={patient.id}
                style={
                  patient.TB_status === "TB" || patient.TB_status === "DRTB"
                    ? {
                        backgroundColor: "#ed941f",
                      }
                    : {}
                }
              >
                <td
                  className="fixed-cell left"
                  style={{
                    backgroundColor: patient.DOT_end_date
                      ? "#009879"
                      : "#EB2429",
                    color: "#ffffff",
                  }}
                >
                  {index + 1}
                </td>
                <td className="fixed-cell left">{patient.name}</td>
                <td className="fixed-cell left">{patient.project}</td>
                <td>{patient.code}</td>
                <td>{patient.plain_password}</td>
                <td>{patient.age}</td>
                <td>{patient.sex}</td>
                <td>{patient.TB_status}</td>
                <td>{patient.TB_DRTB_code}</td>
                <td>{patient.township?.name}</td>
                <td>
                  <Button
                    size="sm"
                    color="success"
                    onClick={() =>
                      router.push(`/admin/DSTB-patient-detail/${patient.id}`)
                    }
                  >
                    Detail
                  </Button>
                </td>
              </tr>
            ))}
          />
          {patient.total > paginationLimit && (
            <CustomPagination pageCount={patient.total / paginationLimit} />
          )}
        </Card>
      </Container>
    </>
  );
};

const mapStateToProps = (store) => ({
  status: store.status,
  auth: store.auth,
  township: store.township,
  patient: store.patient,
});

export default connect(mapStateToProps, {
  getPatients,
  getTownships,
  getVolunteers,
})(DSTBScreenedPatientList);
