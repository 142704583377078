import { combineReducers } from 'redux'
import status from './status'
import auth from './auth'
import account from './account'
import region from './region'
import district from './district'
import township from './township'
import volunteer from './volunteer'
import training from './training'
import he from './he'
import volunteerHandout from './volunteerHandout'
import patientHandout from './patientHandout'
import takeMedicine from './takeMedicine'
import patient from './patient'
import changeVolunteer from './changeVolunteer'
import patientMedicine from './patientMedicine'
import fUSputumExaminition from './fUSputumExaminition'
import supervisionVisit from './supervisionVisit'
import DOT from './DOT'
import contactTracy from './contactTracy'
import community from './community'
import medicine from './medicine'
import report from './report'
import dashboard from './dashboard'

const reducers = combineReducers({
  status,
  auth,
  account,
  region,
  district,
  township,
  volunteer,
  training,
  he,
  volunteerHandout,
  patientHandout,
  takeMedicine,
  patient,
  changeVolunteer,
  patientMedicine,
  fUSputumExaminition,
  supervisionVisit,
  DOT,
  contactTracy,
  community,
  medicine,
  report,
  dashboard,
})

export default reducers
