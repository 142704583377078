import {
  CREATE_COMMUNITY,
  DELETE_COMMUNITY,
  SET_COMMUNITY,
  SHOW_COMMUNITIES,
  UPDATE_COMMUNITY,
} from '../type'

const initialState = {
  communities: [],
  community: {},
  total: 0,
}

const community = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_COMMUNITIES:
      return {
        ...state,
        communities: action.payload.data,
        total: action.payload.total,
      }
    case CREATE_COMMUNITY:
      return {
        ...state,
        communities: [action.payload, ...state.communities],
        total: state.total + 1,
      }
    case DELETE_COMMUNITY:
      return {
        ...state,
        communities: state.communities.filter(
          (community) => community.id !== action.payload,
        ),
        total: state.total - 1,
      }
    case SET_COMMUNITY:
      return {
        ...state,
        community: action.payload,
      }
    case UPDATE_COMMUNITY:
      let index = state.communities.findIndex(
        (community) => community.id === action.payload.id,
      )
      state.communities[index] = action.payload
      if (state.community.id === action.payload.id) {
        state.community = action.payload
      }
      return {
        ...state,
      }
    default:
      return state
  }
}

export default community
