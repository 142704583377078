import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  CardBody,
} from "reactstrap";
import CustomPagination from "components/Pagination/Pagination";
import queryString from "query-string";
import { useParams, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import {
  getNoVolunteerDOTs,
  getDOT,
  getPatient,
  deleteDOT,
} from "store/actions";
import FullScreenLoading from "components/FullScreenLoading/FullScreenLoading";
// import styles from './NoVolunteerDotList.module.css'
import { currentDate } from "utils/currentDate";
import BackBtn from "utils/backBtn";
import NoVolunteerDotDetail from "./NoVolunteerDotDetail";
import CustomTable from "components/Table/Table";
import NoVolunteerDotRemarkCreate from "./NoVolunteerDotRemarkCreate";
import CustomAlert from "components/Alert/CustomAlert";
import NoVolunteerDotRemarkUpdate from "./NoVolunteerDotRemarkUpdate";

const NoVolunteerDotList = ({
  status,
  patient,
  DOT,
  getDOT,
  getNoVolunteerDOTs,
  getPatient,
  deleteDOT,
}) => {
  const { id } = useParams();
  const router = useHistory();

  const [alert, setAlert] = useState(null);
  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const [isUpdateOpen, setIsUpdateOpen] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const handleFilter = () => {
    const query = queryString.parse(router.location.search);
    query.page = 1;
    if (startDate !== "") {
      query.start_date = startDate;
    } else {
      delete query["start_date"];
    }

    if (endDate !== "") {
      query.end_date = endDate;
    } else {
      delete query["end_date"];
    }

    router.push(`${router.location.pathname}?${queryString.stringify(query)}`);
    setStartDate("");
    setEndDate("");
  };

  useEffect(() => {
    const query = queryString.parse(router.location.search);
    if (!("page" in query)) {
      query.page = 1;
    }
    getNoVolunteerDOTs(id, query);
  }, [getNoVolunteerDOTs, id, router.location.search]);

  useEffect(() => {
    getPatient(id);
  }, [getPatient, id]);

  const handleDelete = (id) => {
    setAlert(
      <CustomAlert
        onConfirm={() => {
          deleteDOT(id);
          setAlert(null);
        }}
        onCancel={() => setAlert(null)}
      />
    );
  };

  if (status.loading) {
    return <FullScreenLoading />;
  }

  return (
    <>
      {alert}
      <Container className="mt-3" fluid>
        <BackBtn />
        <Card>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-3">({patient.patient?.name}) VOT Status</h3>
              </Col>
              <Col className="text-right" xs="6">
                <Button
                  size="sm"
                  color="success"
                  onClick={() => setIsCreateOpen(true)}
                >
                  New
                </Button>
              </Col>
            </Row>

            <Row>
              <Col sm={4}>
                <label htmlFor="start_date">Start Date</label>
                <input
                  id="start_date"
                  className="form-control"
                  type="date"
                  min={
                    patient.patient?.DOT_start_date === null
                      ? ""
                      : patient.patient?.DOT_start_date
                  }
                  max={currentDate()}
                  onChange={(event) => setStartDate(event.target.value)}
                />
              </Col>
              <Col sm={4}>
                <label htmlFor="end_date">End Date</label>
                <input
                  id="end_date"
                  className="form-control"
                  type="date"
                  min={startDate}
                  max={currentDate()}
                  onChange={(event) => setEndDate(event.target.value)}
                  disabled={startDate === ""}
                />
              </Col>
              <Col sm={4}>
                <Button
                  size="sm"
                  color="success"
                  className="mt-4"
                  onClick={handleFilter}
                >
                  <i className="fa fa-search" />
                </Button>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            {/* <Row>
              {DOT.DOTs.map((DOT, index) => {
                return (
                  <Col
                    key={index}
                    xs={3}
                    md={3}
                    lg={2}
                    className={`text-center ${styles.box}`}
                    onClick={async () => {
                      await getDOT(DOT.id)
                      setIsOpen(true)
                    }}
                  >
                    {DOT.date}
                  </Col>
                )
              })}
            </Row> */}
            <CustomTable
              header={
                <tr>
                  <th scope="col">Date</th>
                  <th scope="col">Remark</th>
                  <th scope="col">Remark for being late</th>
                  <th scope="col">Detail</th>
                </tr>
              }
              body={DOT.DOTs.map((DOT, index) => {
                return (
                  <tr key={index}>
                    <td
                      style={
                        DOT.late_remark
                          ? {
                              backgroundColor: "#f00",
                              color: "#fff",
                            }
                          : {}
                      }
                    >
                      {DOT.date}
                    </td>
                    <td>{DOT.remark}</td>
                    <td>{DOT.late_remark}</td>
                    <td>
                      <Button
                        size="sm"
                        color="success"
                        onClick={async () => {
                          await getDOT(DOT.id);
                          setIsOpen(true);
                        }}
                      >
                        Detail
                      </Button>
                      {DOT.created === "dashboard" && (
                        <>
                          <Button
                            size="sm"
                            color="primary"
                            onClick={async () => {
                              await getDOT(DOT.id);
                              setIsUpdateOpen(true);
                            }}
                          >
                            Update
                          </Button>
                          <Button
                            size="sm"
                            color="danger"
                            onClick={() => handleDelete(DOT.id)}
                          >
                            Delete
                          </Button>
                        </>
                      )}
                    </td>
                  </tr>
                );
              })}
            />
          </CardBody>

          {DOT.total > 30 && <CustomPagination pageCount={DOT.total / 30} />}
        </Card>
      </Container>

      <NoVolunteerDotDetail isOpen={isOpen} toggle={setIsOpen} />
      <NoVolunteerDotRemarkUpdate
        isOpen={isUpdateOpen}
        toggle={setIsUpdateOpen}
      />
      <NoVolunteerDotRemarkCreate
        isOpen={isCreateOpen}
        toggle={setIsCreateOpen}
      />
    </>
  );
};

const mapStateToProps = (store) => ({
  status: store.status,
  patient: store.patient,
  DOT: store.DOT,
});

export default connect(mapStateToProps, {
  getDOT,
  getNoVolunteerDOTs,
  getPatient,
  deleteDOT,
})(NoVolunteerDotList);
