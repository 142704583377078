import {
  CREATE_PATIENT_MEDICINE,
  DELETE_PATIENT_MEDICINE,
  SET_PATIENT_MEDICINE,
  SHOW_PATIENT_MEDICINES,
  UPDATE_PATIENT_MEDICINE,
} from '../type'

const initialState = {
  patientMedicines: [],
  patientMedicine: {},
}

const patientMedicine = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_PATIENT_MEDICINES:
      return {
        ...state,
        patientMedicines: action.payload,
      }
    case CREATE_PATIENT_MEDICINE:
      return {
        ...state,
        patientMedicines: [action.payload, ...state.patientMedicines],
      }
    case DELETE_PATIENT_MEDICINE:
      return {
        ...state,
        patientMedicines: state.patientMedicines.filter(
          (patientMedicine) => patientMedicine.id !== action.payload,
        ),
      }
    case SET_PATIENT_MEDICINE:
      return {
        ...state,
        patientMedicine: action.payload,
      }
    case UPDATE_PATIENT_MEDICINE:
      let index = state.patientMedicines.findIndex(
        (patientMedicine) => patientMedicine.id === action.payload.id,
      )
      state.patientMedicines[index] = action.payload
      if (state.patientMedicine.id === action.payload.id) {
        state.patientMedicine = action.payload
      }
      return {
        ...state,
      }
    default:
      return state
  }
}

export default patientMedicine
