import { call } from "services/api";

export const patientExport = async (query) => {
  const response = await call(
    "get",
    `patients-export?${new URLSearchParams(query).toString()}`
  );
  const result = response.data.map((data, index) => ({
    ID: index + 1,
    Code: data.code,
    Township: data.township?.name,
    Name: data.name,
    Address: data.address,
    Phone: data.phone,
    Age: data.age,
    Sex: data.sex,
    Referral_Volunteer: data.referral_volunteer?.name,
    Referral_Date: data.referral_date,
    Refer_To: data.refer_to,
    Refer_To_Other: data.refer_to_other,
    Height: data.height,
    Weight: data.weight,
    BMI: data.BMI,
    Case_Founded_Activity: data.case_founded_activity,
    TB_Code: data.TB_code,
    Option: data.option,
    Referred_Type: data.referred_type,
    Symptoms: data.symptoms,
    Presumptive_TB_Number: data.presumptive_TB_number,
    Risk_Factor: data.risk_factor,
    Registration_Date: data.registration_date,
    Sputum_Done_Or_Not_Done: data.sputum_done_or_not_done,
    Sputum_Examination_Date: data.sputum_examination_date,
    Sputum_Smear_Result: data.sputum_smear_result,
    Chest_XRay_Done_Or_Not_Done: data.chest_Xray_done_or_not_done,
    Chest_XRay_Examination_Date: data.chest_Xray_examination_date,
    Chest_XRay_Result: data.chest_Xray_result,
    Gene_XPert_Done_Or_Not_Done: data.gene_Xpert_done_or_not_done,
    Gene_XPert_Examination_Date: data.gene_Xpert_examination_date,
    Gene_XPert_Result: data.gene_Xpert_result,
    Truenat_Done_Or_Not_Done: data.truenat_done_or_not_done,
    Truenat_Examination_Date: data.truenat_examination_date,
    Truenat_Result: data.truenat_result,
    FNAC_Done_Or_Not_Done: data.FNAC_done_or_not_done,
    FNAC_Examination_Date: data.FNAC_examination_date,
    FNAC_Result: data.FNAC_result,
    AFB_Seen_Not: data.AFB_seen_not,
    Tuberculin_Skin_Done_Or_Not_Done: data.tuberculin_skin_done_or_not_done,
    TST_Examination_Date: data.TST_examination_date,
    Size_Of_Induration_In_Millimeter: data.size_of_induration_in_millimeter,
    Conclusion_Of_TST: data.conclusion_of_TST,
    Sputum_Culture_Done_Or_Not_Done: data.sputum_culture_done_or_not_done,
    Sputum_Culture_Examination_Date: data.sputum_culture_examination_date,
    Sputum_Culture_Result: data.sputum_culture_result,
    Drug_Susceptibility_Done_Or_Not_Done:
      data.drug_susceptibility_done_or_not_done,
    Drug_Susceptibility_Date: data.drug_susceptibility_date,
    Drug_Susceptibility_Result: data.drug_susceptibility_result,
    Other_Investigation_Done_Or_Not_Done:
      data.other_investigation_done_or_not_done,
    Other_Investigation_Date: data.other_investigation_date,
    Investigation_Name: data.investigation_name,
    Investigation_Result: data.investigation_result,
    TB_Status: data.TB_status,
    Treatment_Status: data.treatment_status,
    TB_DRTB_Code: data.TB_DRTB_code,
    Treatment_Start_Date: data.treatment_start_date,
    Treatment_Regimen: data.treatment_regimen,
    Defined_TB_Type: data.defined_TB_type,
    Type_Of_TB_Patient: data.type_of_TB_patient,
    Smoking_Status: data.smoking_status,
    DM_Status: data.DB_status,
    HIV_Test: data.HIV_test,
    HIV_Test_Date: data.HIV_test_date,
    HIV_Result: data.HIV_result,
    CPT_Status: data.CPT_status,
    CPT_Date: data.CPT_date,
    ART_Status: data.ART_status,
    ART_Date: data.ART_date,
    Treatment_Outcome: data.treatment_outcome,
    Treatment_Outcome_Date: data.treatment_outcome_date,
    Remark: data.remark,
    Volunteer: `${data.volunteer?.name}(${data.volunteer?.code})`,
    DOT_Start_Date: data.DOT_start_date,
    DOT_End_Date: data.DOT_end_date,
    VOT_End_Date: data.VDOT_end_date,
    VOT_Outcome: data.VDOT_end_type,
    VOT_Remark: data.VDOT_end_remark,
  }));

  return { response, result };
};
