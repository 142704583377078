const DEVELOPER = "Developer";
const ADMIN = "Admin";
const PROJECT_MANAGER = "Project Manager";
const SENIOR_PROJECT_OFFICER = "Senior Project Officer";
const PROJECT_OFFICER = "Project Officer";
const MIS_OFFICER = "MIS Officer";
const VOLUNTEER_SUPERVISOR = "Volunteer Supervisor";
const CLINIC_MO = "Clinic MO";
const CLINIC_COUNSELOR = "Clinic Counselor";
const CLINIC_DATA_ASSISTANT = "Clinic Data Assistant";

export const CREATE = "create";
export const READ = "read";
export const UPDATE = "update";
export const DELETE = "delete";
export const EXPORT = "export";

export const VR = "VR";
export const FR = "FR";
export const NTP = "NTP";
export const AIS = "AIS";

export const isVolunteerSupervisor = (role) => role === VOLUNTEER_SUPERVISOR;

export const accountMiddleware = (role) => {
  switch (role) {
    case DEVELOPER:
    case ADMIN:
    case PROJECT_MANAGER:
    case SENIOR_PROJECT_OFFICER:
    case PROJECT_OFFICER:
    case MIS_OFFICER:
      return true;
    default:
      return false;
  }
};

export const basicDataMiddleware = (role, type) => {
  if (
    role === DEVELOPER ||
    role === SENIOR_PROJECT_OFFICER ||
    role === PROJECT_OFFICER ||
    role === MIS_OFFICER
  ) {
    return true;
  } else {
    if ((role === ADMIN || role === PROJECT_MANAGER) && type === READ) {
      return true;
    } else {
      return false;
    }
  }
};

export const volunteerMiddleware = (role, type) => {
  if (
    role === DEVELOPER ||
    role === SENIOR_PROJECT_OFFICER ||
    role === PROJECT_OFFICER ||
    role === MIS_OFFICER ||
    role === VOLUNTEER_SUPERVISOR
  ) {
    return true;
  } else {
    if (
      (role === ADMIN || role === PROJECT_MANAGER) &&
      (type === READ || type === EXPORT)
    ) {
      return true;
    } else {
      return false;
    }
  }
};

export const patientMiddleware = (role, type) => {
  if (
    role === DEVELOPER ||
    role === SENIOR_PROJECT_OFFICER ||
    role === PROJECT_OFFICER ||
    role === MIS_OFFICER ||
    role === VOLUNTEER_SUPERVISOR
  ) {
    return true;
  } else {
    if (
      (role === ADMIN || role === PROJECT_MANAGER) &&
      (type === READ || type === EXPORT)
    ) {
      return true;
    } else {
      return false;
    }
  }
};

export const clinicPatientMiddleware = (role, type) => {
  if (
    role === DEVELOPER ||
    role === SENIOR_PROJECT_OFFICER ||
    role === PROJECT_OFFICER ||
    role === MIS_OFFICER ||
    role === CLINIC_MO ||
    role === CLINIC_COUNSELOR ||
    role === CLINIC_DATA_ASSISTANT
  ) {
    return true;
  } else {
    if (
      (role === ADMIN || role === PROJECT_MANAGER) &&
      (type === READ || type === EXPORT)
    ) {
      return true;
    } else {
      return false;
    }
  }
};

export const activitiesMiddleware = (role, type) => {
  if (
    role === DEVELOPER ||
    role === SENIOR_PROJECT_OFFICER ||
    role === PROJECT_OFFICER ||
    role === MIS_OFFICER ||
    role === VOLUNTEER_SUPERVISOR
  ) {
    return true;
  } else {
    if (
      (role === ADMIN || role === PROJECT_MANAGER) &&
      (type === READ || type === EXPORT)
    ) {
      return true;
    } else {
      return false;
    }
  }
};

export const handoutMiddleware = (role, type) => {
  if (
    role === DEVELOPER ||
    role === SENIOR_PROJECT_OFFICER ||
    role === PROJECT_OFFICER ||
    role === MIS_OFFICER
  ) {
    return true;
  } else {
    if (
      type === READ &&
      (role === PROJECT_MANAGER ||
        role === VOLUNTEER_SUPERVISOR ||
        role === CLINIC_MO ||
        role === CLINIC_COUNSELOR ||
        role === CLINIC_DATA_ASSISTANT)
    ) {
      return true;
    } else {
      return false;
    }
  }
};

export const reportMiddleware = (role, report) => {
  if (
    role === DEVELOPER ||
    role === ADMIN ||
    role === PROJECT_MANAGER ||
    role === SENIOR_PROJECT_OFFICER ||
    role === PROJECT_OFFICER ||
    role === MIS_OFFICER
  ) {
    return true;
  } else {
    if (role === VOLUNTEER_SUPERVISOR && (report === VR || report === FR)) {
      return true;
    } else {
      return false;
    }
  }
};

export const trashMiddleware = (role) => {
  if (
    role === DEVELOPER ||
    role === SENIOR_PROJECT_OFFICER ||
    role === PROJECT_OFFICER ||
    role === MIS_OFFICER
  ) {
    return true;
  } else {
    return false;
  }
};
