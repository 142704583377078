import React, { useEffect, useState } from "react";
import Select from "react-select";
import {
  Container,
  Card,
  CardHeader,
  Row,
  Col,
  Button,
  CardBody,
} from "reactstrap";
import BackBtn from "utils/backBtn";
import { connect } from "react-redux";
import {
  getVolunteers,
  getTownships,
  getVolunteerReportDashboard,
} from "store/actions";
import FullScreenLoading from "components/FullScreenLoading/FullScreenLoading";
import { isVolunteerSupervisor } from "utils/middleware";
import { useHistory } from "react-router";
import queryString from "query-string";
import IndicatorCard from "components/Dashboard/IndicatorCard";
import BarChart from "components/Chart/BarChart";
import { currentDate } from "utils/currentDate";
import LineChart from "components/Chart/LineChart";

const MonthlyVolunteerReport = ({
  status,
  auth,
  township,
  volunteer,
  getVolunteers,
  getTownships,
  volunteerReportDashboard,
  getVolunteerReportDashboard,
}) => {
  const { role } = auth.user;
  const router = useHistory();

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedTownship, setSelectedTownship] = useState(null);
  const [selectedVolunteer, setSelectedVolunteer] = useState(null);

  const townships = township.townships.map((element) => ({
    value: element.id,
    label: element.name,
  }));

  const volunteers = volunteer.volunteers.map((element) => ({
    value: element.id,
    label: `${element.name}(${element.code})`,
  }));

  useEffect(() => {
    if (role !== undefined && !isVolunteerSupervisor(role)) {
      getTownships();
    }
  }, [getTownships, role]);

  useEffect(() => {
    getVolunteers();
  }, [getVolunteers, role]);

  const handleFilter = () => {
    const query = queryString.parse(router.location.search);
    if (selectedVolunteer) {
      query.volunteer_id = selectedVolunteer.value;
    } else {
      delete query["volunteer_id"];
    }

    if (selectedTownship) {
      query.township_id = selectedTownship.value;
    } else {
      delete query["township_id"];
    }

    if (startDate !== "") {
      query.start_date = startDate;
    } else {
      delete query["start_date"];
    }

    if (endDate !== "") {
      query.end_date = endDate;
    } else {
      delete query["end_date"];
    }

    router.push(`${router.location.pathname}?${queryString.stringify(query)}`);
  };

  useEffect(() => {
    const query = queryString.parse(router.location.search);
    getVolunteerReportDashboard(query);
  }, [getVolunteerReportDashboard, router.location.search]);

  const totalWithTownshipResult = {
    labels: volunteerReportDashboard?.total_with_township_result?.map(
      (data) => data.township_name
    ),
    datasets: [
      {
        label: "Indicator 1",
        data: volunteerReportDashboard?.total_with_township_result?.map(
          (data) => data.indicator_1
        ),
        backgroundColor: "rgba(3, 252, 36)",
      },
      {
        label: "Indicator 2",
        data: volunteerReportDashboard?.total_with_township_result?.map(
          (data) => data.indicator_2
        ),
        backgroundColor: "rgba(239, 247, 7)",
      },
      {
        label: "Indicator 3",
        data: volunteerReportDashboard?.total_with_township_result?.map(
          (data) => data.indicator_3
        ),
        backgroundColor: "rgba(7, 247, 239)",
      },
      {
        label: "Indicator 4",
        data: volunteerReportDashboard?.total_with_township_result?.map(
          (data) => data.indicator_4
        ),
        backgroundColor: "rgba(7, 15, 247)",
      },
      {
        label: "Indicator 5",
        data: volunteerReportDashboard?.total_with_township_result?.map(
          (data) => data.indicator_5
        ),
        backgroundColor: "rgba(179, 7, 247)",
      },
      {
        label: "Indicator 6",
        data: volunteerReportDashboard?.total_with_township_result?.map(
          (data) => data.indicator_6
        ),
        backgroundColor: "rgba(247, 7, 191)",
      },
      {
        label: "Indicator 7",
        data: volunteerReportDashboard?.total_with_township_result?.map(
          (data) => data.indicator_7
        ),
        backgroundColor: "rgba(247, 7, 7)",
      },
    ],
  };

  const totalWithMonthYearResult = {
    labels: volunteerReportDashboard?.total_with_month_year_result?.map(
      (data) => data.month
    ),
    datasets: [
      {
        label: "Indicator 1",
        data: volunteerReportDashboard?.total_with_month_year_result?.map(
          (data) => data.indicator_1
        ),
        borderColor: "rgba(3, 252, 36)",
        fill: false,
        tension: 0.1,
      },
      {
        label: "Indicator 2",
        data: volunteerReportDashboard?.total_with_month_year_result?.map(
          (data) => data.indicator_2
        ),
        borderColor: "rgba(239, 247, 7)",
        fill: false,
        tension: 0.1,
      },
      {
        label: "Indicator 3",
        data: volunteerReportDashboard?.total_with_month_year_result?.map(
          (data) => data.indicator_3
        ),
        borderColor: "rgba(7, 247, 239)",
        fill: false,
        tension: 0.1,
      },
      {
        label: "Indicator 4",
        data: volunteerReportDashboard?.total_with_month_year_result?.map(
          (data) => data.indicator_4
        ),
        borderColor: "rgba(7, 15, 247)",
        fill: false,
        tension: 0.1,
      },
      {
        label: "Indicator 5",
        data: volunteerReportDashboard?.total_with_month_year_result?.map(
          (data) => data.indicator_5
        ),
        borderColor: "rgba(179, 7, 247)",
        fill: false,
        tension: 0.1,
      },
      {
        label: "Indicator 6",
        data: volunteerReportDashboard?.total_with_month_year_result?.map(
          (data) => data.indicator_6
        ),
        borderColor: "rgba(247, 7, 191)",
        fill: false,
        tension: 0.1,
      },
      {
        label: "Indicator 7",
        data: volunteerReportDashboard?.total_with_month_year_result?.map(
          (data) => data.indicator_7
        ),
        borderColor: "rgba(247, 7, 7)",
        fill: false,
        tension: 0.1,
      },
    ],
  };

  if (status.loading) {
    return <FullScreenLoading />;
  }

  return (
    <Container className="mt-3" fluid>
      <BackBtn />
      <Card>
        <CardHeader className="border-0">
          <Row>
            <Col xs={6}>
              <h3 className="mb-0">Monthly Volunteer Report</h3>
            </Col>
            <Col className="text-right" xs={6}>
              <Button size="sm" color="success" onClick={handleFilter}>
                <i className="fa fa-search" />
              </Button>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <Select
                className="mt-4"
                options={volunteers}
                value={selectedVolunteer}
                onChange={(value) => setSelectedVolunteer(value)}
                placeholder="Filter by volunteer..."
                isSearchable={true}
                isClearable={true}
              />
            </Col>
            <Col md={3}>
              <label htmlFor="start_date">Start Date</label>
              <input
                id="start_date"
                className="form-control"
                type="date"
                onChange={(event) => setStartDate(event.target.value)}
                value={startDate}
              />
            </Col>
            <Col md={3}>
              <label htmlFor="end_date">End Date</label>
              <input
                id="end_date"
                className="form-control"
                type="date"
                min={startDate}
                max={currentDate()}
                onChange={(event) => setEndDate(event.target.value)}
                disabled={startDate === ""}
                value={endDate}
              />
            </Col>
            {role !== undefined && !isVolunteerSupervisor(role) && (
              <Col sm={3}>
                <Select
                  className="mt-4"
                  options={townships}
                  value={selectedTownship}
                  onChange={(value) => setSelectedTownship(value)}
                  placeholder="Filter by township..."
                  isSearchable={true}
                  isClearable={true}
                />
              </Col>
            )}
          </Row>
        </CardHeader>
        <CardBody>
          {volunteerReportDashboard?.total_result?.map((total, index) => (
            <p
              key={index}
              style={{
                fontWeight: "bold",
              }}
            >
              Indicator - {total.indicator} {total.label}
            </p>
          ))}
          <Row className="mt-5">
            {volunteerReportDashboard?.total_result?.map((total, index) => (
              <Col md="3" key={index}>
                <IndicatorCard
                  total={total.total}
                  indicator={total.indicator}
                  label={total.label}
                />
              </Col>
            ))}
          </Row>
          <BarChart
            title="Report With Township"
            total={""}
            className="report-with-township"
            data={totalWithTownshipResult}
          />
          <LineChart
            title="Report With Month and Year"
            total={""}
            className="report-with-month-year"
            data={totalWithMonthYearResult}
          />
        </CardBody>
      </Card>
    </Container>
  );
};

const mapStateToProps = (store) => ({
  status: store.status,
  auth: store.auth,
  township: store.township,
  volunteer: store.volunteer,
  volunteerReportDashboard: store.dashboard.volunteerReportDashboard,
});

export default connect(mapStateToProps, {
  getVolunteers,
  getTownships,
  getVolunteerReportDashboard,
})(MonthlyVolunteerReport);
