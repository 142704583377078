import {
  DELETE_CHANGE_VOLUNTEER,
  SET_CHANGE_VOLUNTEER,
  SHOW_CHANGE_VOLUNTEERS,
} from '../type'

const initialState = {
  changeVolunteers: [],
  changeVolunteer: {},
}

const changeVolunteer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_CHANGE_VOLUNTEERS:
      return {
        ...state,
        changeVolunteers: action.payload,
      }
    case DELETE_CHANGE_VOLUNTEER:
      return {
        ...state,
        changeVolunteers: state.changeVolunteers.filter(
          (changeVolunteer) => changeVolunteer.id !== action.payload,
        ),
      }
    case SET_CHANGE_VOLUNTEER:
      return {
        ...state,
        changeVolunteer: action.payload,
      }

    default:
      return state
  }
}

export default changeVolunteer
