import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Form,
  Row,
  Col,
  CardBody,
  CardFooter,
  CardTitle,
} from "reactstrap";
import { connect } from "react-redux";
import CustomSelect from "components/Inputs/CustomSelect";
import CustomDateInput from "components/Inputs/CustomDateInput";
import { currentDate } from "utils/currentDate";
import { updatePatient } from "store/actions";
import { useParams } from "react-router";

const DOTEnd = ({ volunteer, patient, updatePatient }) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [selectedVolunteer, setSelectedVolunteer] = useState(null);

  const volunteers = volunteer.volunteers.map((element) => ({
    value: element.id,
    label: element.name,
  }));

  useEffect(() => {
    const { volunteer, DOT_start_date, DOT_end_date } = patient.patient;

    setValue("volunteer", { value: volunteer?.id, label: volunteer?.name });
    setSelectedVolunteer({ value: volunteer?.id, label: volunteer?.name });
    setValue("DOT_start_date", DOT_start_date);
    setValue("DOT_end_date", DOT_end_date);
  }, [patient.patient, setValue]);

  const onSubmit = async (data) => {
    setLoading(true);
    await updatePatient(
      id,
      {
        DOT_end_date: data.DOT_end_date,
      },
      "DOT-end",
      "VOT End"
    );
    setLoading(false);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <CardBody>
        <CardTitle>
          <h3>VOT End</h3>
        </CardTitle>
        <Row>
          {patient.patient.volunteer && (
            <Col md={3}>
              <CustomSelect
                id="volunteer"
                label="Select Volunteer"
                control={control}
                options={volunteers}
                value={selectedVolunteer}
                setData={setSelectedVolunteer}
                placeholder="Select Volunteer"
                isRequired={false}
                isDisabled={true}
              />
            </Col>
          )}
          <Col md={3}>
            <CustomDateInput
              id="DOT_start_date"
              label="VOT Start Date"
              register={{
                ...register("DOT_start_date", {
                  required: false,
                }),
              }}
              placeholder="Select VOT Start Date"
              errors={errors}
              isRequired={false}
              disabled={true}
            />
          </Col>
          <Col md={3}>
            <CustomDateInput
              id="DOT_end_date"
              label="VOT End Date"
              register={{
                ...register("DOT_end_date", {
                  required: false,
                }),
              }}
              placeholder="Select VOT End Date"
              errors={errors}
              isRequired={false}
              max={currentDate()}
            />
          </Col>
        </Row>
      </CardBody>
      <CardFooter className="text-right">
        <Button color="primary" size="sm" disabled={loading} type="submit">
          {loading ? "Loading" : "Update"}
        </Button>
      </CardFooter>
    </Form>
  );
};

const mapStateToProps = (store) => ({
  patient: store.patient,
  volunteer: store.volunteer,
});

export default connect(mapStateToProps, { updatePatient })(DOTEnd);
