import { call, setAccessToken } from '../../services/api'
import { serverErrorMessage } from '../../utils/messages'
import { SET_HE, SET_LOADING, SET_SUCCESS, SHOW_HES } from '../type'
import { NotificationManager } from 'react-notifications'

export const getHEs = (query) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING })
    try {
      const result = await call(
        'get',
        `hes?${new URLSearchParams(query).toString()}`,
      )

      dispatch({
        type: SHOW_HES,
        payload: result,
      })
    } catch (error) {
      const { status, data } = error.response

      if (status === 401) {
        setAccessToken(null)
        NotificationManager.error(data.data.message)
      } else {
        NotificationManager.error(serverErrorMessage)
      }
    }
    dispatch({ type: SET_LOADING })
  }
}

export const getHE = (id) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING })
    dispatch({ type: SET_SUCCESS, payload: false })
    try {
      const response = await call('get', `hes/${id}`)
      const result = response.data

      dispatch({
        type: SET_HE,
        payload: result,
      })
    } catch (error) {
      const { status, data } = error.response

      if (status === 401) {
        setAccessToken(null)
        NotificationManager.error(data.data.message)
      } else {
        NotificationManager.error(serverErrorMessage)
      }
    }
    dispatch({ type: SET_LOADING })
  }
}
