import React, { useEffect, useState, useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { Button, Form } from 'reactstrap'
import CustomModal from 'components/Modal/CustomModal'
import { connect } from 'react-redux'
import CustomDateInput from 'components/Inputs/CustomDateInput'
import { currentDate } from 'utils/currentDate'
import { handleDateArray } from 'utils/dateArray'
import { createDOT } from 'store/actions'

const NoAppDotCreate = ({
  isOpen,
  toggle,
  status,
  createDOT,
  patient,
  DOT,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm()

  const [loading, setLoading] = useState(false)
  const [startDate, setStartDate] = useState('')

  const onSubmit = async (values) => {
    setLoading(true)
    const dates = handleDateArray(
      new Date(values.start_date),
      new Date(values.end_date),
    )

    await createDOT({ dates, patient_id: patient.patient.id })
    setLoading(false)
  }

  const handleStartDate = useCallback(() => {
    const DOTs = DOT.DOTs
    const DOT_start_date = patient.patient?.DOT_start_date
    const final_date = DOTs[DOTs.length - 1]?.date

    const start = final_date ? final_date : DOT_start_date

    return start
  }, [DOT.DOTs, patient.patient?.DOT_start_date])

  useEffect(() => {
    const start = handleStartDate()
    setValue('start_date', start)
    setStartDate(start)
  }, [handleStartDate, setValue])

  useEffect(() => {
    if (status.success) {
      reset()
    }
  }, [status.success, reset])

  return (
    <CustomModal
      isOpen={isOpen}
      title="Create VOT Visit Date (No App)"
      onClick={() => {
        toggle(false)
        reset()
      }}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-body">
          <CustomDateInput
            id="start_date"
            label="Start Date"
            register={{
              ...register('start_date', {
                required: 'Start Date is required!',
              }),
            }}
            placeholder="Select Start Date"
            errors={errors}
            isRequired={true}
            max={currentDate()}
            onChange={(event) => setStartDate(event.target.value)}
          />
          <CustomDateInput
            id="end_date"
            label="End Date"
            register={{
              ...register('end_date', {
                required: 'End Date is required!',
              }),
            }}
            placeholder="Select End Date"
            errors={errors}
            isRequired={true}
            min={startDate}
            max={currentDate()}
            disabled={!startDate}
          />
        </div>
        <div className="modal-footer">
          <Button color="primary" size="sm" type="submit" disabled={loading}>
            {loading ? 'Loading' : 'Save'}
          </Button>
        </div>
      </Form>
    </CustomModal>
  )
}

const mapStateToProps = (store) => ({
  status: store.status,
  patient: store.patient,
  DOT: store.DOT,
})

export default connect(mapStateToProps, { createDOT })(NoAppDotCreate)
