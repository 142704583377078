import {
  CREATE_MEDICINE,
  DELETE_MEDICINE,
  SET_MEDICINE,
  SHOW_MEDICINES,
  UPDATE_MEDICINE,
} from '../type'

const initialState = {
  medicines: [],
  medicine: {},
}

const medicine = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_MEDICINES:
      return {
        ...state,
        medicines: action.payload,
      }
    case CREATE_MEDICINE:
      return {
        ...state,
        medicines: [action.payload, ...state.medicines],
      }
    case DELETE_MEDICINE:
      return {
        ...state,
        medicines: state.medicines.filter(
          (medicine) => medicine.id !== action.payload,
        ),
      }
    case SET_MEDICINE:
      return {
        ...state,
        medicine: action.payload,
      }
    case UPDATE_MEDICINE:
      let index = state.medicines.findIndex(
        (medicine) => medicine.id === action.payload.id,
      )
      state.medicines[index] = action.payload
      if (state.medicine.id === action.payload.id) {
        state.medicine = action.payload
      }
      return {
        ...state,
      }
    default:
      return state
  }
}

export default medicine
