import {
  ACTIVITY_DASHBOARD,
  DOT_STATUS_DASHBOARD,
  PATIENT_DASHBOARD,
  VOLUNTEER_DASHBOARD,
  VOLUNTEER_REPORT_DASHBOARD,
} from "../type";

const initialState = {
  volunteerDashboard: {},
  patientDashboard: {},
  dotStatusDashboard: {},
  activityDashboard: {},
  volunteerReportDashboard: {},
};

const dashboard = (state = initialState, action) => {
  switch (action.type) {
    case VOLUNTEER_DASHBOARD:
      return {
        ...state,
        volunteerDashboard: action.payload,
      };
    case PATIENT_DASHBOARD:
      return {
        ...state,
        patientDashboard: action.payload,
      };
    case DOT_STATUS_DASHBOARD:
      return {
        ...state,
        dotStatusDashboard: action.payload,
      };
    case ACTIVITY_DASHBOARD:
      return {
        ...state,
        activityDashboard: action.payload,
      };
    case VOLUNTEER_REPORT_DASHBOARD:
      return {
        ...state,
        volunteerReportDashboard: action.payload,
      };
    default:
      return state;
  }
};

export default dashboard;
