export const roles = [
  { value: 'Admin', label: 'Admin' },
  { value: 'Project Manager', label: 'Project Manager' },
  { value: 'Senior Project Officer', label: 'Senior Project Officer' },
  { value: 'Project Officer', label: 'Project Officer' },
  { value: 'MIS Officer', label: 'MIS Officer' },
  { value: 'Volunteer Supervisor', label: 'Volunteer Supervisor' },
  // { value: 'Clinic MO', label: 'Clinic MO' },
  // { value: 'Clinic Counselor', label: 'Clinic Counselor' },
  // { value: 'Clinic Data Assistant', label: 'Clinic Data Assistant' },
]

export const sexs = [
  { value: 'Male', label: 'Male' },
  { value: 'Female', label: 'Female' },
]

export const nationals = [
  { value: 'N', label: 'N' },
  { value: 'Non-N', label: 'Non-N' },
]

export const yesOrNo = [
  { value: 'Yes', label: 'Yes' },
  { value: 'No', label: 'No' },
]

export const no = [{ value: 'No', label: 'No' }]

export const dones = [
  { value: 'Done', label: 'Done' },
  { value: 'Not Done', label: 'Not Done' },
]

export const firstXpertStatusResults = [
  { value: 'T', label: 'T' },
  { value: 'RR', label: 'RR' },
  { value: 'TI', label: 'TI' },
  { value: 'N', label: 'N' },
]

export const secondXpertStatusResults = [
  { value: 'RR/()', label: 'RR/()' },
  { value: 'RR/(H)', label: 'RR/(H)' },
  { value: 'RR/(M)', label: 'RR/(M)' },
  { value: 'RR/(L)', label: 'RR/(L)' },
  { value: 'RR/(VL)', label: 'RR/(VL)' },
]

export const DSTResults = [
  { value: 'Negative', label: 'Negative' },
  { value: 'Positive', label: 'Positive' },
]

export const susceptibleOrResistantTos = [
  { value: 'H', label: 'H' },
  { value: 'R', label: 'R' },
  { value: 'Z', label: 'Z' },
  { value: 'E', label: 'E' },
  { value: 'S', label: 'S' },
  { value: 'Am', label: 'Am' },
  { value: 'Km', label: 'Km' },
  { value: 'Cm', label: 'Cm' },
  { value: 'Fq', label: 'Fq' },
  { value: 'Other', label: 'Other' },
]

export const registrationOnGroups = [
  { value: '1=New', label: '1=New' },
  { value: '2=Non-Converter (IR/RR)', label: '2=Non-Converter (IR/RR)' },
  {
    value: '3=Treatment After Lost To Follow up (IR/RR)',
    label: '3=Treatment After Lost To Follow up (IR/RR)',
  },
  {
    value: '4=Treatment After Failure Of Treatment (IR/RR)',
    label: '4=Treatment After Failure Of Treatment (IR/RR)',
  },
  { value: '5=Relapse (IR/RR)', label: '5.=Relapse (IR/RR)' },
  {
    value: '6.1.1=SSTR (LTFU/Failure/Relapse)',
    label: '6.1.1=SSTR (LTFU/Failure/Relapse)',
  },
  {
    value: '6.1.2=LTR (LTFU/Failure/Relapse)',
    label: '6.1.2=LTR (LTFU/Failure/Relapse)',
  },
  {
    value: '6.2.1=OSSTR (LTFU/Failure/Relapse)',
    label: '6.2.1=OSSTR (LTFU/Failure/Relapse)',
  },
  {
    value: '6.2.2=OLTR (LTFU/Failure/Relapse)',
    label: '6.2.2=OLTR (LTFU/Failure/Relapse)',
  },
  {
    value: '6.3=Individualized/Other regimen (LTFU/Failure/Relapse)',
    label: '6.3=Individualized/Other regimen (LTFU/Failure/Relapse)',
  },
  { value: '7=Others', label: '7=Others' },
  { value: '8=Transfer In', label: '8=Transfer In' },
]

export const HIVResults = [
  { value: 'Non-Reactive', label: 'Non-Reactive' },
  { value: 'Reactive', label: 'Reactive' },
]

export const COMorbidities = [
  { value: 'Yes', label: 'Yes' },
  { value: 'No', label: 'No' },
  { value: 'Unknown', label: 'Unknown' },
]

export const COMorbiditieOthers = [
  { value: 'Hepatitis B+', label: 'Hepatitis B+' },
  { value: 'Hepatitis C+', label: 'Hepatitis C+' },
  { value: 'B+&C+', label: 'B+&C+' },
  { value: 'Neg', label: 'Neg' },
]

export const treatmentOutcomes = [
  { value: 'Cured', label: 'Cured' },
  { value: 'Treatment Complete', label: 'Treatment Complete' },
  { value: 'Treatment Failure', label: 'Treatment Failure' },
  { value: 'Died', label: 'Died' },
  { value: 'Lost to Follow up', label: 'Lost to Follow up' },
  { value: 'Not Evaluated', label: 'Not Evaluated' },
  {
    value: 'Move To second line Treatment',
    label: 'Move To second line Treatment',
  },
  { value: 'Unable to continue VOT', label: 'Unable to continue VOT' },
]

export const months = [
  { value: 2, label: 'Month 2' },
  { value: 3, label: 'Month 3' },
  { value: 5, label: 'Month 5' },
  { value: 6, label: 'Month 6' },
  { value: 8, label: 'Month 8' },
  { value: 'End of Month', label: 'End of Month' },
]

export const smearResults = [
  { value: '0', label: '0' },
  { value: 'Scanty', label: 'Scanty' },
  { value: '+', label: '+' },
  { value: '++', label: '++' },
  { value: '+++', label: '+++' },
]

export const cultureAndDSTResults = [
  { value: 'Positive', label: 'Positive' },
  { value: 'Negative', label: 'Negative' },
  { value: 'Contaminated', label: 'Contaminated' },
]

export const CBTBCPatientTypes = [
  {
    value: 'ရိုးရိုးတီဘီပိုးတွေ့ အဆုတ်တီဘီလူနာ',
    label: 'ရိုးရိုးတီဘီပိုးတွေ့ အဆုတ်တီဘီလူနာ',
  },
  { value: 'ဆေးယဉ်ပါးတီဘီလူနာ', label: 'ဆေးယဉ်ပါးတီဘီလူနာ' },
  {
    value: 'ကိုယ်ခံစွမ်းအားကျသော ဗိုင်းရပ်စ်ပိုးကူးစက်ခံရသည့်တီဘီလူနာ',
    label: 'ကိုယ်ခံစွမ်းအားကျသော ဗိုင်းရပ်စ်ပိုးကူးစက်ခံရသည့်တီဘီလူနာ',
  },
  {
    value: 'အသက် (၅) နှစ်အောက်ကလေးတီဘီလူနာ',
    label: 'အသက် (၅) နှစ်အောက်ကလေးတီဘီလူနာ',
  },
  { value: 'အခြား', label: 'အခြား' },
]

// export const medicines = [
//   { value: 'Isoniazid (H)', label: 'Isoniazid (H)' },
//   { value: 'Rifampicin (R)', label: 'Rifampicin (R)' },
//   { value: 'Pyrazinamide (Z)', label: 'Pyrazinamide (Z)' },
//   { value: 'Ethambutol (E)', label: 'Ethambutol (E)' },
//   { value: 'Streptomycin (S)', label: 'Streptomycin (S)' },
// ]

export const reportMonths = [
  { value: '1', label: 'January' },
  { value: '2', label: 'February' },
  { value: '3', label: 'March' },
  { value: '4', label: 'April' },
  { value: '5', label: 'May' },
  { value: '6', label: 'June' },
  { value: '7', label: 'July' },
  { value: '8', label: 'August' },
  { value: '9', label: 'September' },
  { value: '10', label: 'October' },
  { value: '11', label: 'November' },
  { value: '12', label: 'December' },
  { value: '1 Quarter', label: '1st Quarter' },
  { value: '2 Quarter', label: '2nd Quarter' },
  { value: '3 Quarter', label: '3rd Quarter' },
  { value: '4 Quarter', label: '4th Quarter' },
  { value: 'Annual', label: 'Annual' },
]

export const educations = [
  { value: 'Primary', label: 'Primary' },
  { value: 'Secondary', label: 'Secondary' },
  { value: 'Higher', label: 'Higher' },
]

export const years = [
  { value: '2020', label: '2020' },
  { value: '2021', label: '2021' },
  { value: '2022', label: '2022' },
  { value: '2023', label: '2023' },
  { value: '2024', label: '2024' },
  { value: '2025', label: '2025' },
  { value: '2026', label: '2026' },
  { value: '2027', label: '2027' },
  { value: '2028', label: '2028' },
  { value: '2029', label: '2029' },
  { value: '2030', label: '2030' },
]

export const allMonths = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
  { value: '6', label: '6' },
  { value: '7', label: '7' },
  { value: '8', label: '8' },
  { value: '9', label: '9' },
  { value: '10', label: '10' },
  { value: '11', label: '11' },
  { value: '12', label: '12' },
]

export const types = [
  { value: 'On Job', label: 'On Job' },
  { value: 'New', label: 'New' },
  { value: 'Refresher', label: 'Refresher' },
]

export const DOTTypes = [
  { value: 'App', label: 'App' },
  { value: 'No App', label: 'No App' },
]

export const referTos = [
  { value: 'NTP', label: 'NTP' },
  {
    value: 'Private Partnership/NGO Clinic',
    label: 'Private Partnership/NGO Clinic',
  },
  { value: 'MCXR Clinic', label: 'MCXR Clinic' },
  { value: 'Other', label: 'Other' },
]

export const caseFoundedActivities = [
  { value: 'Community', label: 'Community' },
  { value: 'HE', label: 'HE' },
  { value: 'CI', label: 'CI' },
]

export const TBCodeChooses = [
  { value: 'Household', label: 'Household' },
  { value: 'Non-Household', label: 'Non-Household' },
]

export const referredTypes = [
  { value: 'Patient', label: 'Patient' },
  { value: 'Sputum Sample', label: 'Sputum Sample' },
]

export const symptomAges = [
  { value: '>=15', label: '>=15' },
  { value: '<15', label: '<15' },
]

export const greaterThanEqual15Symptoms = [
  { value: 'Any cough', label: 'Any cough' },
  { value: 'Fever and night sweat', label: 'Fever and night sweat' },
  {
    value: 'Loss of appetite and loss of weight',
    label: 'Loss of appetite and loss of weight',
  },
  { value: 'Hemoptysis', label: 'Hemoptysis' },
  { value: 'Chest pain', label: 'Chest pain' },
  {
    value: 'Frequent fatigue and tiredness',
    label: 'Frequent fatigue and tiredness',
  },
  { value: 'Neck glands', label: 'Neck glands' },
]

export const lessThan15Symptoms = [
  {
    value: 'Cough and/or fever for more than two weeks',
    label: 'Cough and/or fever for more than two weeks',
  },
  {
    value: 'History of contact with presumptive or diagnosed TB patients',
    label: 'History of contact with presumptive or diagnosed TB patients',
  },
  {
    value: 'Weight loss or failure to gain weight',
    label: 'Weight loss or failure to gain weight',
  },
]

export const riskFactors = [
  { value: 'No Risk Factor', label: 'No Risk Factor' },
  { value: 'Past History of TB', label: 'Past History of TB' },
  { value: 'HIV', label: 'HIV' },
  { value: 'DM', label: 'DM' },
  { value: 'TB contact', label: 'TB contact' },
  { value: 'MDRTB contact', label: 'MDRTB contact' },
]

export const sputumSmearResults = [
  { value: 'Negative', label: 'Negative' },
  { value: 'Positive', label: 'Positive' },
]

export const chestXRayResults = [
  { value: 'Normal', label: 'Normal' },
  { value: 'Abnormal', label: 'Abnormal' },
]

export const geneXpertResults = [
  { value: 'N-MTB Not Detected', label: 'N-MTB Not Detected' },
  { value: 'T-MTB detected', label: 'T-MTB detected' },
  { value: 'RR-Rif resistance', label: 'RR-Rif resistance' },
  {
    value: 'TI-MTB detected, RR indeterminate',
    label: 'TI-MTB detected, RR indeterminate',
  },
  { value: 'I-invalid result', label: 'I-invalid result' },
]

export const trueNatResults = [{ value: '', label: '' }]

export const FNACResults = [
  { value: 'Other', label: 'Other' },
  { value: 'TB lymphadenitis', label: 'TB lymphadenitis' },
]

export const AFBSeenNots = [
  { value: 'Seen', label: 'Seen' },
  { value: 'Not Seen', label: 'Not Seen' },
  { value: 'Not Done', label: 'Not Done' },
]

export const conclusionOfTSTResults = [
  { value: 'Negative', label: 'Negative' },
  { value: 'Positive', label: 'Positive' },
]

export const sputumCultureResults = [
  { value: 'Negative', label: 'Negative' },
  { value: 'Positive', label: 'Positive' },
  { value: 'Contaminated', label: 'Contaminated' },
]

export const drugSusceptibilityResults = [
  { value: 'H Resistance', label: 'H Resistance' },
  { value: 'R Resistance', label: 'R Resistance' },
  { value: 'Z Resistance', label: 'Z Resistance' },
  { value: 'E Resistance', label: 'E Resistance' },
  { value: 'S Resistance', label: 'S Resistance' },
  { value: 'Multi Drug Resistance', label: 'Multi Drug Resistance' },
  { value: 'Poly Resistance', label: 'Poly Resistance' },
]

export const TBStatus = [
  { value: 'TB', label: 'TB' },
  { value: 'No TB', label: 'No TB' },
  { value: 'DRTB', label: 'DRTB' },
]

export const treatmentStatus = [
  { value: 'Received TB Treatment', label: 'Received TB Treatment' },
  { value: 'Refer to other Township', label: 'Refer to other Township' },
  { value: 'Initial Loss', label: 'Initial Loss' },
  { value: 'Died before Treatment', label: 'Died before Treatment' },
]

export const treatmentRegimens = [
  { value: 'Initial Regimen (IR)', label: 'Initial Regimen (IR)' },
  { value: 'Retreatment Regimen (RR)', label: 'Retreatment Regimen (RR)' },
  { value: 'Childhood Regimen (CR)', label: 'Childhood Regimen (CR)' },
  { value: 'MDRTB Regimen (MDR)', label: 'MDRTB Regimen (MDR)' },
  { value: 'Modified Regimen', label: 'Modified Regimen' },
]

export const definedTBTypes = [
  { value: 'Pulmonary BC', label: 'Pulmonary BC' },
  { value: 'Extra Pulmonary BC', label: 'Extra Pulmonary BC' },
  { value: 'Pulmonary CD', label: 'Pulmonary CD' },
  { value: 'Extra Pulmonary CD', label: 'Extra Pulmonary CD' },
]

export const typeOfTBPateints = [
  { value: 'New', label: 'New' },
  { value: 'Releapse', label: 'Releapse' },
  { value: 'Treatment after failure', label: 'Treatment after failure' },
  {
    value: 'Treatment after loss to follow up',
    label: 'Treatment after loss to follow up',
  },
  { value: 'Other previously treated', label: 'Other previously treated' },
  {
    value: 'Previous treatment history unknown',
    label: 'Previous treatment history unknown',
  },
]

export const smokingStatus = [
  { value: 'Past', label: 'Past' },
  { value: 'Never', label: 'Never' },
  { value: 'Current', label: 'Current' },
]

export const DMStatus = [
  { value: 'Yes', label: 'Yes' },
  { value: 'No', label: 'No' },
  { value: 'Unknown', label: 'Unknown' },
]

export const patients = [
  { value: 'Ko Ko', label: 'Ko Ko' },
  {
    value: 'Kyaw Kyaw',
    label: 'Kyaw Kyaw',
  },
]

export const referralPlaces = [
  { value: 'တီဘီရောဂါတိုက်ဖျက်ရေးဌာန', label: 'တီဘီရောဂါတိုက်ဖျက်ရေးဌာန' },
  { value: 'မိတ်ဖက် ပြင်ပ/NGO ဆေးခန်း', label: 'မိတ်ဖက် ပြင်ပ/NGO ဆေးခန်း' },
  {
    value: 'ရွေ့လျားတီဘီရှာဖွေရေးဆေးခန်း',
    label: 'ရွေ့လျားတီဘီရှာဖွေရေးဆေးခန်း',
  },
]

export const VDOTOutcomes = [
  { value: 'Change to In Person DOT', label: 'Change to In Person DOT' },
  { value: 'Change to Family DOT', label: 'Change to Family DOT' },
  { value: 'Deny VOT', label: 'Deny VOT' },
  { value: 'Damage and Losing Phone', label: 'Damage and Losing Phone' },
  { value: 'Technical Issues', label: 'Technical Issues' },
  { value: 'Internet Connection Problem', label: 'Internet Connection Problem' },
  { value: 'Other Outcomes', label: 'Other Outcomes' },
]

export const VOTNotAcceptReasons = [
  { value: 'Family DOT', label: 'Family DOT' },
  { value: 'Privacy', label: 'Privacy' },
  { value: 'Others (Specify)', label: 'Others (Specify)' },
]