import React from 'react'
import { FormGroup } from 'reactstrap'

const CustomInput = React.forwardRef(
  (
    {
      id,
      label,
      register,
      placeholder,
      errors,
      isRequired = true,
      type = 'text',
      disabled = false,
      accept = '',
    },
    ref,
  ) => {
    return (
      <FormGroup>
        <label htmlFor={id}>
          {label} {isRequired && <sup className="text-danger">*</sup>}
        </label>
        <input
          ref={ref}
          id={id}
          className="form-control"
          {...register}
          placeholder={placeholder}
          type={type}
          disabled={disabled}
          accept={accept}
        />
        {errors[id] && (
          <span className="text-danger">{errors[id]?.message}</span>
        )}
      </FormGroup>
    )
  },
)

export default CustomInput
