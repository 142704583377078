import {
  CREATE_PATIENT_HANDOUT,
  SET_PATIENT_HANDOUT,
  SHOW_PATIENT_HANDOUTS,
  UPDATE_PATIENT_HANDOUT,
} from '../type'

const initialState = {
  patientHandouts: [],
  patientHandout: {},
}

const patientHandout = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_PATIENT_HANDOUTS:
      return {
        ...state,
        patientHandouts: action.payload,
      }
    case CREATE_PATIENT_HANDOUT:
      return {
        ...state,
        patientHandouts: [action.payload, ...state.patientHandouts],
      }
    case SET_PATIENT_HANDOUT:
      return {
        ...state,
        patientHandout: action.payload,
      }
    case UPDATE_PATIENT_HANDOUT:
      let index = state.patientHandouts.findIndex(
        (patientHandout) => patientHandout.id === action.payload.id,
      )
      state.patientHandouts[index] = action.payload
      if (state.patientHandout.id === action.payload.id) {
        state.patientHandout = action.payload
      }
      return {
        ...state,
      }
    default:
      return state
  }
}

export default patientHandout
