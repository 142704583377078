import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button, Form } from 'reactstrap'
import CustomSelect from 'components/Inputs/CustomSelect'
import CustomModal from 'components/Modal/CustomModal'
import CustomDateInput from 'components/Inputs/CustomDateInput'
import { connect } from 'react-redux'
import { updateTraining } from 'store/actions'
import { types } from 'variables/options'

const TrainingUpdate = ({
  isOpen,
  toggle,
  status,
  volunteer,
  training,
  updateTraining,
}) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm()

  const [loading, setLoading] = useState(false)

  const [type, setType] = useState(null)
  const [selectedVolunteer, setSelectedVolunteer] = useState(null)

  const volunteers = volunteer.volunteers.map((element) => ({
    value: element.id,
    label: element.name,
  }))

  useEffect(() => {
    const { volunteer, date, type } = training.training

    setValue('volunteer', { value: volunteer?.id, label: volunteer?.name })
    setSelectedVolunteer({ value: volunteer?.id, label: volunteer?.name })
    setValue('date', date)
    setValue('type', { value: type, label: type })
    setType({ value: type, label: type })
  }, [setValue, training.training])

  const onSubmit = async (data) => {
    setLoading(true)
    await updateTraining(training.training.id, {
      volunteer_id: data.volunteer.value,
      date: data.date,
      type: data.type.value,
    })
    setLoading(false)
  }

  useEffect(() => {
    if (status.success) {
      toggle(false)
    }
  }, [status.success, toggle])

  return (
    <CustomModal
      isOpen={isOpen}
      title="Update Training"
      onClick={() => toggle(false)}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-body">
          <CustomSelect
            id="volunteer"
            label="Select Volunteer"
            rules={{ required: 'Volunteer is required!' }}
            control={control}
            options={volunteers}
            value={selectedVolunteer}
            setData={setSelectedVolunteer}
            placeholder="Select volunteer"
          />
          <CustomDateInput
            id="date"
            label="Date"
            register={{
              ...register('date', {
                required: 'Date is required!',
              }),
            }}
            placeholder="Select Date"
            errors={errors}
          />
          <CustomSelect
            id="type"
            label="Select Type"
            rules={{ required: 'Type is required!' }}
            control={control}
            options={types}
            value={type}
            setData={setType}
            placeholder="Select type"
          />
        </div>
        <div className="modal-footer">
          <Button color="primary" size="sm" type="submit" disabled={loading}>
            {loading ? 'Loading' : 'Update'}
          </Button>
        </div>
      </Form>
    </CustomModal>
  )
}

const mapStateToProps = (store) => ({
  status: store.status,
  volunteer: store.volunteer,
  training: store.training,
})

export default connect(mapStateToProps, { updateTraining })(TrainingUpdate)
