import { SET_CONTACT_TRACY, SHOW_CONTACT_TRACIES } from '../type'

const initialState = {
  contactTracies: [],
  contactTracy: {},
  total: 0,
}

const contactTracy = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_CONTACT_TRACIES:
      return {
        ...state,
        contactTracies: action.payload.data,
        total: action.payload.total,
      }
    case SET_CONTACT_TRACY:
      return {
        ...state,
        contactTracy: action.payload,
      }

    default:
      return state
  }
}

export default contactTracy
