import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { Container, Card, CardHeader, Row, Col, Button } from 'reactstrap'
import BackBtn from 'utils/backBtn'
import { years } from 'variables/options'
import { reportMonths } from 'variables/options'
import styles from './FacilitatorReport.module.css'
import { connect } from 'react-redux'
import { getTownships, getFacilitatorReport } from 'store/actions'
import FullScreenLoading from 'components/FullScreenLoading/FullScreenLoading'
import { isVolunteerSupervisor } from 'utils/middleware'
import { useHistory } from 'react-router'
import queryString from 'query-string'
import classnames from 'classnames'
import ReactHTMLTableToExcel from 'react-html-table-to-excel'

const FacilitatorReport = ({
  status,
  auth,
  township,
  report,
  getTownships,
  getFacilitatorReport,
}) => {
  const { role } = auth.user
  const router = useHistory()

  const [month, setMonth] = useState(null)
  const [year, setYear] = useState(null)
  const [selectedTownship, setSelectedTownship] = useState(null)

  const townships = township.townships.map((element) => ({
    value: element.id,
    label: element.name,
  }))

  useEffect(() => {
    if (role !== undefined && !isVolunteerSupervisor(role)) {
      getTownships()
    }
  }, [getTownships, role])

  const handleFilter = () => {
    const query = queryString.parse(router.location.search)
    if (selectedTownship) {
      query.township_id = selectedTownship.value
    } else {
      delete query['township_id']
    }

    if (month) {
      query.month = month.value
    } else {
      delete query['month']
    }

    if (year) {
      query.year = year.value
    } else {
      delete query['year']
    }

    router.push(`${router.location.pathname}?${queryString.stringify(query)}`)
  }

  useEffect(() => {
    const query = queryString.parse(router.location.search)
    getFacilitatorReport(query)
  }, [getFacilitatorReport, router.location.search])

  if (status.loading) {
    return <FullScreenLoading />
  }

  const {
    // reported_volunteers,
    _1_male,
    _1_female,
    _1_total,
    _2_male,
    _2_female,
    _2_total,
    _3_total,
    _4_male,
    _4_female,
    _4_total,
    _5_MCXRClinic_female,
    _5_MCXRClinic_male,
    _5_MCXRClinic_total,
    _5_NTP_female,
    _5_NTP_male,
    _5_NTP_total,
    _5_PPNGOClinic_female,
    _5_PPNGOClinic_male,
    _5_PPNGOClinic_total,
    _6_MCXRClinic_female,
    _6_MCXRClinic_male,
    _6_MCXRClinic_total,
    _6_NTP_female,
    _6_NTP_male,
    _6_NTP_total,
    _6_PPNGOClinic_female,
    _6_PPNGOClinic_male,
    _6_PPNGOClinic_total,
    _7_MCXRClinic_female,
    _7_MCXRClinic_male,
    _7_MCXRClinic_total,
    _7_NTP_female,
    _7_NTP_male,
    _7_NTP_total,
    _7_PPNGOClinic_female,
    _7_PPNGOClinic_male,
    _7_PPNGOClinic_total,
    _8_MCXRClinic_female,
    _8_MCXRClinic_male,
    _8_MCXRClinic_total,
    _8_NTP_female,
    _8_NTP_male,
    _8_NTP_total,
    _8_PPNGOClinic_female,
    _8_PPNGOClinic_male,
    _8_PPNGOClinic_total,
    _9_female,
    _9_male,
    _9_total,
    _10_total,
  } = report.facilitatorReport

  let fileName = 'Facilitator-Report'
  if (month) {
    fileName += `-${month.label}`
  }
  if (year) {
    fileName += `-${year.label}`
  }
  if (selectedTownship) {
    fileName += `-${selectedTownship.label}`
  }

  return (
    <Container className="mt-3" fluid>
      <BackBtn />
      <Card>
        <CardHeader className="border-0">
          <Row>
            <Col xs={6}>
              <h3 className="mb-0">Facilitator Report</h3>
            </Col>
            <Col className="text-right" xs={6}>
              <ReactHTMLTableToExcel
                className="btn btn-info btn-sm"
                table="table-to-xls"
                filename={fileName}
                sheet="Facilitator-Report"
                buttonText="Export"
              />
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <Select
                className="mt-3"
                options={reportMonths}
                value={month}
                onChange={(value) => setMonth(value)}
                placeholder="Filter by month..."
                isSearchable={true}
                isClearable={true}
              />
            </Col>
            <Col md={3}>
              <Select
                className="mt-3"
                options={years}
                value={year}
                onChange={(value) => setYear(value)}
                placeholder="Filter by year..."
                isSearchable={true}
                isClearable={true}
              />
            </Col>
            {role !== undefined && !isVolunteerSupervisor(role) && (
              <Col sm={3}>
                <Select
                  className="mt-3"
                  options={townships}
                  value={selectedTownship}
                  onChange={(value) => setSelectedTownship(value)}
                  placeholder="Filter by township..."
                  isSearchable={true}
                  isClearable={true}
                />
              </Col>
            )}
            <Col md={3}>
              <Button
                className="mt-3"
                size="sm"
                color="success"
                onClick={handleFilter}
              >
                <i className="fa fa-search" />
              </Button>
            </Col>
          </Row>
        </CardHeader>

        <table id="table-to-xls" className={styles.styled_table}>
          <thead>
            <tr>
              <th scope="col">No</th>
              <th colSpan={2} scope="col">
                Indicators
              </th>
              <th></th>
              <th scope="col">Male</th>
              <th scope="col">Female</th>
              <th scope="col">Total</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td colSpan={2}>Number Of Trained Volunteer</td>
              <td>
                <i
                  className={classnames(
                    'fa fa-question-circle',
                    styles.tooltip,
                  )}
                >
                  <span className={classnames(styles.tooltiptext)}>
                    Training ကျား မ ပေါင်း
                  </span>
                </i>
              </td>
              <td>{_1_male}</td>
              <td>{_1_female}</td>
              <td>{_1_total}</td>
            </tr>
            <tr>
              <td>2</td>
              <td colSpan={2}>Number Of reported Volunteer</td>
              <td></td>
              <td>{_2_male}</td>
              <td>{_2_female}</td>
              <td>{_2_total}</td>
            </tr>
            <tr>
              <td>3</td>
              <td colSpan={2}>
                Number of health education sessions conducted by volunteers
              </td>
              <td>
                <i
                  className={classnames(
                    'fa fa-question-circle',
                    styles.tooltip,
                  )}
                >
                  <span className={classnames(styles.tooltiptext)}>
                    HE အရေအတွက်
                  </span>
                </i>
              </td>
              <td style={{ backgroundColor: '#AFAAAA' }}></td>
              <td style={{ backgroundColor: '#AFAAAA' }}></td>
              <td>{_3_total}</td>
            </tr>
            <tr>
              <td>4</td>
              <td colSpan={2}>
                Number Of Health education attendees in this township
              </td>
              <td>
                <i
                  className={classnames(
                    'fa fa-question-circle',
                    styles.tooltip,
                  )}
                >
                  <span className={classnames(styles.tooltiptext)}>
                    HE ကျား မ ပေါင်း
                  </span>
                </i>
              </td>
              <td>{_4_male}</td>
              <td>{_4_female}</td>
              <td>{_4_total}</td>
            </tr>
            <tr>
              <td rowSpan={3}>5</td>
              <td rowSpan={3}>
                Number Of Presumptive TB cases referred by volunteers for
                examination (exclude referred from Contact Tracing)
              </td>
              <td>NTP</td>
              <td>
                <i
                  className={classnames(
                    'fa fa-question-circle',
                    styles.tooltip,
                  )}
                >
                  <span className={classnames(styles.tooltiptext)}>
                    HE နှင့် Community ကျား မ ပေါင်း
                  </span>
                </i>
              </td>
              <td>{_5_NTP_male}</td>
              <td>{_5_NTP_female}</td>
              <td>{_5_NTP_total}</td>
            </tr>
            <tr>
              <td>Private Partnership/NGO Clinic</td>
              <td></td>
              <td>{_5_PPNGOClinic_male}</td>
              <td>{_5_PPNGOClinic_female}</td>
              <td>{_5_PPNGOClinic_total}</td>
            </tr>
            <tr>
              <td>MCXR Clinic</td>
              <td></td>
              <td>{_5_MCXRClinic_male}</td>
              <td>{_5_MCXRClinic_female}</td>
              <td>{_5_MCXRClinic_total}</td>
            </tr>
            <tr>
              <td rowSpan={3}>6</td>
              <td rowSpan={3}>
                Number Of Total TB Patients referred by Volunteers (exclude TB
                cases referred from contact Tracing)
              </td>
              <td>NTP</td>
              <td>
                <i
                  className={classnames(
                    'fa fa-question-circle',
                    styles.tooltip,
                  )}
                >
                  <span className={classnames(styles.tooltiptext)}>
                    Patient ရဲ့ case founded activity(Community, HE) နှင့် TB
                    ဖြစ်သည့် ကျား မ ပေါင်း
                  </span>
                </i>
              </td>
              <td>{_6_NTP_male}</td>
              <td>{_6_NTP_female}</td>
              <td>{_6_NTP_total}</td>
            </tr>
            <tr>
              <td>Private Partnership/NGO Clinic</td>
              <td></td>
              <td>{_6_PPNGOClinic_male}</td>
              <td>{_6_PPNGOClinic_female}</td>
              <td>{_6_PPNGOClinic_total}</td>
            </tr>
            <tr>
              <td>MCXR Clinic</td>
              <td></td>
              <td>{_6_MCXRClinic_male}</td>
              <td>{_6_MCXRClinic_female}</td>
              <td>{_6_MCXRClinic_total}</td>
            </tr>
            <tr>
              <td rowSpan={3}>7</td>
              <td rowSpan={3}>
                Number Of Presumptive TB cases referred by contact tracing
              </td>
              <td>NTP</td>
              <td>
                <i
                  className={classnames(
                    'fa fa-question-circle',
                    styles.tooltip,
                  )}
                >
                  <span className={classnames(styles.tooltiptext)}>
                    CT ကျား မ ပေါင်း
                  </span>
                </i>
              </td>
              <td>{_7_NTP_male}</td>
              <td>{_7_NTP_female}</td>
              <td>{_7_NTP_total}</td>
            </tr>
            <tr>
              <td>Private Partnership/NGO Clinic</td>
              <td></td>
              <td>{_7_PPNGOClinic_male}</td>
              <td>{_7_PPNGOClinic_female}</td>
              <td>{_7_PPNGOClinic_total}</td>
            </tr>
            <tr>
              <td>MCXR Clinic</td>
              <td></td>
              <td>{_7_MCXRClinic_male}</td>
              <td>{_7_MCXRClinic_female}</td>
              <td>{_7_MCXRClinic_total}</td>
            </tr>
            <tr>
              <td rowSpan={3}>8</td>
              <td rowSpan={3}>
                Number Of Presumptive TB cases diagnosed as TB referred by
                contact tracing
              </td>
              <td>NTP</td>
              <td>
                <i
                  className={classnames(
                    'fa fa-question-circle',
                    styles.tooltip,
                  )}
                >
                  <span className={classnames(styles.tooltiptext)}>
                    Patient ရဲ့ case founded activity(CT) နှင့် TB ဖြစ်သည့် ကျား
                    မ ပေါင်း
                  </span>
                </i>
              </td>
              <td>{_8_NTP_male}</td>
              <td>{_8_NTP_female}</td>
              <td>{_8_NTP_total}</td>
            </tr>
            <tr>
              <td>Private Partnership/NGO Clinic</td>
              <td></td>
              <td>{_8_PPNGOClinic_male}</td>
              <td>{_8_PPNGOClinic_female}</td>
              <td>{_8_PPNGOClinic_total}</td>
            </tr>
            <tr>
              <td>MCXR Clinic</td>
              <td></td>
              <td>{_8_MCXRClinic_male}</td>
              <td>{_8_MCXRClinic_female}</td>
              <td>{_8_MCXRClinic_total}</td>
            </tr>
            <tr>
              <td>9</td>
              <td colSpan={2}>
                Number Of TB patients on DOTS provided by Volunteers
              </td>
              <td>
                <i
                  className={classnames(
                    'fa fa-question-circle',
                    styles.tooltip,
                  )}
                >
                  <span className={classnames(styles.tooltiptext)}>
                    Patient ရဲ့ DOT start date ရှိပြီး DOT end date မရှိသည့်
                    ကျား မ ပေါင်း
                  </span>
                </i>
              </td>
              <td>{_9_male}</td>
              <td>{_9_female}</td>
              <td>{_9_total}</td>
            </tr>
            <tr>
              <td>10</td>
              <td colSpan={2}>Number Of Supervision visited by Facilitator</td>
              <td>
                <i
                  className={classnames(
                    'fa fa-question-circle',
                    styles.tooltip,
                  )}
                >
                  <span className={classnames(styles.tooltiptext)}>
                    ကွင်းဆင်းကြီးကြပ်စာရင်း ပေါင်း
                  </span>
                </i>
              </td>
              <td style={{ backgroundColor: '#AFAAAA' }}></td>
              <td style={{ backgroundColor: '#AFAAAA' }}></td>
              <td>{_10_total}</td>
            </tr>
          </tbody>
        </table>
      </Card>
    </Container>
  )
}

const mapStateToProps = (store) => ({
  status: store.status,
  auth: store.auth,
  township: store.township,
  report: store.report,
})

export default connect(mapStateToProps, {
  getTownships,
  getFacilitatorReport,
})(FacilitatorReport)
