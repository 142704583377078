import React, { useEffect, useState } from 'react'
import CustomPagination from 'components/Pagination/Pagination'
import CustomTable from 'components/Table/Table'
import { Button, Card, CardHeader, Container, Row, Col } from 'reactstrap'
import TrainingCreate from './TrainingCreate'
import queryString from 'query-string'
import { useHistory, useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  getVolunteerTrainings,
  deleteTraining,
  getTraining,
  getVolunteers,
  getTownships,
  getVolunteer,
} from 'store/actions'
import FullScreenLoading from 'components/FullScreenLoading/FullScreenLoading'
import { paginationLimit } from 'variables/limits'
import CustomAlert from 'components/Alert/CustomAlert'
import {
  isVolunteerSupervisor,
  activitiesMiddleware,
  CREATE,
} from 'utils/middleware'
import TrainingUpdate from './TrainingUpdate'
import { NotificationManager } from 'react-notifications'
import { call } from 'services/api'
import { excelExport } from 'utils/excelExport'
import BackBtn from 'utils/backBtn'

const VolunteerTraingList = ({
  status,
  auth,
  training,
  volunteer,
  getVolunteerTrainings,
  deleteTraining,
  getTraining,
  getVolunteers,
  getTownships,
  getVolunteer,
}) => {
  const { role } = auth.user
  const router = useHistory()
  const { id } = useParams()
  const [alert, setAlert] = useState(null)

  const [exportLoading, setExportLoading] = useState(false)
  const [isCreateOpen, setIsCreateOpen] = useState(false)
  const [isUpdateOpen, setIsUpdateOpen] = useState(false)

  const [monthYear, setMonthYear] = useState('')

  const handleFilter = () => {
    const query = queryString.parse(router.location.search)
    query.page = 1
    if (monthYear !== '') {
      query.month_year = monthYear
    } else {
      delete query['month_year']
    }

    router.push(`${router.location.pathname}?${queryString.stringify(query)}`)
  }

  useEffect(() => {
    const query = queryString.parse(router.location.search)
    if (!('page' in query)) {
      query.page = 1
    }
    getVolunteerTrainings(id, query)
  }, [getVolunteerTrainings, id, router.location.search])

  useEffect(() => {
    getVolunteer(id)
  }, [getVolunteer, id])

  useEffect(() => {
    if (role !== undefined && !isVolunteerSupervisor(role)) {
      getTownships()
    }
  }, [getTownships, role])

  useEffect(() => {
    getVolunteers()
  }, [getVolunteers])

  const handleDelete = (id) => {
    setAlert(
      <CustomAlert
        onConfirm={() => {
          deleteTraining(id)
          setAlert(null)
        }}
        onCancel={() => setAlert(null)}
      />,
    )
  }

  if (status.loading) {
    return <FullScreenLoading />
  }

  const handleExport = async () => {
    setExportLoading(true)
    try {
      const query = queryString.parse(router.location.search)
      const response = await call(
        'get',
        `volunteer-trainings-export/${id}?${new URLSearchParams(
          query,
        ).toString()}`,
      )
      const result = response.data.map((data, index) => ({
        ID: index + 1,
        Volunteer: data.volunteer?.name,
        Township: data.volunteer?.township?.name,
        Date: data.date,
        Type: data.type,
      }))

      let fileName = 'Volunteer-Trainings'
      if (monthYear) {
        fileName += `-${monthYear}`
      }

      if (response.status === 'success') {
        excelExport(result, fileName)
      }
    } catch (_) {
      NotificationManager.error('Please try again!')
    }
    setExportLoading(false)
  }

  return (
    <>
      {alert}
      <Container className="mt-3" fluid>
        <BackBtn />
        <Card>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-0">
                  (
                  {`${volunteer.volunteer?.name} - ${volunteer.volunteer?.township?.name}`}
                  )သင်တန်းရရှိမှုစာရင်း
                </h3>
              </Col>
              {activitiesMiddleware(role, CREATE) && (
                <Col className="text-right" xs="6">
                  <Button
                    size="sm"
                    color="success"
                    onClick={() => setIsCreateOpen(true)}
                    className="mb-3"
                  >
                    New
                  </Button>
                </Col>
              )}
            </Row>
            <Row>
              <Col sm={3}>
                <label htmlFor="month_year">Month-Year</label>
                <input
                  id="month_year"
                  className="form-control"
                  type="month"
                  value={monthYear}
                  onChange={(event) => setMonthYear(event.target.value)}
                />
              </Col>

              <Col sm={3}>
                <Button
                  size="sm"
                  color="success"
                  onClick={handleFilter}
                  className="mt-4"
                >
                  <i className="fa fa-search" />
                </Button>
                <Button
                  size="sm"
                  color="info"
                  className="mt-4"
                  onClick={handleExport}
                  disabled={exportLoading}
                >
                  {exportLoading ? 'Loading' : 'Export'}
                </Button>
              </Col>
            </Row>
          </CardHeader>

          <CustomTable
            header={
              <tr>
                <th scope="col">ID</th>
                <th scope="col">Date</th>
                <th scope="col">Type</th>
                {activitiesMiddleware(role, CREATE) && (
                  <th scope="col">Action</th>
                )}
              </tr>
            }
            body={training.trainings.map((training, index) => (
              <tr key={training.id}>
                <td>{index + 1}</td>
                <td>{training.date}</td>
                <td>{training.type}</td>
                {activitiesMiddleware(role, CREATE) && (
                  <td>
                    <Button
                      size="sm"
                      color="primary"
                      onClick={async () => {
                        await getTraining(training.id)
                        setIsUpdateOpen(true)
                      }}
                    >
                      Update
                    </Button>
                    <Button
                      size="sm"
                      color="danger"
                      onClick={() => handleDelete(training.id)}
                    >
                      Delete
                    </Button>
                  </td>
                )}
              </tr>
            ))}
          />

          {training.total > paginationLimit && (
            <CustomPagination pageCount={training.total / paginationLimit} />
          )}
        </Card>
      </Container>

      <TrainingUpdate isOpen={isUpdateOpen} toggle={setIsUpdateOpen} />
      <TrainingCreate isOpen={isCreateOpen} toggle={setIsCreateOpen} />
    </>
  )
}

const mapStateToProps = (store) => ({
  status: store.status,
  auth: store.auth,
  volunteer: store.volunteer,
  training: store.training,
})

export default connect(mapStateToProps, {
  getVolunteerTrainings,
  getTraining,
  getVolunteers,
  getTownships,
  deleteTraining,
  getVolunteer,
})(VolunteerTraingList)
