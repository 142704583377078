import React, { useState } from 'react'
import { useHistory } from 'react-router'
import { Button, Row, Col, Container, Card, CardHeader } from 'reactstrap'
import BackBtn from 'utils/backBtn'
import ChestXRay from './DetailComponents/ChestXRay'
import DrugSusceptibility from './DetailComponents/DrugSusceptibility'
import FNAC from './DetailComponents/FNAC'
import GeneXpert from './DetailComponents/GeneXpert'
import OtherInvestigation from './DetailComponents/OtherInvestigation'
import PresumptiveInfo from './DetailComponents/PresumptiveInfo'
import Sputum from './DetailComponents/Sputum'
import SputumCulture from './DetailComponents/SputumCulture'
import TBConclusion from './DetailComponents/TBConclusion'
import TrueNat from './DetailComponents/TrueNat'
import TuberculinSkin from './DetailComponents/TuberculinSkin'

const NoDOTDSTBPatientDetail = () => {
  const history = useHistory()
  const [page, setPage] = useState('')

  const handleShowPage = () => {
    switch (page) {
      case 'Presumptive_Info':
        return <PresumptiveInfo />
      case 'Sputum':
        return <Sputum />
      case 'Chest_XRay':
        return <ChestXRay />
      case 'Gene_Xpert':
        return <GeneXpert />
      case 'Truenat':
        return <TrueNat />
      case 'FNAC':
        return <FNAC />
      case 'Tuberculin_Skin':
        return <TuberculinSkin />
      case 'Sputum_Culture':
        return <SputumCulture />
      case 'Drug_Susceptibility':
        return <DrugSusceptibility />
      case 'Other_Investigation':
        return <OtherInvestigation />
      case 'TB_conclusion':
        return <TBConclusion />
      default:
        return <PresumptiveInfo />
    }
  }

  return (
    <Container className="mt-3" fluid>
      <BackBtn />
      <Card>
        <CardHeader className="border-0">
          <Row className="mb-2">
            <Col>
              <Button
                color="primary"
                size="sm"
                onClick={() => setPage('Presumptive_Info')}
              >
                PRESUMPTIVE INFO
              </Button>
              <Button
                color="primary"
                size="sm"
                onClick={() => setPage('Sputum')}
              >
                SPUTUM
              </Button>
              <Button
                color="primary"
                size="sm"
                onClick={() => setPage('Chest_XRay')}
              >
                CHEST X-RAY
              </Button>
              <Button
                color="primary"
                size="sm"
                onClick={() => setPage('Gene_Xpert')}
              >
                GENE XPERT
              </Button>
              <Button
                color="primary"
                size="sm"
                onClick={() => setPage('Truenat')}
              >
                TRUENAT
              </Button>
              <Button color="primary" size="sm" onClick={() => setPage('FNAC')}>
                FNAC
              </Button>
              <Button
                color="primary"
                size="sm"
                onClick={() => setPage('Tuberculin_Skin')}
              >
                TUBERCULIN SKIN
              </Button>
              <Button
                color="primary"
                size="sm"
                onClick={() => setPage('Sputum_Culture')}
              >
                SPUTUM CULTURE
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button
                color="primary"
                size="sm"
                onClick={() => setPage('Drug_Susceptibility')}
              >
                DRUG SENSITIVITY
              </Button>
              <Button
                color="primary"
                size="sm"
                onClick={() => setPage('Other_Investigation')}
              >
                OTHER INVESTIGATION
              </Button>
              <Button
                color="primary"
                size="sm"
                onClick={() => setPage('TB_conclusion')}
              >
                TB CONCLUSION
              </Button>
              <Button
                color="success"
                size="sm"
                onClick={() => history.push(`/admin/move-to-DOT/1`)}
              >
                Move To VOT
              </Button>
            </Col>
          </Row>
        </CardHeader>
        {handleShowPage()}
      </Card>
    </Container>
  )
}

export default NoDOTDSTBPatientDetail
