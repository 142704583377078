import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import {
  Button,
  Form,
  Row,
  Col,
  CardBody,
  CardFooter,
  CardTitle,
} from 'reactstrap'
import CustomSelect from 'components/Inputs/CustomSelect'
import { dones } from 'variables/options'
import CustomDateInput from 'components/Inputs/CustomDateInput'
import { currentDate } from 'utils/currentDate'
import { FNACResults } from 'variables/options'
import { AFBSeenNots } from 'variables/options'
import { connect } from 'react-redux'
import { updatePatient } from 'store/actions'
import { useParams } from 'react-router'
import { useQuery } from 'hooks/useQuery'

const FNAC = ({ patient, updatePatient }) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm()
  const { id } = useParams()

  const fromTBPatient = useQuery("from_TB_patient");

  const [loading, setLoading] = useState(false)
  const [FNACDoneNotDone, setFNACDoneNotDone] = useState(null)
  const [FNACResult, setFNACResult] = useState(null)
  const [AFBSeenNot, setAFBSeenNot] = useState(null)

  useEffect(() => {
    const {
      FNAC_done_or_not_done,
      FNAC_examination_date,
      FNAC_result,
      AFB_seen_not,
    } = patient.patient

    setValue(
      'FNAC_done_not_done',
      FNAC_done_or_not_done === null
        ? null
        : { value: FNAC_done_or_not_done, label: FNAC_done_or_not_done },
    )
    setFNACDoneNotDone(
      FNAC_done_or_not_done === null
        ? null
        : { value: FNAC_done_or_not_done, label: FNAC_done_or_not_done },
    )
    setValue('FNAC_examination_date', FNAC_examination_date)
    setValue(
      'FNAC_result',
      FNAC_result === null ? null : { value: FNAC_result, label: FNAC_result },
    )
    setFNACResult(
      FNAC_result === null ? null : { value: FNAC_result, label: FNAC_result },
    )
    setValue(
      'AFB_seen_not',
      AFB_seen_not === null
        ? null
        : { value: AFB_seen_not, label: AFB_seen_not },
    )
    setAFBSeenNot(
      AFB_seen_not === null
        ? null
        : { value: AFB_seen_not, label: AFB_seen_not },
    )
  }, [setValue, patient.patient])

  const onSubmit = async (data) => {
    setLoading(true)
    await updatePatient(
      id,
      {
        FNAC_done_or_not_done: data.FNAC_done_not_done?.value,
        FNAC_examination_date:
          data.FNAC_done_not_done?.value === 'Not Done'
            ? null
            : data.FNAC_examination_date,
        FNAC_result:
          data.FNAC_done_not_done?.value === 'Not Done'
            ? null
            : data.FNAC_result?.value,
        AFB_seen_not:
          data.FNAC_done_not_done?.value === 'Not Done'
            ? null
            : data.AFB_seen_not?.value,
      },
      'FNAC',
      'FNAC',
    )
    setLoading(false)
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <CardBody>
        <CardTitle>
          <h3>FNAC Examination</h3>
        </CardTitle>
        <Row>
          <Col md={4}>
            <CustomSelect
              id="FNAC_done_not_done"
              label="FNAC Done or Not Done"
              control={control}
              rules={{
                required: 'FNAC Done or Not Done is required!',
              }}
              options={dones}
              value={FNACDoneNotDone}
              setData={setFNACDoneNotDone}
              placeholder="FNAC Done or Not Done"
              isRequired={true}
            />
          </Col>
          <Col md={4}>
            <CustomDateInput
              id="FNAC_examination_date"
              label="FNAC Examination Date"
              register={{
                ...register('FNAC_examination_date', {
                  required:
                    FNACDoneNotDone?.value === 'Done'
                      ? 'FNAC Examination Date is required!'
                      : false,
                }),
              }}
              placeholder="Select FNAC Examination Date"
              errors={errors}
              max={currentDate()}
              disabled={
                FNACDoneNotDone === null || FNACDoneNotDone?.value !== 'Done'
              }
              isRequired={FNACDoneNotDone?.value === 'Done'}
            />
          </Col>
          <Col md={4}>
            <CustomSelect
              id="FNAC_result"
              label="FNAC Result"
              control={control}
              rules={{
                required:
                  FNACDoneNotDone?.value === 'Done'
                    ? 'FNAC Result is required!'
                    : false,
              }}
              options={FNACResults}
              value={FNACResult}
              setData={setFNACResult}
              placeholder="FNAC Result"
              isRequired={FNACDoneNotDone?.value === 'Done'}
              isDisabled={
                FNACDoneNotDone === null || FNACDoneNotDone?.value !== 'Done'
              }
            />
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <CustomSelect
              id="AFB_seen_not"
              label="AFB Seen/not"
              control={control}
              rules={{
                required:
                  FNACDoneNotDone?.value === 'Done'
                    ? 'AFB Seen/not is required!'
                    : false,
              }}
              options={AFBSeenNots}
              value={AFBSeenNot}
              setData={setAFBSeenNot}
              placeholder="AFB Seen/not"
              isRequired={FNACDoneNotDone?.value === 'Done'}
              isDisabled={
                FNACDoneNotDone === null || FNACDoneNotDone?.value !== 'Done'
              }
            />
          </Col>
        </Row>
      </CardBody>
      <CardFooter className="text-right">
      {fromTBPatient && (
          <Button color="primary" size="sm" disabled={loading} type="submit">
            {loading ? "Loading" : "Update"}
          </Button>
        )}
      </CardFooter>
    </Form>
  )
}

const mapStateToProps = (store) => ({
  patient: store.patient,
})

export default connect(mapStateToProps, { updatePatient })(FNAC)
