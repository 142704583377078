import Login from "views/pages/auth/Login";
import Dashboard from "views/pages/dashboard";
import AccountList from "views/pages/dashboard/account/AccountList";
import DistrictList from "views/pages/dashboard/basicData/district/DistrictList";
import RegionList from "views/pages/dashboard/basicData/region/RegionList";
import TownshipList from "views/pages/dashboard/basicData/township/TownshipList";
import VolunteerReport from "views/pages/dashboard/report/VolunteerReport";
import HEList from "views/pages/dashboard/volunteer/he/HEList";
import TakeMedicineList from "views/pages/dashboard/volunteer/takeMedicine/TakeMedicineList";
import TraingList from "views/pages/dashboard/volunteer/training/TrainingList";
import VolunteerDetail from "views/pages/dashboard/volunteer/VolunteerDetail";
import VolunteerList from "views/pages/dashboard/volunteer/VolunteerList";
import FacilitatorReport from "views/pages/dashboard/report/FacilitatorReport";
import NTPCBTBCTemplate from "views/pages/dashboard/report/NTPCBTBCTemplate";
import VolunteerHandoutList from "views/pages/dashboard/handout/VolunteerHandout/VolunteerHandoutList";
import PatientHandoutList from "views/pages/dashboard/handout/PatientHandout/PatientHandoutList";
import VolunteerTrainingList from "views/pages/dashboard/volunteer/training/VolunteerTrainingList";
import ChangePassword from "views/pages/auth/ChangePassword";
import DSTBPatientList from "views/pages/dashboard/DSTBPatient/DSTBPatientList";
import DSTBPatientCreate from "views/pages/dashboard/DSTBPatient/DSTBPatientCreate";
import DSTBPatientDetail from "views/pages/dashboard/DSTBPatient/DSTBPatientDetail";
import MoveToDOT from "views/pages/dashboard/DSTBPatient/MoveToDOT";
import ChangeVolunteerCreate from "views/pages/dashboard/DSTBPatient/changeVolunteer/ChangeVolunteerCreate";
import ChangeVolunteerList from "views/pages/dashboard/DSTBPatient/changeVolunteer/ChangeVolunteerList";
import DOTPatientWithVolunteerList from "views/pages/dashboard/DSTBPatient/DOTPatientWithVolunteerList";
import DOTPatientWithVolunteerDetail from "views/pages/dashboard/DSTBPatient/DOTPatientWithVolunteerDetail";
import DotList from "views/pages/dashboard/DSTBPatient/dot/DotList";
import PatientMedicineList from "views/pages/dashboard/DSTBPatient/patientMedicine/PatientMedicineList";
import FUSputumExaminitionList from "views/pages/dashboard/DSTBPatient/FUSputumExaminition/FUSputumExaminitionList";
import InfectionCheckList from "views/pages/dashboard/DSTBPatient/infectionCheck/InfectionCheckList";
import DOTPatientWithNoVolunteerList from "views/pages/dashboard/DSTBPatient/DOTPatientWithNoVolunteerList";
import DOTPatientWithNoVolunteerDetail from "views/pages/dashboard/DSTBPatient/DOTPatientWithNoVolunteerDetail";
import NoDOTDSTBPatientList from "views/pages/dashboard/DSTBPatient/NoDOTDSTBPatientList";
import NoDOTDSTBPatientDetail from "views/pages/dashboard/DSTBPatient/NoDOTDSTBPatientDetail";
import NoVolunteerDotList from "views/pages/dashboard/DSTBPatient/noVolunteerDOT/NoVolunteerDotList";
import NoAppDotList from "views/pages/dashboard/DSTBPatient/dotNoApp/NoAppDotList";
import DSTBPatientTrashList from "views/pages/dashboard/trash/DSTBPatientTrash/DSTBPatientTrashList";
import SupervisionVisitList from "views/pages/dashboard/volunteer/supervisionVisit/SupervisionVisitList";
import ContactTracyList from "views/pages/dashboard/DSTBPatient/contactTracy/ContactTracyList";
import CommunityList from "views/pages/dashboard/volunteer/community/CommunityList";
import CommunityCreate from "views/pages/dashboard/volunteer/community/CommunityCreate";
import CommunityDetail from "views/pages/dashboard/volunteer/community/CommunityDetail";
import MedicineList from "views/pages/dashboard/basicData/medicine/MedicineList";
import TBPatientList from "views/pages/dashboard/DSTBPatient/TBPatientList";
import VolunteerTrashList from "views/pages/dashboard/trash/VolunteerTrash/VolunteerTrashList";
import AISReport from "views/pages/dashboard/report/AISReport";
import VolunteerVirtualization from "views/pages/dashboard/dataVirtualization/VolunteerVirtualization";
import PatientVirtualization from "views/pages/dashboard/dataVirtualization/PatientVirtualization";
import DOTStatusVirtualization from "views/pages/dashboard/dataVirtualization/DOTStatusVirtualization";
import ActivityVirtualization from "views/pages/dashboard/dataVirtualization/ActivityVirtualization";
import MonthlyVolunteerReport from "views/pages/dashboard/dataVirtualization/MonthlyVolunteerReport";
import DSTBEligiblePatientList from "views/pages/dashboard/DSTBPatient/DSTBEligiblePatientList";
import DSTBDeclinedPatientList from "views/pages/dashboard/DSTBPatient/DSTBDeclinedPatientList";
import DSTBAcceptablePatientList from "views/pages/dashboard/DSTBPatient/DSTBAcceptablePatientList";
import VOTReport from "views/pages/dashboard/report/VOTReport";
import DSTBScreenedPatientList from "views/pages/dashboard/DSTBPatient/DSTBScreenedPatientList";
import AllDOTList from "views/pages/dashboard/DSTBPatient/AllDOT/AllDOTList";
// import DOTClinicPatientList from 'views/pages/dashboard/clinicPatient/DOTClinicPatientList'
// import NoDOTClinicPatientList from 'views/pages/dashboard/clinicPatient/NoDOTClinicPatientList'
// import ClinicPatientList from 'views/pages/dashboard/clinicPatient/ClinicPatientList'

const routes = [
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-archive-2 text-green",
    component: Login,
    layout: "/auth",
    invisible: true,
  },
  {
    path: "/change-password",
    name: "ChangePassword",
    icon: "ni ni-archive-2 text-green",
    component: ChangePassword,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/account-list",
    name: "Accounts",
    icon: "ni ni-single-02 text-primary",
    component: AccountList,
    layout: "/admin",
  },
  {
    collapse: true,
    name: "Basic Data",
    icon: "ni ni-chart-bar-32 text-primary",
    state: "basicDataCollapse",
    views: [
      {
        path: "/region-list",
        name: "State/Regions",
        miniName: "RL",
        component: RegionList,
        layout: "/admin",
      },
      {
        path: "/district-list",
        name: "Districts",
        miniName: "DL",
        component: DistrictList,
        layout: "/admin",
      },
      {
        path: "/township-list",
        name: "Townships",
        miniName: "TL",
        component: TownshipList,
        layout: "/admin",
      },
      {
        path: "/medicine-list",
        name: "Medicines",
        miniName: "MN",
        component: MedicineList,
        layout: "/admin",
      },
    ],
  },
  {
    path: "/volunteer-list",
    name: "Volunteers",
    icon: "fas fa-user-friends text-primary",
    component: VolunteerList,
    layout: "/admin",
  },
  {
    path: "/volunteer-detail/:id",
    name: "Volunteer",
    icon: "ni ni-archive-2 text-blue",
    component: VolunteerDetail,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/volunteer-training-list/:id",
    name: "VolunteerTraining",
    icon: "ni ni-archive-2 text-blue",
    component: VolunteerTrainingList,
    layout: "/admin",
    invisible: true,
  },
  {
    collapse: true,
    name: "Patients",
    icon: "ni ni-collection text-primary",
    state: "CBTBCPatientsCollapse",
    views: [
      {
        path: "/DSTB-patient-list",
        name: "Presumptive TB Patients",
        miniName: "PP",
        component: DSTBPatientList,
        layout: "/admin",
      },
      {
        path: "/TB-patient-list",
        name: "TB Patients",
        miniName: "PP",
        component: TBPatientList,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "VOT Eligible",
    icon: "ni ni-chart-bar-32 text-primary",
    state: "VOTEligibleCollapse",
    views: [
      {
        path: "/eligible-patient-list",
        name: "Eligible Patients",
        miniName: "EP",
        component: DSTBEligiblePatientList,
        layout: "/admin",
      },
      {
        path: "/screened-patient-list",
        name: "Screened Patients",
        miniName: "SP",
        component: DSTBScreenedPatientList,
        layout: "/admin",
      },
      {
        path: "/acceptable-patient-list",
        name: "Acceptable Patients",
        miniName: "AP",
        component: DSTBAcceptablePatientList,
        layout: "/admin",
      },
      {
        path: "/declined-patient-list",
        name: "Declined Patients",
        miniName: "DP",
        component: DSTBDeclinedPatientList,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "VOT Status",
    icon: "ni ni-chart-bar-32 text-primary",
    state: "DOTPatientsCollapse",
    views: [
      {
        path: "/dot-patient-with-volunteer-list",
        name: "VOT patient with volunteer",
        miniName: "DV",
        component: DOTPatientWithVolunteerList,
        layout: "/admin",
      },
      {
        path: "/dot-patient-with-no-volunteer-list",
        name: "VOT patient without volunteer",
        miniName: "DNV",
        component: DOTPatientWithNoVolunteerList,
        layout: "/admin",
      },
      {
        path: "/no-dot-DSTB-patient-list",
        name: "No VOT patients",
        miniName: "ND",
        component: NoDOTDSTBPatientList,
        layout: "/admin",
      },
    ],
  },
  {
    path: "/DSTB-patient-create",
    name: "DSTBPatientCreate",
    icon: "ni ni-archive-2 text-blue",
    component: DSTBPatientCreate,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/DSTB-patient-detail/:id",
    name: "DSTBPatientDetail",
    icon: "ni ni-archive-2 text-blue",
    component: DSTBPatientDetail,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/dot-patient-with-volunteer-detail/:id",
    name: "DOTPatientWithVolunteerDetail",
    icon: "ni ni-archive-2 text-blue",
    component: DOTPatientWithVolunteerDetail,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/dot-patient-with-no-volunteer-detail/:id",
    name: "DOTPatientWithVolunteerDetail",
    icon: "ni ni-archive-2 text-blue",
    component: DOTPatientWithNoVolunteerDetail,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/no-dot-DSTB-patient-detail/:id",
    name: "NoDOTDSTBPatientDetail",
    icon: "ni ni-archive-2 text-blue",
    component: NoDOTDSTBPatientDetail,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/move-to-DOT/:id",
    name: "MoveToDOT",
    icon: "ni ni-archive-2 text-blue",
    component: MoveToDOT,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/change-volunteer-create/:id",
    name: "ChangeVolunteerCreate",
    icon: "ni ni-archive-2 text-blue",
    component: ChangeVolunteerCreate,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/change-volunteer-list/:id",
    name: "ChangeVolunteerList",
    icon: "ni ni-archive-2 text-blue",
    component: ChangeVolunteerList,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/dot-list/:id",
    name: "DOTs",
    icon: "ni ni-archive-2 text-blue",
    component: DotList,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/no-volunteer-dot-list/:id",
    name: "NoVolunteerDOTs",
    icon: "ni ni-archive-2 text-blue",
    component: NoVolunteerDotList,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/no-app-dot-list/:id",
    name: "NoAppDOTs",
    icon: "ni ni-archive-2 text-blue",
    component: NoAppDotList,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/VOT-calendar/:id",
    name: "VOTCalendar",
    icon: "ni ni-archive-2 text-blue",
    component: AllDOTList,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/patient-medicine-list/:id",
    name: "Patient Medicine",
    icon: "ni ni-archive-2 text-blue",
    component: PatientMedicineList,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/FU-sputum-examinition-list/:id",
    name: "F/U Sputum Examinitions",
    icon: "ni ni-archive-2 text-blue",
    component: FUSputumExaminitionList,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/infection-check-list/:id",
    name: "Infection Checks",
    icon: "ni ni-archive-2 text-blue",
    component: InfectionCheckList,
    layout: "/admin",
    invisible: true,
  },
  // {
  //   path: '/all-clinic-patient-list',
  //   name: 'Clinic Patient',
  //   icon: 'ni ni-archive-2 text-green',
  //   component: ClinicPatientList,
  //   layout: '/admin',
  // },
  // {
  //   collapse: true,
  //   name: 'Clinic DOT Patient',
  //   icon: 'ni ni-archive-2 text-green',
  //   state: 'clinicPatientsCollapse',
  //   views: [
  //     {
  //       path: '/dot-patient-clinic-list',
  //       name: 'DOT patient',
  //       miniName: 'DP',
  //       component: DOTClinicPatientList,
  //       layout: '/admin',
  //     },
  //     {
  //       path: '/no-dot-patient-clinic-list',
  //       name: 'No DOT patient',
  //       miniName: 'ND',
  //       component: NoDOTClinicPatientList,
  //       layout: '/admin',
  //     },
  //   ],
  // },
  {
    collapse: true,
    name: "Activities",
    icon: "ni ni-archive-2 text-primary",
    state: "ActivitiessCollapse",
    views: [
      {
        path: "/he-list",
        name: "ကျန်းမာရေးအသိပညာပေးခြင်း",
        miniName: "HE",
        component: HEList,
        layout: "/admin",
      },
      {
        path: "/take-medicine-list",
        name: "တီဘီဆေးရရှိမှုစာရင်း",
        miniName: "TM",
        component: TakeMedicineList,
        layout: "/admin",
      },
      {
        path: "/training-list",
        name: "သင်တန်းရရှိမှုစာရင်း",
        miniName: "TR",
        component: TraingList,
        layout: "/admin",
      },
      {
        path: "/supervision-visit-list",
        name: "ကွင်းဆင်းကြီးကြပ်စာရင်း",
        miniName: "TR",
        component: SupervisionVisitList,
        layout: "/admin",
      },
      {
        path: "/contact-tracy-list",
        name: "တီဘီလူနာနှင့်အတူနေသူစာရင်း",
        miniName: "CT",
        component: ContactTracyList,
        layout: "/admin",
      },
      {
        path: "/community-list",
        name: "ပြည်သူလုထုအတွင်းမှညွှန်းပို့ခြင်း",
        miniName: "CT",
        component: CommunityList,
        layout: "/admin",
      },
    ],
  },
  {
    path: "/community-create",
    name: "Community Create",
    icon: "ni ni-archive-2 text-blue",
    component: CommunityCreate,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/community-detail/:id",
    name: "Community Detail",
    icon: "ni ni-archive-2 text-blue",
    component: CommunityDetail,
    layout: "/admin",
    invisible: true,
  },
  {
    collapse: true,
    name: "Handouts",
    icon: "ni ni-archive-2 text-primary",
    state: "handoutCollapse",
    views: [
      {
        path: "/volunteer-handout",
        name: "Volunteer Handout",
        miniName: "VH",
        component: VolunteerHandoutList,
        layout: "/admin",
      },
      {
        path: "/patient-handout",
        name: "Patient Handout",
        miniName: "PH",
        component: PatientHandoutList,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Report",
    icon: "ni ni-book-bookmark text-primary",
    state: "reportCollapse",
    views: [
      {
        path: "/volunteer-report",
        name: "Volunteer Report",
        miniName: "VR",
        component: VolunteerReport,
        layout: "/admin",
      },
      {
        path: "/facilitator-report",
        name: "Facilitator Report",
        miniName: "FR",
        component: FacilitatorReport,
        layout: "/admin",
      },
      // {
      //   path: '/clinic-report',
      //   name: 'Clinic Report',
      //   miniName: 'CR',
      //   component: ClinicReport,
      //   layout: '/admin',
      // },
      {
        path: "/NTP-CBTBC-Report",
        name: "NTP CBTBC Report",
        miniName: "NTP",
        component: NTPCBTBCTemplate,
        layout: "/admin",
      },
      {
        path: "/AIS-Report",
        name: "AIS Report",
        miniName: "AIS",
        component: AISReport,
        layout: "/admin",
      },
      {
        path: "/VOT-Report",
        name: "VOT Report",
        miniName: "VOT",
        component: VOTReport,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Trash",
    icon: "ni ni-app text-primary",
    state: "trashCollapse",
    views: [
      {
        path: "/DSTB-patient-trash-list",
        name: "All Patients",
        miniName: "AP",
        component: DSTBPatientTrashList,
        layout: "/admin",
      },
      {
        path: "/volunteer-trash-list",
        name: "All Volunteers",
        miniName: "AV",
        component: VolunteerTrashList,
        layout: "/admin",
      },
    ],
  },
  {
    path: "/volunteer-virtualization",
    name: "Volunteer Virtualization",
    icon: "ni ni-archive-2 text-blue",
    component: VolunteerVirtualization,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/patient-virtualization",
    name: "Patient Virtualization",
    icon: "ni ni-archive-2 text-blue",
    component: PatientVirtualization,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/dot-status-virtualization",
    name: "Patient Virtualization",
    icon: "ni ni-archive-2 text-blue",
    component: DOTStatusVirtualization,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/activity-virtualization",
    name: "Patient Virtualization",
    icon: "ni ni-archive-2 text-blue",
    component: ActivityVirtualization,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/monthly-volunteer-report-visualization",
    name: "MVR Visualization",
    icon: "ni ni-archive-2 text-blue",
    component: MonthlyVolunteerReport,
    layout: "/admin",
    invisible: true,
  },
];

export default routes;
