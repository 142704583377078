import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import FullScreenLoading from "components/FullScreenLoading/FullScreenLoading";
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  CardHeader,
  CardTitle,
  Button,
} from "reactstrap";
import { getTownships } from "store/actions";
import { isVolunteerSupervisor } from "utils/middleware";
import queryString from "query-string";
import { useHistory } from "react-router";
import { currentDate } from "utils/currentDate";
import { getActivityDashboard } from "store/actions";
import BarChart from "components/Chart/BarChart";
import LineChart from "components/Chart/LineChart";
import BackBtn from "utils/backBtn";

const ActivityVirtualization = () => {
  const router = useHistory();

  const [selectedTownship, setSelectedTownship] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const { status, dashboard, township, auth } = useSelector((state) => state);
  const { activityDashboard } = dashboard;
  const {
    hes,
    hes_with_month,
    take_medicines,
    cts,
    cts_with_month,
    svs,
    communities,
  } = activityDashboard;
  const { role } = auth.user;

  const dispatch = useDispatch();

  useEffect(() => {
    const query = queryString.parse(router.location.search);
    dispatch(getActivityDashboard(query));
  }, [dispatch, router.location.search]);

  useEffect(() => {
    if (role !== undefined && !isVolunteerSupervisor(role)) {
      dispatch(getTownships());
    }
  }, [dispatch, role]);

  const townships = township.townships.map((element) => ({
    value: element.id,
    label: element.name,
  }));

  const handleFilter = () => {
    const query = queryString.parse(router.location.search);

    if (selectedTownship) {
      query.township_id = selectedTownship.value;
    } else {
      delete query["township_id"];
    }

    if (startDate !== "") {
      query.start_date = startDate;
    } else {
      delete query["start_date"];
    }

    if (endDate !== "") {
      query.end_date = endDate;
    } else {
      delete query["end_date"];
    }

    router.push(`${router.location.pathname}?${queryString.stringify(query)}`);
  };

  const hesBar = {
    labels: hes?.map((data) => data.township_name),
    datasets: [
      {
        label: "Session",
        data: hes?.map((data) => data.match),
        backgroundColor: "rgba(53, 162, 235)",
      },
      {
        label: "Attendent",
        data: hes?.map((data) => data.people),
        backgroundColor: "rgba(255, 99, 132)",
      },
    ],
  };

  const hesBarTotal = hes?.reduce((acc, curr) => acc + curr.match, 0);

  const hesWithMonthBar = {
    labels: hes_with_month?.map((data) => data.month),
    datasets: [
      {
        type: "line",
        label: "Session",
        data: hes_with_month?.map((data) => data.match),
        borderColor: "rgba(53, 162, 235)",
        fill: false,
        tension: 0.1,
      },
      {
        type: "bar",
        label: "Attendent",
        data: hes_with_month?.map((data) => data.people),
        backgroundColor: "rgba(255, 99, 132, 0.5)",
        fill: false,
        tension: 0.1,
      },
    ],
  };

  const hesBarWithMonthTotal = hes_with_month?.reduce(
    (acc, curr) => acc + curr.match,
    0
  );

  const takemedicineBar = {
    labels: take_medicines?.map((data) => data.township_name),
    datasets: [
      {
        label: "Number of Taking Medicine",
        data: take_medicines?.map((data) => data.qty),
        backgroundColor: "rgba(255, 99, 132)",
      },
    ],
  };

  const takemedicineBarTotal = take_medicines?.reduce(
    (acc, curr) => acc + curr.qty,
    0
  );

  const ctsBar = {
    labels: cts?.cts_result?.map((data) => data.title),
    datasets: [
      {
        label: "တီဘီရောဂါတိုက်ဖျက်ရေးဌာန",
        data: cts?.cts_result?.map((data) => data.sub_1),
        backgroundColor: "rgba(53, 162, 235)",
      },
      {
        label: "မိတ်ဖက် ပြင်ပ/NGO ဆေးခန်း",
        data: cts?.cts_result?.map((data) => data.sub_2),
        backgroundColor: "rgba(255, 99, 132)",
      },
      {
        label: "ရွေ့လျားတီဘီရှာဖွေရေးဆေးခန်း",
        data: cts?.cts_result?.map((data) => data.sub_3),
        backgroundColor: "rgb(75, 192, 192)",
      },
    ],
  };

  const ctsBarWithMonth = {
    labels: cts_with_month?.cts_result_with_month?.map((data) => data.month),
    datasets: [
      {
        type: "bar",
        label: "တီဘီရောဂါတိုက်ဖျက်ရေးဌာန",
        data: cts_with_month?.cts_result_with_month?.map(
          (data) => data._1_with_month
        ),
        backgroundColor: "rgba(53, 162, 235, 0.5)",
        fill: false,
        tension: 0.1,
      },
      {
        type: "bar",
        label: "မိတ်ဖက် ပြင်ပ/NGO ဆေးခန်း",
        data: cts_with_month?.cts_result_with_month?.map(
          (data) => data._2_with_month
        ),
        backgroundColor: "rgba(255, 99, 132, 0.5)",
        fill: false,
        tension: 0.1,
      },
      {
        type: "bar",
        label: "ရွေ့လျားတီဘီရှာဖွေရေးဆေးခန်း",
        data: cts_with_month?.cts_result_with_month?.map(
          (data) => data._3_with_month
        ),
        backgroundColor: "rgb(75, 192, 192, 0.5)",
        fill: false,
        tension: 0.1,
      },
      {
        type: "line",
        label: "Sessions",
        data: cts_with_month?.cts_result_with_month?.map(
          (data) => data.per_month_total
        ),
        fill: false,
        borderColor: "rgb(152, 99, 102)",
        tension: 0.1,
      },
    ],
  };

  const svsLineChart = {
    labels: svs?.sv_result?.map((data) => data.month),
    datasets: [
      {
        label: "Number of Supervision Visits",
        data: svs?.sv_result?.map((data) => data.quantity),
        fill: false,
        borderColor: "rgb(75, 192, 192)",
        tension: 0.1,
      },
    ],
  };

  const communityBar = {
    labels: communities?.map((data) => data.township_name),
    datasets: [
      {
        label: "တီဘီရောဂါတိုက်ဖျက်ရေးဌာန",
        data: communities?.map((data) => data._1),
        backgroundColor: "rgba(53, 162, 235)",
      },
      {
        label: "မိတ်ဖက် ပြင်ပ/NGO ဆေးခန်း",
        data: communities?.map((data) => data._2),
        backgroundColor: "rgba(255, 99, 132)",
      },
      {
        label: "ရွေ့လျားတီဘီရှာဖွေရေးဆေးခန်း",
        data: communities?.map((data) => data._3),
        backgroundColor: "rgb(75, 192, 192)",
      },
    ],
  };

  const communityBarTotal = communities?.reduce(
    (acc, curr) => acc + curr._1 + curr._2 + curr._3,
    0
  );

  if (status.loading) {
    return <FullScreenLoading />;
  }

  return (
    <Container className="mt-3" fluid>
      <BackBtn />
      <Card>
        <CardHeader>
          <CardTitle tag="h3">Activity Virtualization</CardTitle>
          <Row>
            {!isVolunteerSupervisor(role) && (
              <Col sm={3}>
                <Select
                  className="mt-4"
                  options={townships}
                  value={selectedTownship}
                  onChange={(value) => setSelectedTownship(value)}
                  placeholder="Filter by township..."
                  isSearchable={true}
                  isClearable={true}
                />
              </Col>
            )}
            <Col sm={3}>
              <label htmlFor="start_date">Start Date</label>
              <input
                id="start_date"
                className="form-control"
                type="date"
                onChange={(event) => setStartDate(event.target.value)}
                value={startDate}
              />
            </Col>
            <Col sm={3}>
              <label htmlFor="end_date">End Date</label>
              <input
                id="end_date"
                className="form-control"
                type="date"
                min={startDate}
                max={currentDate()}
                onChange={(event) => setEndDate(event.target.value)}
                disabled={startDate === ""}
                value={endDate}
              />
            </Col>
            <Col sm={3}>
              <Button
                size="sm"
                color="success"
                onClick={handleFilter}
                className="mt-4"
              >
                <i className="fa fa-search" />
              </Button>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <BarChart
            title="HE"
            total={hesBarTotal}
            className="HE"
            data={hesBar}
          />
          <BarChart
            title="HE With Month"
            total={hesBarWithMonthTotal}
            className="HE-with-month"
            data={hesWithMonthBar}
          />
          <BarChart
            title="Taking Medicine by Township"
            total={takemedicineBarTotal}
            className="take-medicine"
            data={takemedicineBar}
          />
          <BarChart
            title="TBCI Activity"
            total={cts?.cts_total}
            className="contact-tracy"
            data={ctsBar}
          />
          <BarChart
            title="TBCI Activity With Month"
            total={cts_with_month?.cts_total_with_month}
            className="contact-tracy-with-month"
            data={ctsBarWithMonth}
          />
          <LineChart
            title="Supervision"
            total={svs?.sv_total}
            className="svs"
            data={svsLineChart}
          />
          <BarChart
            title="Community"
            total={communityBarTotal}
            className="community"
            data={communityBar}
          />
        </CardBody>
      </Card>
    </Container>
  );
};

export default ActivityVirtualization;
