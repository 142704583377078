import React, { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button, Form } from 'reactstrap'
import CustomSelect from 'components/Inputs/CustomSelect'
import CustomModal from 'components/Modal/CustomModal'
import { connect } from 'react-redux'
import { createSupervisionVisit } from 'store/actions'
import { years } from 'variables/options'
import CustomNumberInput from 'components/Inputs/CustomNumberInput'
import { allMonths } from 'variables/options'
import { isVolunteerSupervisor } from 'utils/middleware'

const SupervisionVisit = ({
  isOpen,
  toggle,
  status,
  auth,
  township,
  createSupervisionVisit,
}) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm()
  const { role } = auth.user
  const [loading, setLoading] = useState(false)
  const [month, setMonth] = useState(null)
  const [year, setYear] = useState(null)
  const [selectedTownship, setSelectedTownship] = useState(null)

  const townships = township.townships.map((element) => ({
    value: element.id,
    label: element.name,
  }))

  const onSubmit = async (data) => {
    setLoading(true)
    await createSupervisionVisit({
      month: data.month.value,
      year: data.year.value,
      quantity: data.quantity,
      township_id:
        role !== undefined && !isVolunteerSupervisor(role)
          ? data.township?.value
          : auth.user?.township?.id,
    })
    setLoading(false)
  }

  const formReset = useCallback(() => {
    setMonth(null)
    setYear(null)
    setSelectedTownship(null)
    reset()
  }, [reset, setMonth, setYear, setSelectedTownship])

  useEffect(() => {
    if (status.success) {
      formReset()
    }

    return () => formReset()
  }, [status.success, formReset])

  return (
    <CustomModal
      isOpen={isOpen}
      title="Create Supervision Visit"
      onClick={() => {
        toggle(false)
        formReset()
      }}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-body">
          <CustomSelect
            id="month"
            label="Select Month"
            rules={{ required: 'Month is required!' }}
            control={control}
            options={allMonths}
            value={month}
            setData={setMonth}
            placeholder="Select month"
          />
          <CustomSelect
            id="year"
            label="Select Year"
            rules={{ required: 'Year is required!' }}
            control={control}
            options={years}
            value={year}
            setData={setYear}
            placeholder="Select year"
          />
          <CustomNumberInput
            id="quantity"
            label="Number of Supervision Visit"
            register={{
              ...register('quantity', {
                required: 'Number of Supervision Visit is required!',
              }),
            }}
            placeholder="Enter Number of Supervision Visit"
            errors={errors}
          />
          {role !== undefined && !isVolunteerSupervisor(role) ? (
            <CustomSelect
              id="township"
              label="Select Township"
              rules={{ required: 'Township is required!' }}
              control={control}
              options={townships}
              value={selectedTownship}
              setData={setSelectedTownship}
              placeholder="Select township"
            />
          ) : (
            <p>{auth.user?.township?.name}</p>
          )}
        </div>
        <div className="modal-footer">
          <Button color="primary" size="sm" type="submit" disabled={loading}>
            {loading ? 'Loading' : 'Save'}
          </Button>
        </div>
      </Form>
    </CustomModal>
  )
}

const mapStateToProps = (store) => ({
  status: store.status,
  auth: store.auth,
  township: store.township,
})

export default connect(mapStateToProps, { createSupervisionVisit })(
  SupervisionVisit,
)
