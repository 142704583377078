import { call, setAccessToken } from '../../services/api'
import { serverErrorMessage } from '../../utils/messages'
import {
  DELETE_CHANGE_VOLUNTEER,
  SET_LOADING,
  SET_CHANGE_VOLUNTEER,
  SET_SUCCESS,
  SHOW_CHANGE_VOLUNTEERS,
} from '../type'
import { NotificationManager } from 'react-notifications'

export const getChangeVolunteers = (patientId) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING })
    try {
      const response = await call('get', `change-volunteers/${patientId}`)
      const result = response.data

      dispatch({
        type: SHOW_CHANGE_VOLUNTEERS,
        payload: result,
      })
    } catch (error) {
      const { status, data } = error.response

      if (status === 401) {
        setAccessToken(null)
        NotificationManager.error(data.data.message)
      } else {
        NotificationManager.error(serverErrorMessage)
      }
    }
    dispatch({ type: SET_LOADING })
  }
}

export const getChangeVolunteer = (id) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING })
    dispatch({ type: SET_SUCCESS, payload: false })
    try {
      const response = await call('get', `change-volunteer/${id}`)
      const result = response.data

      dispatch({
        type: SET_CHANGE_VOLUNTEER,
        payload: result,
      })
    } catch (error) {
      const { status, data } = error.response

      if (status === 401) {
        setAccessToken(null)
        NotificationManager.error(data.data.message)
      } else {
        NotificationManager.error(serverErrorMessage)
      }
    }
    dispatch({ type: SET_LOADING })
  }
}

export const deleteChangeVolunteer = (id) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING })
    try {
      await call('delete', `change-volunteers/${id}`)

      dispatch({ type: DELETE_CHANGE_VOLUNTEER, payload: id })
      NotificationManager.success(
        'Change Volunteer has been deleted successfully!',
      )
    } catch (error) {
      const { status, data } = error.response

      if (status === 401) {
        setAccessToken(null)
        NotificationManager.error(data.data.message)
      } else {
        NotificationManager.error(serverErrorMessage)
      }
    }
    dispatch({ type: SET_LOADING })
  }
}
