import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import {
  Button,
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  Input,
} from 'reactstrap'
import queryString from 'query-string'
import { useHistory } from 'react-router-dom'
import AccountCreate from './AccountCreate'
import CustomPagination from 'components/Pagination/Pagination'
import CustomTable from 'components/Table/Table'
import { roles } from 'variables/options'
import { connect } from 'react-redux'
import {
  getAccounts,
  deleteAccount,
  getAccount,
  getTownships,
} from 'store/actions'
import FullScreenLoading from 'components/FullScreenLoading/FullScreenLoading'
import AccountUpdate from './AccountUpdate'
import { paginationLimit } from 'variables/limits'
import CustomAlert from 'components/Alert/CustomAlert'
import BackBtn from 'utils/backBtn'
import AccountEmailUpdate from './AccountEmailUpdate'

const AccountList = ({
  status,
  account,
  getAccounts,
  deleteAccount,
  getAccount,
  getTownships,
}) => {
  const router = useHistory()
  const [alert, setAlert] = useState(null)

  const [isCreateOpen, setIsCreateOpen] = useState(false)
  const [isUpdateOpen, setIsUpdateOpen] = useState(false)
  const [isEmailUpdateOpen, setIsEmailUpdateOpen] = useState(false)

  const [email, setEmail] = useState('')
  const [role, setRole] = useState(null)

  useEffect(() => {
    const query = queryString.parse(router.location.search)
    if (!('page' in query)) {
      query.page = 1
    }
    getAccounts(query)
  }, [getAccounts, router.location.search])

  useEffect(() => {
    getTownships()
  }, [getTownships])

  const handleFilter = () => {
    const query = queryString.parse(router.location.search)
    query.page = 1
    if (email !== '') {
      query.email = email
    } else {
      delete query['email']
    }
    if (role) {
      query.role = role.value
    } else {
      delete query['role']
    }

    router.push(`${router.location.pathname}?${queryString.stringify(query)}`)
    setEmail('')
    setRole(null)
  }

  const handleDelete = (id) => {
    setAlert(
      <CustomAlert
        onConfirm={() => {
          deleteAccount(id)
          setAlert(null)
        }}
        onCancel={() => setAlert(null)}
      />,
    )
  }

  if (status.loading) {
    return <FullScreenLoading />
  }

  return (
    <>
      {alert}
      <Container className="mt-3" fluid>
        <BackBtn />
        <Card>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-0">Accounts</h3>
              </Col>
              <Col className="text-right" xs="6">
                <Button
                  size="sm"
                  color="success"
                  onClick={() => setIsCreateOpen(true)}
                >
                  New
                </Button>
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <Input
                  bsSize="sm"
                  type="text"
                  placeholder="Enter Email"
                  className="mt-3"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                />
              </Col>
              <Col md={3}>
                <Select
                  className="mt-3"
                  options={roles}
                  value={role}
                  onChange={(value) => setRole(value)}
                  placeholder="Filter by role..."
                  isSearchable={true}
                  isClearable={true}
                />
              </Col>
              <Col md={3}>
                <Button
                  size="sm"
                  color="success"
                  className="mt-3"
                  onClick={handleFilter}
                >
                  <i className="fa fa-search" />
                </Button>
              </Col>
              <Col md={3}>
                <Button
                  size="sm"
                  color="success"
                  className="mt-3"
                  onClick={() => router.push(router.location.pathname)}
                >
                  All
                </Button>
              </Col>
            </Row>
          </CardHeader>

          <CustomTable
            header={
              <tr>
                <th scope="col">ID</th>
                <th scope="col">Name</th>
                <th scope="col">Email</th>
                <th scope="col">Plain Password</th>
                <th scope="col">Role</th>
                <th scope="col">Township</th>
                <th scope="col">Action</th>
              </tr>
            }
            body={account.accounts.map((account, index) => (
              <tr key={account.id}>
                <td>{index + 1}</td>
                <td>{account.name}</td>
                <td>{account.email}</td>
                <td>{account.plain_password}</td>
                <td>{account.role}</td>
                <td>{account.township?.name}</td>
                <td>
                  <Button
                    size="sm"
                    color="primary"
                    onClick={async () => {
                      await getAccount(account.id)
                      setIsUpdateOpen(true)
                    }}
                  >
                    Update
                  </Button>
                  <Button
                    size="sm"
                    color="warning"
                    onClick={async () => {
                      await getAccount(account.id)
                      setIsEmailUpdateOpen(true)
                    }}
                  >
                    Email Update
                  </Button>
                  <Button
                    size="sm"
                    color="danger"
                    onClick={() => handleDelete(account.id)}
                  >
                    Delete
                  </Button>
                </td>
              </tr>
            ))}
          />

          {account.total > paginationLimit && (
            <CustomPagination pageCount={account.total / paginationLimit} />
          )}
        </Card>
      </Container>

      <AccountCreate isOpen={isCreateOpen} toggle={setIsCreateOpen} />
      <AccountUpdate isOpen={isUpdateOpen} toggle={setIsUpdateOpen} />
      <AccountEmailUpdate
        isOpen={isEmailUpdateOpen}
        toggle={setIsEmailUpdateOpen}
      />
    </>
  )
}

const mapStateToProps = (store) => ({
  status: store.status,
  account: store.account,
})

export default connect(mapStateToProps, {
  getAccounts,
  deleteAccount,
  getAccount,
  getTownships,
})(AccountList)
