import {
  CREATE_FU_SPUTUM_EXAMINITION,
  DELETE_FU_SPUTUM_EXAMINITION,
  SET_FU_SPUTUM_EXAMINITION,
  SHOW_FU_SPUTUM_EXAMINITIONS,
  UPDATE_FU_SPUTUM_EXAMINITION,
} from '../type'

const initialState = {
  fUSputumExaminitions: [],
  fUSputumExaminition: {},
}

const fUSputumExaminition = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_FU_SPUTUM_EXAMINITIONS:
      return {
        ...state,
        fUSputumExaminitions: action.payload,
      }
    case CREATE_FU_SPUTUM_EXAMINITION:
      return {
        ...state,
        fUSputumExaminitions: [action.payload, ...state.fUSputumExaminitions],
      }
    case DELETE_FU_SPUTUM_EXAMINITION:
      return {
        ...state,
        fUSputumExaminitions: state.fUSputumExaminitions.filter(
          (fUSputumExaminition) => fUSputumExaminition.id !== action.payload,
        ),
      }
    case SET_FU_SPUTUM_EXAMINITION:
      return {
        ...state,
        fUSputumExaminition: action.payload,
      }
    case UPDATE_FU_SPUTUM_EXAMINITION:
      let index = state.fUSputumExaminitions.findIndex(
        (fUSputumExaminition) => fUSputumExaminition.id === action.payload.id,
      )
      state.fUSputumExaminitions[index] = action.payload
      if (state.fUSputumExaminition.id === action.payload.id) {
        state.fUSputumExaminition = action.payload
      }
      return {
        ...state,
      }
    default:
      return state
  }
}

export default fUSputumExaminition
