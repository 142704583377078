import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import {
  Button,
  Form,
  Row,
  Col,
  CardBody,
  CardFooter,
  CardTitle,
} from 'reactstrap'
import CustomSelect from 'components/Inputs/CustomSelect'
import { dones } from 'variables/options'
import CustomDateInput from 'components/Inputs/CustomDateInput'
import { currentDate } from 'utils/currentDate'
import CustomInput from 'components/Inputs/CustomInput'
import { connect } from 'react-redux'
import { updatePatient } from 'store/actions'
import { useParams } from 'react-router'
import { useQuery } from 'hooks/useQuery'

const OtherInvestigation = ({ patient, updatePatient }) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm()

  const { id } = useParams()

  const fromTBPatient = useQuery("from_TB_patient");

  const [loading, setLoading] = useState(false)
  const [
    otherInvestigationDoneNotDone,
    setOtherInvestigationDoneNotDone,
  ] = useState(null)

  useEffect(() => {
    const {
      other_investigation_done_or_not_done,
      other_investigation_date,
      investigation_name,
      investigation_result,
    } = patient.patient

    setValue(
      'other_investigation_done_not_done',
      other_investigation_done_or_not_done === null
        ? null
        : {
            value: other_investigation_done_or_not_done,
            label: other_investigation_done_or_not_done,
          },
    )
    setOtherInvestigationDoneNotDone(
      other_investigation_done_or_not_done === null
        ? null
        : {
            value: other_investigation_done_or_not_done,
            label: other_investigation_done_or_not_done,
          },
    )
    setValue('other_investigation_date', other_investigation_date)
    setValue('investigation_name', investigation_name)
    setValue('investigation_result', investigation_result)
  }, [setValue, patient.patient])

  const onSubmit = async (data) => {
    setLoading(true)
    await updatePatient(
      id,
      {
        other_investigation_done_or_not_done:
          data.other_investigation_done_not_done?.value,
        other_investigation_date:
          data.other_investigation_done_not_done?.value === 'Not Done'
            ? null
            : data.other_investigation_date,
        investigation_name:
          data.other_investigation_done_not_done?.value === 'Not Done'
            ? null
            : data.investigation_name,
        investigation_result:
          data.other_investigation_done_not_done?.value === 'Not Done'
            ? null
            : data.investigation_result,
      },
      'other-investigation',
      'Other Investigation',
    )
    setLoading(false)
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <CardBody>
        <CardTitle>
          <h3>Other Investigation</h3>
        </CardTitle>
        <Row>
          <Col md={5}>
            <CustomSelect
              id="other_investigation_done_not_done"
              label="Other Investigation Done or Not Done"
              control={control}
              rules={{
                required: 'Other Investigation Done or Not Done is required!',
              }}
              options={dones}
              value={otherInvestigationDoneNotDone}
              setData={setOtherInvestigationDoneNotDone}
              placeholder="Other Investigation Done or Not Done"
              isRequired={true}
            />
          </Col>
          <Col md={5}>
            <CustomDateInput
              id="other_investigation_date"
              label="Other Investigation Date"
              register={{
                ...register('other_investigation_date', {
                  required:
                    otherInvestigationDoneNotDone?.value === 'Done'
                      ? 'Other Investigation Date is required!'
                      : false,
                }),
              }}
              placeholder="Other Investigation Date"
              errors={errors}
              max={currentDate()}
              disabled={
                otherInvestigationDoneNotDone === null ||
                otherInvestigationDoneNotDone?.value !== 'Done'
              }
              isRequired={otherInvestigationDoneNotDone?.value === 'Done'}
            />
          </Col>
        </Row>
        <Row>
          <Col md={5}>
            <CustomInput
              id="investigation_name"
              label="Investigation Name"
              register={{
                ...register('investigation_name', {
                  required:
                    otherInvestigationDoneNotDone?.value === 'Done'
                      ? 'Investigation Name is required!'
                      : false,
                }),
              }}
              placeholder="Enter Investigation Name"
              errors={errors}
              disabled={
                otherInvestigationDoneNotDone === null ||
                otherInvestigationDoneNotDone?.value !== 'Done'
              }
              isRequired={otherInvestigationDoneNotDone?.value === 'Done'}
            />
          </Col>
          <Col md={5}>
            <CustomInput
              id="investigation_result"
              label="Investigation Result"
              register={{
                ...register('investigation_result', {
                  required:
                    otherInvestigationDoneNotDone?.value === 'Done'
                      ? 'Investigation Result is required!'
                      : false,
                }),
              }}
              placeholder="Enter Investigation Result"
              errors={errors}
              disabled={
                otherInvestigationDoneNotDone === null ||
                otherInvestigationDoneNotDone?.value !== 'Done'
              }
              isRequired={otherInvestigationDoneNotDone?.value === 'Done'}
            />
          </Col>
        </Row>
      </CardBody>
      <CardFooter className="text-right">
      {fromTBPatient && (
          <Button color="primary" size="sm" disabled={loading} type="submit">
            {loading ? "Loading" : "Update"}
          </Button>
        )}
      </CardFooter>
    </Form>
  )
}

const mapStateToProps = (store) => ({
  patient: store.patient,
})

export default connect(mapStateToProps, { updatePatient })(OtherInvestigation)
