import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  CardBody,
} from "reactstrap";
import CustomPagination from "components/Pagination/Pagination";
import queryString from "query-string";
import { useParams, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { getDOT, getPatient } from "store/actions";
import FullScreenLoading from "components/FullScreenLoading/FullScreenLoading";
import styles from "./AllDOTList.module.css";
import { currentDate } from "utils/currentDate";
import BackBtn from "utils/backBtn";
import { NotificationManager } from "react-notifications";
import { excelExport } from "utils/excelExport";
import { call } from "services/api";
import { DOTWorking } from "utils/DOTWorking";
import { getAllDOTs } from "store/actions";
import AllDOTDetail from "./AllDOTDetail";
import { host } from "services/api";

const AllDOTList = ({
  status,
  patient,
  DOT,
  getDOT,
  getAllDOTs,
  getPatient,
}) => {
  const { id } = useParams();
  const router = useHistory();

  const [exportLoading, setExportLoading] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const handleFilter = () => {
    const query = queryString.parse(router.location.search);
    query.page = 1;
    if (startDate !== "") {
      query.start_date = startDate;
    } else {
      delete query["start_date"];
    }

    if (endDate !== "") {
      query.end_date = endDate;
    } else {
      delete query["end_date"];
    }

    router.push(`${router.location.pathname}?${queryString.stringify(query)}`);
    setStartDate("");
    setEndDate("");
  };

  useEffect(() => {
    const query = queryString.parse(router.location.search);
    if (!("page" in query)) {
      query.page = 1;
    }
    getAllDOTs(id, query);
  }, [getAllDOTs, id, router.location.search]);

  useEffect(() => {
    getPatient(id);
  }, [getPatient, id]);

  const handleExport = async () => {
    setExportLoading(true);
    try {
      const query = queryString.parse(router.location.search);
      const response = await call(
        "get",
        `export-all-DOTs/${id}?${new URLSearchParams(query).toString()}`
      );
      const result = response.data.map((data, index) => ({
        ID: index + 1,
        Patient_Name: data.patient.name,
        Volunteer_Name: `${data.change_volunteer?.volunteer?.name}(${data.change_volunteer?.volunteer?.code})`,
        Date: data.date,
        Work: DOTWorking[data.work],
        Work_Status: data.work_status ? "အောင်မြင်ပါသည်" : "မအောင်မြင်ပါ",
        Minor_Side_Effects: data.minor_side_effects,
        Major_Side_Effects: data.major_side_effects,
        Actions: data.actions,
        Video_Link: data.work === 3 ? `${host}/DOT-video/${data.uuid}` : null,
        VOT_Video_Link: data.work === 2 ? data.video_url : null,
        Patient_Sign: data.work === 1 ? data.sign_url : null,
        Remark: data.remark,
        Late_Remark: data.late_remark,
        Type: data.change_volunteer?.type,
      }));

      if (response.status === "success") {
        excelExport(result, "All-VOT");
      }
    } catch (_) {
      NotificationManager.error("Please try again!");
    }
    setExportLoading(false);
  };

  if (status.loading) {
    return <FullScreenLoading />;
  }

  return (
    <>
      <Container className="mt-3" fluid>
        <BackBtn />
        <Card>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-3">
                  ({patient.patient?.name}) VOT Calendar
                </h3>
              </Col>
            </Row>

            <Row>
              <Col sm={4}>
                <label htmlFor="start_date">Start Date</label>
                <input
                  id="start_date"
                  className="form-control"
                  type="date"
                  min={
                    patient.patient?.DOT_start_date === null
                      ? ""
                      : patient.patient?.DOT_start_date
                  }
                  max={currentDate()}
                  onChange={(event) => setStartDate(event.target.value)}
                />
              </Col>
              <Col sm={4}>
                <label htmlFor="end_date">End Date</label>
                <input
                  id="end_date"
                  className="form-control"
                  type="date"
                  min={startDate}
                  max={currentDate()}
                  onChange={(event) => setEndDate(event.target.value)}
                  disabled={startDate === ""}
                />
              </Col>
              <Col sm={4}>
                <Button
                  size="sm"
                  color="success"
                  className="mt-4"
                  onClick={handleFilter}
                >
                  <i className="fa fa-search" />
                </Button>
                <Button
                  size="sm"
                  color="info"
                  className="mt-4"
                  onClick={handleExport}
                  disabled={exportLoading}
                >
                  {exportLoading ? "Loading" : "Export"}
                </Button>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Row>
              {DOT.DOTs.map((DOT, index) => {
                return (
                  <Col
                    key={index}
                    xs={3}
                    md={3}
                    lg={2}
                    className={`text-center ${styles.box}`}
                    onClick={async () => {
                      await getDOT(DOT.id);
                      setIsOpen(true);
                    }}
                  >
                    {DOT.date}
                  </Col>
                );
              })}
            </Row>
          </CardBody>

          {DOT.total > 28 && <CustomPagination pageCount={DOT.total / 28} />}
        </Card>
      </Container>

      <AllDOTDetail isOpen={isOpen} toggle={setIsOpen} />
    </>
  );
};

const mapStateToProps = (store) => ({
  status: store.status,
  patient: store.patient,
  DOT: store.DOT,
});

export default connect(mapStateToProps, {
  getDOT,
  getAllDOTs,
  getPatient,
})(AllDOTList);
