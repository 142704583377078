import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import FullScreenLoading from "components/FullScreenLoading/FullScreenLoading";
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  CardHeader,
  CardTitle,
  Button,
} from "reactstrap";
import { getTownships } from "store/actions";
import { isVolunteerSupervisor } from "utils/middleware";
import queryString from "query-string";
import { useHistory } from "react-router";
import { currentDate } from "utils/currentDate";
import { getPatientDashboard } from "store/actions";
import LineChart from "components/Chart/LineChart";
import BarChart from "components/Chart/BarChart";
import PieChart from "components/Chart/PieChart";
import BackBtn from "utils/backBtn";

const PatientVirtualization = () => {
  const router = useHistory();

  const [selectedTownship, setSelectedTownship] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const { status, dashboard, township, auth } = useSelector((state) => state);
  const { patientDashboard } = dashboard;
  const {
    total_patients,
    case_founded_activities,
    defined_TB_type,
    treatment_regimen,
    TB_patients_outcome,
    type_of_TB_patient,
  } = patientDashboard;
  const { role } = auth.user;

  const dispatch = useDispatch();

  useEffect(() => {
    const query = queryString.parse(router.location.search);
    dispatch(getPatientDashboard(query));
  }, [dispatch, router.location.search]);

  useEffect(() => {
    if (role !== undefined && !isVolunteerSupervisor(role)) {
      dispatch(getTownships());
    }
  }, [dispatch, role]);

  const townships = township.townships.map((element) => ({
    value: element.id,
    label: element.name,
  }));

  const handleFilter = () => {
    const query = queryString.parse(router.location.search);

    if (selectedTownship) {
      query.township_id = selectedTownship.value;
    } else {
      delete query["township_id"];
    }

    if (startDate !== "") {
      query.start_date = startDate;
    } else {
      delete query["start_date"];
    }

    if (endDate !== "") {
      query.end_date = endDate;
    } else {
      delete query["end_date"];
    }

    router.push(`${router.location.pathname}?${queryString.stringify(query)}`);
  };

  const totalPatients = {
    labels: total_patients?.map((data) => data.month),
    datasets: [
      {
        label: "Patients",
        data: total_patients?.map((data) => data.total),
        fill: false,
        borderColor: "rgb(75, 192, 192)",
        tension: 0.1,
      },
      {
        label: "TB Patients",
        data: total_patients?.map((data) => data.TB_total),
        fill: false,
        borderColor: "rgb(255, 99, 132)",
        tension: 0.1,
      },
    ],
  };

  const totalPatientsTotal = total_patients?.reduce(
    (acc, curr) => acc + curr.total,
    0
  );
  const totalTBPatientsTotal = total_patients?.reduce(
    (acc, curr) => acc + curr.TB_total,
    0
  );

  let definedTBTypeDataSets = [];
  if (defined_TB_type?.Pulmonary_BC > 0) {
    definedTBTypeDataSets = [
      ...definedTBTypeDataSets,
      {
        data: defined_TB_type?.Pulmonary_BC,
        color: "rgb(255, 99, 132)",
      },
    ];
  }
  if (defined_TB_type?.Extra_Pulmonary_BC > 0) {
    definedTBTypeDataSets = [
      ...definedTBTypeDataSets,
      {
        data: defined_TB_type?.Extra_Pulmonary_BC,
        color: "rgb(54, 162, 235)",
      },
    ];
  }
  if (defined_TB_type?.Pulmonary_CD > 0) {
    definedTBTypeDataSets = [
      ...definedTBTypeDataSets,
      {
        data: defined_TB_type?.Pulmonary_CD,
        color: "rgb(255, 205, 86)",
      },
    ];
  }
  if (defined_TB_type?.Extra_Pulmonary_CD > 0) {
    definedTBTypeDataSets = [
      ...definedTBTypeDataSets,
      {
        data: defined_TB_type?.Extra_Pulmonary_CD,
        color: "rgb(75, 192, 192)",
      },
    ];
  }

  const definedTBTypePie = {
    labels: [
      "Pulmonary_BC",
      "Extra_Pulmonary_BC",
      "Pulmonary_CD",
      "Extra_Pulmonary_CD",
    ],
    datasets: [
      {
        label: "Defined TB Type",
        data: definedTBTypeDataSets.map((data) => data.data),
        backgroundColor: definedTBTypeDataSets.map((data) => data.color),
        hoverOffset: 4,
      },
    ],
  };

  const definedTBTypeTotal =
    defined_TB_type?.Pulmonary_BC +
    defined_TB_type?.Extra_Pulmonary_BC +
    defined_TB_type?.Pulmonary_CD +
    defined_TB_type?.Extra_Pulmonary_CD;

  let treatmentRegimenDataSet = [];
  if (treatment_regimen?.Initial_Regimen_IR > 0) {
    treatmentRegimenDataSet = [
      ...treatmentRegimenDataSet,
      {
        data: treatment_regimen?.Initial_Regimen_IR,
        color: "rgb(255, 99, 132)",
      },
    ];
  }
  if (treatment_regimen?.Retreatment_Regimen_RR > 0) {
    treatmentRegimenDataSet = [
      ...treatmentRegimenDataSet,
      {
        data: treatment_regimen?.Retreatment_Regimen_RR,
        color: "rgb(54, 162, 235)",
      },
    ];
  }
  if (treatment_regimen?.Childhood_Regimen_CR > 0) {
    treatmentRegimenDataSet = [
      ...treatmentRegimenDataSet,
      {
        data: treatment_regimen?.Childhood_Regimen_CR,
        color: "rgb(255, 205, 86)",
      },
    ];
  }
  if (treatment_regimen?.MDRTB_Regimen_MDR > 0) {
    treatmentRegimenDataSet = [
      ...treatmentRegimenDataSet,
      {
        data: treatment_regimen?.MDRTB_Regimen_MDR,
        color: "rgb(75, 192, 192)",
      },
    ];
  }
  if (treatment_regimen?.Modified_Regimen > 0) {
    treatmentRegimenDataSet = [
      ...treatmentRegimenDataSet,
      {
        data: treatment_regimen?.Modified_Regimen,
        color: "rgba(90, 12, 235)",
      },
    ];
  }

  const treatmentRegimenPie = {
    labels: [
      "Initial Regimen (IR)",
      "Retreatment Regimen (RR)",
      "Childhood Regimen (CR)",
      "MDRTB Regimen (MDR)",
      "Modified Regimen",
    ],
    datasets: [
      {
        label: "Treatment Regimen",
        data: treatmentRegimenDataSet.map((data) => data.data),
        backgroundColor: treatmentRegimenDataSet.map((data) => data.color),
        hoverOffset: 4,
      },
    ],
  };

  const treatmentRegimenPieTotal =
    treatment_regimen?.Initial_Regimen_IR +
    treatment_regimen?.Retreatment_Regimen_RR +
    treatment_regimen?.Childhood_Regimen_CR +
    treatment_regimen?.MDRTB_Regimen_MDR +
    treatment_regimen?.Modified_Regimen;

  let TBPatientsOutcomeDataSet = [];
  if (TB_patients_outcome?.Cured > 0) {
    TBPatientsOutcomeDataSet = [
      ...TBPatientsOutcomeDataSet,
      {
        data: TB_patients_outcome?.Cured,
        color: "rgb(255, 99, 132)",
      },
    ];
  }
  if (TB_patients_outcome?.Treatment_Complete > 0) {
    TBPatientsOutcomeDataSet = [
      ...TBPatientsOutcomeDataSet,
      {
        data: TB_patients_outcome?.Treatment_Complete,
        color: "rgb(54, 162, 235)",
      },
    ];
  }
  if (TB_patients_outcome?.Treatment_Failure > 0) {
    TBPatientsOutcomeDataSet = [
      ...TBPatientsOutcomeDataSet,
      {
        data: TB_patients_outcome?.Treatment_Failure,
        color: "rgb(255, 205, 86)",
      },
    ];
  }
  if (TB_patients_outcome?.Died > 0) {
    TBPatientsOutcomeDataSet = [
      ...TBPatientsOutcomeDataSet,
      {
        data: TB_patients_outcome?.Died,
        color: "rgb(75, 192, 192)",
      },
    ];
  }
  if (TB_patients_outcome?.Lost_to_Follow_up > 0) {
    TBPatientsOutcomeDataSet = [
      ...TBPatientsOutcomeDataSet,
      {
        data: TB_patients_outcome?.Lost_to_Follow_up,
        color: "rgba(53, 160, 25)",
      },
    ];
  }
  if (TB_patients_outcome?.Not_Evaluated > 0) {
    TBPatientsOutcomeDataSet = [
      ...TBPatientsOutcomeDataSet,
      {
        data: TB_patients_outcome?.Not_Evaluated,
        color: "rgb(23, 90, 132)",
      },
    ];
  }
  if (TB_patients_outcome?.Move_To_second_line_Treatment > 0) {
    TBPatientsOutcomeDataSet = [
      ...TBPatientsOutcomeDataSet,
      {
        data: TB_patients_outcome?.Move_To_second_line_Treatment,
        color: "rgb(57, 90, 25)",
      },
    ];
  }
  if (TB_patients_outcome?.Unable_to_continue_VOT > 0) {
    TBPatientsOutcomeDataSet = [
      ...TBPatientsOutcomeDataSet,
      {
        data: TB_patients_outcome?.Unable_to_continue_VOT,
        color: "rgb(55, 25, 60)",
      },
    ];
  }

  const TBPatientsOutcomePie = {
    labels: [
      "Cured",
      "Treatment Complete",
      "Treatment Failure",
      "Died",
      "Lost to Follow up",
      "Not Evaluated",
      "Move To second line Treatment",
      "Unable to continue VOT",
    ],
    datasets: [
      {
        label: "TB Patients Outcome",
        data: TBPatientsOutcomeDataSet.map((data) => data.data),
        backgroundColor: TBPatientsOutcomeDataSet.map((data) => data.color),
        hoverOffset: 4,
      },
    ],
  };

  const TBPatientsOutcomePieTotal =
    TB_patients_outcome?.Cured +
    TB_patients_outcome?.Treatment_Complete +
    TB_patients_outcome?.Treatment_Failure +
    TB_patients_outcome?.Died +
    TB_patients_outcome?.Lost_to_Follow_up +
    TB_patients_outcome?.Not_Evaluated +
    TB_patients_outcome?.Move_To_second_line_Treatment +
    TB_patients_outcome?.Unable_to_continue_VOT;

  let typeOfTBPatientDataSet = [];
  if (type_of_TB_patient?.New > 0) {
    typeOfTBPatientDataSet = [
      ...typeOfTBPatientDataSet,
      {
        data: type_of_TB_patient?.New,
        color: "rgb(255, 99, 132)",
      },
    ];
  }
  if (type_of_TB_patient?.Releapse > 0) {
    typeOfTBPatientDataSet = [
      ...typeOfTBPatientDataSet,
      {
        data: type_of_TB_patient?.Releapse,
        color: "rgb(54, 162, 235)",
      },
    ];
  }
  if (type_of_TB_patient?.Treatment_after_failure > 0) {
    typeOfTBPatientDataSet = [
      ...typeOfTBPatientDataSet,
      {
        data: type_of_TB_patient?.Treatment_after_failure,
        color: "rgb(255, 205, 86)",
      },
    ];
  }
  if (type_of_TB_patient?.Treatment_after_loss_to_follow_up > 0) {
    typeOfTBPatientDataSet = [
      ...typeOfTBPatientDataSet,
      {
        data: type_of_TB_patient?.Treatment_after_loss_to_follow_up,
        color: "rgb(75, 192, 192)",
      },
    ];
  }
  if (type_of_TB_patient?.Other_previously_treated > 0) {
    typeOfTBPatientDataSet = [
      ...typeOfTBPatientDataSet,
      {
        data: type_of_TB_patient?.Other_previously_treated,
        color: "rgb(57, 90, 25)",
      },
    ];
  }
  if (type_of_TB_patient?.Previous_treatment_history_unknown > 0) {
    typeOfTBPatientDataSet = [
      ...typeOfTBPatientDataSet,
      {
        data: type_of_TB_patient?.Previous_treatment_history_unknown,
        color: "rgb(55, 25, 60)",
      },
    ];
  }

  const typeOfTBPatientPie = {
    labels: [
      "New",
      "Releapse",
      "Treatment_after_failure",
      "Treatment_after_loss_to_follow_up",
      "Other_previously_treated",
      "Previous_treatment_history_unknown",
    ],
    datasets: [
      {
        label: "Type of TB Patient",
        data: typeOfTBPatientDataSet.map((data) => data.data),
        backgroundColor: typeOfTBPatientDataSet.map((data) => data.color),
        hoverOffset: 4,
      },
    ],
  };

  const typeOfTBPatientPieTotal =
    type_of_TB_patient?.New +
    type_of_TB_patient?.Releapse +
    type_of_TB_patient?.Treatment_after_failure +
    type_of_TB_patient?.Treatment_after_loss_to_follow_up +
    type_of_TB_patient?.Other_previously_treated +
    type_of_TB_patient?.Previous_treatment_history_unknown;

  const caseFoundedActivities = {
    labels: case_founded_activities?.map((data) => data.case_founded_activity),
    datasets: [
      {
        label: "Male",
        data: case_founded_activities?.map((data) => data.male),
        backgroundColor: "rgba(255, 99, 132)",
      },
      {
        label: "Female",
        data: case_founded_activities?.map((data) => data.female),
        backgroundColor: "rgba(53, 162, 235)",
      },
    ],
  };

  const caseFoundedActivitiesTotal = case_founded_activities?.reduce(
    (acc, curr) => acc + (curr.male + curr.female),
    0
  );

  if (status.loading) {
    return <FullScreenLoading />;
  }

  return (
    <Container className="mt-3" fluid>
      <BackBtn />
      <Card>
        <CardHeader>
          <CardTitle tag="h3">Patient Virtualization</CardTitle>
          <Row>
            {!isVolunteerSupervisor(role) && (
              <Col sm={3}>
                <Select
                  className="mt-4"
                  options={townships}
                  value={selectedTownship}
                  onChange={(value) => setSelectedTownship(value)}
                  placeholder="Filter by township..."
                  isSearchable={true}
                  isClearable={true}
                />
              </Col>
            )}
            <Col sm={3}>
              <label htmlFor="start_date">Start Date</label>
              <input
                id="start_date"
                className="form-control"
                type="date"
                onChange={(event) => setStartDate(event.target.value)}
                value={startDate}
              />
            </Col>
            <Col sm={3}>
              <label htmlFor="end_date">End Date</label>
              <input
                id="end_date"
                className="form-control"
                type="date"
                min={startDate}
                max={currentDate()}
                onChange={(event) => setEndDate(event.target.value)}
                disabled={startDate === ""}
                value={endDate}
              />
            </Col>
            <Col sm={3}>
              <Button
                size="sm"
                color="success"
                onClick={handleFilter}
                className="mt-4"
              >
                <i className="fa fa-search" />
              </Button>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <LineChart
            title="Total Patients"
            total={`${totalPatientsTotal} | TB - ${totalTBPatientsTotal}`}
            className="total_patients"
            data={totalPatients}
          />
          <BarChart
            title="Case Founded Activity"
            total={caseFoundedActivitiesTotal}
            className="case_founded_activity"
            data={caseFoundedActivities}
          />
          <PieChart
            title="Defined TB Type"
            total={definedTBTypeTotal}
            className="defined_TB_type"
            data={definedTBTypePie}
          />
          <PieChart
            title="Treatment Regimen"
            total={treatmentRegimenPieTotal}
            className="treatment_regimen"
            data={treatmentRegimenPie}
          />
          <PieChart
            title="TB Patients Outcomes"
            total={TBPatientsOutcomePieTotal}
            className="TB_patients_outcome"
            data={TBPatientsOutcomePie}
          />
          <PieChart
            title="Type of TB Patients"
            total={typeOfTBPatientPieTotal}
            className="type_of_TB_patient"
            data={typeOfTBPatientPie}
          />
        </CardBody>
      </Card>
    </Container>
  );
};

export default PatientVirtualization;
