import React from 'react'
import CustomModal from 'components/Modal/CustomModal'
import { Col, Container, Row, Table } from 'reactstrap'
import { connect } from 'react-redux'

const TakeMedicineDetail = ({ isOpen, toggle, takeMedicine }) => {
  const {
    date,
    take_medicine_records,
    health_worker_sign,
    volunteer_sign,
  } = takeMedicine.takeMedicine

  return (
    <CustomModal
      isOpen={isOpen}
      title="တီဘီဆေးရရှိမှု အသေးစိတ်"
      onClick={() => toggle(false)}
      style={{ maxWidth: '60%' }}
    >
      <Container fluid>
        <Row className="mb-3">
          <Col sm={6}>နေ့စွဲ</Col>
          <Col sm={6}>{date}</Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <Table
              className="app-table align-items-center table-flush"
              responsive
            >
              <thead>
                <tr>
                  <th>ဆေးအမည်</th>
                  <th>ဆေးလုံးအရေအတွက်</th>
                </tr>
              </thead>
              <tbody>
                {take_medicine_records?.map((record) => (
                  <tr key={record.id}>
                    <td>{record.medicine_name}</td>
                    <td>{record.quantity}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={6}>တာဝန်ခံကျန်းမာရေး ဝန်ထမ်းလက်မှတ်</Col>
          <Col sm={6}>
            <img
              src={health_worker_sign}
              alt="health worker sign"
              width={150}
              height={130}
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={6}>ဆေးတိုက်ကျွေးသူလက်မှတ်</Col>
          <Col sm={6}>
            <img
              src={volunteer_sign}
              alt="volunteer sign"
              width={150}
              height={130}
            />
          </Col>
        </Row>
      </Container>
    </CustomModal>
  )
}

const mapStateToProps = (store) => ({
  takeMedicine: store.takeMedicine,
})

export default connect(mapStateToProps)(TakeMedicineDetail)
