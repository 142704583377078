import {
  CREATE_DISTRICT,
  DELETE_DISTRICT,
  SET_DISTRICT,
  SHOW_DISTRICTS,
  UPDATE_DISTRICT,
} from '../type'

const initialState = {
  districts: [],
  district: {},
}

const district = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_DISTRICTS:
      return {
        ...state,
        districts: action.payload,
      }
    case CREATE_DISTRICT:
      return {
        ...state,
        districts: [action.payload, ...state.districts],
      }
    case DELETE_DISTRICT:
      return {
        ...state,
        districts: state.districts.filter(
          (district) => district.id !== action.payload,
        ),
      }
    case SET_DISTRICT:
      return {
        ...state,
        district: action.payload,
      }
    case UPDATE_DISTRICT:
      let index = state.districts.findIndex(
        (district) => district.id === action.payload.id,
      )
      state.districts[index] = action.payload
      if (state.district.id === action.payload.id) {
        state.district = action.payload
      }
      return {
        ...state,
      }
    default:
      return state
  }
}

export default district
