import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Form,
  Row,
  Col,
  CardBody,
  CardFooter,
  CardTitle,
} from "reactstrap";
import CustomSelect from "components/Inputs/CustomSelect";
import { dones } from "variables/options";
import CustomDateInput from "components/Inputs/CustomDateInput";
import { currentDate } from "utils/currentDate";
import { sputumSmearResults } from "variables/options";
import { connect } from "react-redux";
import { updatePatient } from "store/actions";
import { useParams } from "react-router";
import { useQuery } from "hooks/useQuery";

const Sputum = ({ patient, updatePatient }) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const { id } = useParams();

  const fromTBPatient = useQuery("from_TB_patient");

  const [loading, setLoading] = useState(false);
  const [suptumDoneNotDone, setSuptumDoneNotDone] = useState(null);
  const [sputumSmearResult, setSputumSmearResult] = useState(null);

  useEffect(() => {
    const {
      sputum_done_or_not_done,
      sputum_examination_date,
      sputum_smear_result,
    } = patient.patient;

    setValue(
      "sputum_done_not_done",
      sputum_done_or_not_done === null
        ? null
        : { value: sputum_done_or_not_done, label: sputum_done_or_not_done }
    );
    setSuptumDoneNotDone(
      sputum_done_or_not_done === null
        ? null
        : { value: sputum_done_or_not_done, label: sputum_done_or_not_done }
    );
    setValue("sputum_examination_date", sputum_examination_date);
    setValue(
      "sputum_smear_result",
      sputum_smear_result === null
        ? null
        : { value: sputum_smear_result, label: sputum_smear_result }
    );
    setSputumSmearResult(
      sputum_smear_result === null
        ? null
        : { value: sputum_smear_result, label: sputum_smear_result }
    );
  }, [setValue, patient.patient]);

  const onSubmit = async (data) => {
    setLoading(true);
    await updatePatient(
      id,
      {
        sputum_done_or_not_done: data.sputum_done_not_done?.value,
        sputum_examination_date:
          data.sputum_done_not_done?.value === "Not Done"
            ? null
            : data.sputum_examination_date,
        sputum_smear_result:
          data.sputum_done_not_done?.value === "Not Done"
            ? null
            : data.sputum_smear_result?.value,
      },
      "sputum",
      "Sputum"
    );
    setLoading(false);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <CardBody>
        <CardTitle>
          <h3>Sputum Examination</h3>
        </CardTitle>
        <Row>
          <Col md={4}>
            <CustomSelect
              id="sputum_done_not_done"
              label="Sputum Done or Not Done"
              control={control}
              rules={{
                required: "Sputum Done or Not Done is required!",
              }}
              options={dones}
              value={suptumDoneNotDone}
              setData={setSuptumDoneNotDone}
              placeholder="Sputum Done or Not Done"
              isRequired={true}
            />
          </Col>
          <Col md={4}>
            <CustomDateInput
              id="sputum_examination_date"
              label="Sputum Examination Date"
              register={{
                ...register("sputum_examination_date", {
                  required:
                    suptumDoneNotDone?.value === "Done"
                      ? "Sputum Examination Date is required!"
                      : false,
                }),
              }}
              placeholder="Select Sputum Examination Date"
              errors={errors}
              max={currentDate()}
              disabled={
                suptumDoneNotDone === null ||
                suptumDoneNotDone?.value !== "Done"
              }
              isRequired={suptumDoneNotDone?.value === "Done"}
            />
          </Col>
          <Col md={4}>
            <CustomSelect
              id="sputum_smear_result"
              label="Sputum Smear Result"
              control={control}
              rules={{
                required:
                  suptumDoneNotDone?.value === "Done"
                    ? "Sputum Smear Result is required!"
                    : false,
              }}
              options={sputumSmearResults}
              value={sputumSmearResult}
              setData={setSputumSmearResult}
              placeholder="Sputum Smear Result"
              isRequired={suptumDoneNotDone?.value === "Done"}
              isDisabled={
                suptumDoneNotDone === null ||
                suptumDoneNotDone?.value !== "Done"
              }
            />
          </Col>
        </Row>
      </CardBody>
      <CardFooter className="text-right">
        {fromTBPatient && (
          <Button color="primary" size="sm" disabled={loading} type="submit">
            {loading ? "Loading" : "Update"}
          </Button>
        )}
      </CardFooter>
    </Form>
  );
};

const mapStateToProps = (store) => ({
  patient: store.patient,
});

export default connect(mapStateToProps, { updatePatient })(Sputum);
