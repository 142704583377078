import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import classNames from 'classnames'
import {
  Container,
  Card,
  CardHeader,
  Table,
  Row,
  Col,
  Button,
} from 'reactstrap'
import { reportMonths } from 'variables/options'
import styles from './NTPCBTBCTemplate.module.css'
import { years } from 'variables/options'
import BackBtn from 'utils/backBtn'
import { connect } from 'react-redux'
import { getTownships, getCBTBCReport } from 'store/actions'
import FullScreenLoading from 'components/FullScreenLoading/FullScreenLoading'
import { isVolunteerSupervisor } from 'utils/middleware'
import { useHistory } from 'react-router'
import queryString from 'query-string'
import ReactHTMLTableToExcel from 'react-html-table-to-excel'

const NTPCBTBCTemplate = ({
  status,
  auth,
  township,
  getTownships,
  report,
  getCBTBCReport,
}) => {
  const { role } = auth.user
  const router = useHistory()

  const [selectedMonth, setSelectedMonth] = useState(null)
  const [selectedYear, setSelectedYear] = useState(null)
  const [selectedTownship, setSelectedTownship] = useState(null)

  const townships = township.townships.map((element) => ({
    value: element.id,
    label: element.name,
  }))

  const handleFilter = () => {
    const query = queryString.parse(router.location.search)
    if (selectedTownship) {
      query.township_id = selectedTownship.value
    } else {
      delete query['township_id']
    }

    if (selectedMonth) {
      query.month = selectedMonth.value
    } else {
      delete query['month']
    }

    if (selectedYear) {
      query.year = selectedYear.value
    } else {
      delete query['year']
    }

    router.push(`${router.location.pathname}?${queryString.stringify(query)}`)
  }

  useEffect(() => {
    if (role !== undefined && !isVolunteerSupervisor(role)) {
      getTownships()
    }
  }, [getTownships, role])

  useEffect(() => {
    const query = queryString.parse(router.location.search)
    getCBTBCReport(query)
  }, [getCBTBCReport, router.location.search])

  if (status.loading) {
    return <FullScreenLoading />
  }

  const {
    // reported_volunteers,
    region_name,
    township_name,
    month,
    year,
    _a_female,
    _a_male,
    _a_total,
    _b_male,
    _b_female,
    _b_total,
    _c_female,
    _c_male,
    _c_total,
    _d_female,
    _d_male,
    _d_total,
    _e_female,
    _e_male,
    _e_total,
    _f_female,
    _f_male,
    _f_total,
    _g_female,
    _g_male,
    _g_total,
    _h_female,
    _h_male,
    _h_total,
    _i_total,
    _j_female,
    _j_male,
    _j_total,
    _k_female,
    _k_male,
    _k_total,
    _l_female,
    _l_male,
    _l_total,
    _m_total,
    _n_female,
    _n_male,
    _n_total,
    _o_female,
    _o_male,
    _o_total,
  } = report.CBTBCReport

  let fileName = 'CBTBC-Report'
  if (selectedMonth) {
    fileName += `-${selectedMonth.label}`
  }
  if (selectedYear) {
    fileName += `-${selectedYear.label}`
  }
  if (selectedTownship) {
    fileName += `-${selectedTownship.label}`
  }

  return (
    <Container className="mt-3" fluid>
      <BackBtn />
      <Card>
        <CardHeader className="border-0">
          <Row>
            <Col xs="6">
              <h3 className="mb-0">NTP CBTBC Report</h3>
            </Col>
            <Col className="text-right" xs="6">
              <ReactHTMLTableToExcel
                className="btn btn-info btn-sm mb-3"
                table="table-to-xls"
                filename={fileName}
                sheet="CBTBC-Report"
                buttonText="Export"
              />
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <Select
                className="mt-3"
                options={reportMonths}
                value={selectedMonth}
                onChange={(value) => setSelectedMonth(value)}
                placeholder="Filter by month..."
                isSearchable={true}
                isClearable={true}
              />
            </Col>
            <Col md={3}>
              <Select
                className="mt-3"
                options={years}
                value={selectedYear}
                onChange={(value) => setSelectedYear(value)}
                placeholder="Filter by year..."
                isSearchable={true}
                isClearable={true}
              />
            </Col>
            {role !== undefined && !isVolunteerSupervisor(role) && (
              <Col sm={3}>
                <Select
                  className="mt-3"
                  options={townships}
                  value={selectedTownship}
                  onChange={(value) => setSelectedTownship(value)}
                  placeholder="Filter by township..."
                  isSearchable={true}
                  isClearable={true}
                />
              </Col>
            )}
            <Col md={3}>
              <Button
                className="mt-3"
                size="sm"
                color="success"
                onClick={handleFilter}
              >
                <i className="fa fa-search" />
              </Button>
            </Col>
          </Row>
        </CardHeader>

        <Table
          className={classNames('app-table align-items-center table-fixed')}
          responsive
          id="table-to-xls"
        >
          <thead className="thead-light">
            <tr>
              <th
                className={['fixed-cell left', styles.purple].join(' ')}
                rowSpan={2}
              >
                No
              </th>
              <th
                className={['fixed-cell left', styles.purple].join(' ')}
                rowSpan={2}
              >
                Organization
              </th>
              <th className={styles.purple} rowSpan={2}>
                Funding source
              </th>
              <th className={styles.purple} rowSpan={2}>
                Type of project
              </th>
              <th className={styles.purple} rowSpan={2}>
                Region/State
              </th>
              <th className={styles.purple} rowSpan={2}>
                Township
              </th>
              <th className={styles.purple} rowSpan={2}>
                Year
              </th>
              <th className={styles.purple} rowSpan={2}>
                Quarter
              </th>
              <th
                className={[styles.purple, styles.width].join(' ')}
                colSpan={3}
              >
                No of CHVs trained (A)
              </th>
              <th
                className={[styles.purple, styles.width].join(' ')}
                colSpan={3}
              >
                No of reported CHVs (active CHVs) (B)
              </th>
              <th
                className={[styles.skyblue, styles.width].join(' ')}
                colSpan={3}
              >
                No of presumptive TB cases referred in the community ©
              </th>
              <th className={[styles.red, styles.width].join(' ')} colSpan={3}>
                No of TB patients detected among presumptive TB referred in the
                community (D)
              </th>
              <th
                className={[styles.skyblue, styles.width].join(' ')}
                colSpan={3}
              >
                No of presumptive TB cases referred among family member by
                contact tracing (E)
              </th>
              <th className={[styles.red, styles.width].join(' ')} colSpan={3}>
                No of TB patients detected by contact tracing (F)
              </th>
              <th className={[styles.skyblue, styles.width].join(' ')}>
                Total No. of presumptive TB case referral (C+E)
              </th>
              <th className={[styles.red, styles.width].join(' ')}>
                No. of total TB patients among Presemptive TB referred (D+F)
              </th>
              <th
                className={[styles.purple, styles.width].join(' ')}
                colSpan={3}
              >
                No of TB patients who initiated DOT provided by volunteer (G)
              </th>
              <th
                className={[styles.purple, styles.width].join(' ')}
                colSpan={3}
              >
                No of TB patients completed treatment in same quarter of the
                previous year (H)
              </th>
              <th className={[styles.purple, styles.width].join(' ')}>
                Total Number of HE sessions held (I)
              </th>
              <th
                className={[styles.purple, styles.width].join(' ')}
                colSpan={3}
              >
                No . of attendees in HE sessions (J)
              </th>
              <th
                className={[styles.purple, styles.width].join(' ')}
                colSpan={3}
              >
                No of TB patients referred for HIV testing and counselling (K)
              </th>
              <th
                className={[styles.purple, styles.width].join(' ')}
                colSpan={3}
              >
                No. of TB Patients who were tested for HIV (L)
              </th>
              <th className={[styles.purple, styles.width].join(' ')}>
                No of supervisory visits conducted by supervisors (M)
              </th>
              <th
                className={[styles.skyblue, styles.width].join(' ')}
                colSpan={3}
              >
                No of presumptive TB cases referred by volunteers to mobile team
                visit (N)
              </th>

              <th className={[styles.red, styles.width].join(' ')} colSpan={3}>
                No of TB patients detected among presumptive TB referred by
                volunteers to mobile team visit (O)
              </th>
              <th
                className={[styles.skyblue, styles.width].join(' ')}
                colSpan={3}
              >
                No of presumptive TB cases referred by volunteers to PPM clinics
                (P)
              </th>
              <th className={[styles.red, styles.width].join(' ')} colSpan={3}>
                No of TB patients detected among presumptive TB referred by
                volunteers to PPM Clinics (Q)
              </th>
            </tr>
            <tr>
              <th className={styles.purple}>Male</th>
              <th className={styles.purple}>Female</th>
              <th className={styles.purple}>Total</th>
              <th className={styles.purple}>Male</th>
              <th className={styles.purple}>Female</th>
              <th className={styles.purple}>Total</th>
              <th className={styles.skyblue}>Male</th>
              <th className={styles.skyblue}>Female</th>
              <th className={styles.skyblue}>Total</th>
              <th className={styles.red}>Male</th>
              <th className={styles.red}>Female</th>
              <th className={styles.red}>Total</th>
              <th className={styles.skyblue}>Male</th>
              <th className={styles.skyblue}>Female</th>
              <th className={styles.skyblue}>Total</th>
              <th className={styles.red}>Male</th>
              <th className={styles.red}>Female</th>
              <th className={styles.red}>Total</th>
              <th className={styles.skyblue}>Total</th>
              <th className={styles.red}>Total</th>
              <th className={styles.purple}>Male</th>
              <th className={styles.purple}>Female</th>
              <th className={styles.purple}>Total</th>
              <th className={styles.purple}>Male</th>
              <th className={styles.purple}>Female</th>
              <th className={styles.purple}>Total</th>
              <th className={styles.purple}>Total</th>
              <th className={styles.purple}>Male</th>
              <th className={styles.purple}>Female</th>
              <th className={styles.purple}>Total</th>
              <th className={styles.purple}>Male</th>
              <th className={styles.purple}>Female</th>
              <th className={styles.purple}>Total</th>
              <th className={styles.purple}>Male</th>
              <th className={styles.purple}>Female</th>
              <th className={styles.purple}>Total</th>
              <th className={styles.purple}>Total</th>
              <th className={styles.skyblue}>Male</th>
              <th className={styles.skyblue}>Female</th>
              <th className={styles.skyblue}>Total</th>
              <th className={styles.red}>Male</th>
              <th className={styles.red}>Female</th>
              <th className={styles.red}>Total</th>
              <th className={styles.skyblue}>Male</th>
              <th className={styles.skyblue}>Female</th>
              <th className={styles.skyblue}>Total</th>
              <th className={styles.red}>Male</th>
              <th className={styles.red}>Female</th>
              <th className={styles.red}>Total</th>
            </tr>
          </thead>
          <tbody className="list">
            <tr>
              <td className="fixed-cell left">1</td>
              <td className="fixed-cell left">MMA</td>
              <td>USAID</td>
              <td>MMA TB Project</td>
              <td>{region_name}</td>
              <td>{township_name}</td>
              <td>{year}</td>
              <td>{month}</td>
              <td>{_a_male}</td>
              <td>{_a_female}</td>
              <td>{_a_total}</td>
              <td>{_b_male}</td>
              <td>{_b_female}</td>
              <td>{_b_total}</td>
              <td>{_c_male}</td>
              <td>{_c_female}</td>
              <td>{_c_total}</td>
              <td>{_d_male}</td>
              <td>{_d_female}</td>
              <td>{_d_total}</td>
              <td>{_e_male}</td>
              <td>{_e_female}</td>
              <td>{_e_total}</td>
              <td>{_f_male}</td>
              <td>{_f_female}</td>
              <td>{_f_total}</td>
              <td>{(_c_total + _e_total).toString()}</td>
              <td>{(_d_total + _f_total).toString()}</td>
              <td>{_g_male}</td>
              <td>{_g_female}</td>
              <td>{_g_total}</td>
              <td>{_h_male}</td>
              <td>{_h_female}</td>
              <td>{_h_total}</td>
              <td>{_i_total}</td>
              <td>{_j_male}</td>
              <td>{_j_female}</td>
              <td>{_j_total}</td>
              <td>{_k_male}</td>
              <td>{_k_female}</td>
              <td>{_k_total}</td>
              <td>{_l_male}</td>
              <td>{_l_female}</td>
              <td>{_l_total}</td>
              <td>{_m_total}</td>
              <td>{_n_male}</td>
              <td>{_n_female}</td>
              <td>{_n_total}</td>
              <td>{_o_male}</td>
              <td>{_o_female}</td>
              <td>{_o_total}</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
            </tr>
          </tbody>
        </Table>
      </Card>
    </Container>
  )
}

const mapStateToProps = (store) => ({
  status: store.status,
  auth: store.auth,
  township: store.township,
  report: store.report,
})

export default connect(mapStateToProps, {
  getTownships,
  getCBTBCReport,
})(NTPCBTBCTemplate)
