import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Form,
  Row,
  Col,
  CardBody,
  CardFooter,
  CardTitle,
} from "reactstrap";
import CustomSelect from "components/Inputs/CustomSelect";
import { dones } from "variables/options";
import CustomDateInput from "components/Inputs/CustomDateInput";
import { currentDate } from "utils/currentDate";
import { chestXRayResults } from "variables/options";
import { connect } from "react-redux";
import { updatePatient } from "store/actions";
import { useParams } from "react-router";
import { useQuery } from "hooks/useQuery";

const ChestXRay = ({ patient, updatePatient }) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  const { id } = useParams();

  const fromTBPatient = useQuery("from_TB_patient");

  const [loading, setLoading] = useState(false);
  const [chestXRayDoneNotDone, setChestXRayDoneNotDone] = useState(null);
  const [chestXRayResult, setChestXRayResult] = useState(null);

  useEffect(() => {
    const {
      chest_Xray_done_or_not_done,
      chest_Xray_examination_date,
      chest_Xray_result,
    } = patient.patient;

    setValue(
      "chest_xray_done_not_done",
      chest_Xray_done_or_not_done === null
        ? null
        : {
            value: chest_Xray_done_or_not_done,
            label: chest_Xray_done_or_not_done,
          }
    );
    setChestXRayDoneNotDone(
      chest_Xray_done_or_not_done === null
        ? null
        : {
            value: chest_Xray_done_or_not_done,
            label: chest_Xray_done_or_not_done,
          }
    );
    setValue("chest_xray_examination_date", chest_Xray_examination_date);
    setValue(
      "chest_xray_result",
      chest_Xray_result === null
        ? null
        : { value: chest_Xray_result, label: chest_Xray_result }
    );
    setChestXRayResult(
      chest_Xray_result === null
        ? null
        : { value: chest_Xray_result, label: chest_Xray_result }
    );
  }, [setValue, patient.patient]);

  const onSubmit = async (data) => {
    setLoading(true);
    await updatePatient(
      id,
      {
        chest_Xray_done_or_not_done: data.chest_xray_done_not_done?.value,
        chest_Xray_examination_date:
          data.chest_xray_done_not_done?.value === "Not Done"
            ? null
            : data.chest_xray_examination_date,
        chest_Xray_result:
          data.chest_xray_done_not_done?.value === "Not Done"
            ? null
            : data.chest_xray_result?.value,
      },
      "chest-XRay",
      "Chest X-Ray"
    );
    setLoading(false);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <CardBody>
        <CardTitle>
          <h3>Chest X-Ray Examination</h3>
        </CardTitle>
        <Row>
          <Col md={4}>
            <CustomSelect
              id="chest_xray_done_not_done"
              label="Chest X-Ray Done or Not Done"
              control={control}
              rules={{
                required: "Chest X-Ray Done or Not Done is required!",
              }}
              options={dones}
              value={chestXRayDoneNotDone}
              setData={setChestXRayDoneNotDone}
              placeholder="Chest X-Ray Done or Not Done"
              isRequired={true}
            />
          </Col>
          <Col md={4}>
            <CustomDateInput
              id="chest_xray_examination_date"
              label="CXR Examination Date"
              register={{
                ...register("chest_xray_examination_date", {
                  required:
                    chestXRayDoneNotDone?.value === "Done"
                      ? "CXR Examination Date is required!"
                      : false,
                }),
              }}
              placeholder="Select CXR Examination Date"
              errors={errors}
              max={currentDate()}
              disabled={
                chestXRayDoneNotDone === null ||
                chestXRayDoneNotDone?.value !== "Done"
              }
              isRequired={chestXRayDoneNotDone?.value === "Done"}
            />
          </Col>
          <Col md={4}>
            <CustomSelect
              id="chest_xray_result"
              label="Chest X-Ray Result"
              control={control}
              rules={{
                required:
                  chestXRayDoneNotDone?.value === "Done"
                    ? "Chest X-Ray Result is required!"
                    : false,
              }}
              options={chestXRayResults}
              value={chestXRayResult}
              setData={setChestXRayResult}
              placeholder="Chest X-Ray Result"
              isRequired={chestXRayDoneNotDone?.value === "Done"}
              isDisabled={
                chestXRayDoneNotDone === null ||
                chestXRayDoneNotDone?.value !== "Done"
              }
            />
          </Col>
        </Row>
      </CardBody>
      <CardFooter className="text-right">
        {fromTBPatient && (
          <Button color="primary" size="sm" disabled={loading} type="submit">
            {loading ? "Loading" : "Update"}
          </Button>
        )}
      </CardFooter>
    </Form>
  );
};

const mapStateToProps = (store) => ({
  patient: store.patient,
});

export default connect(mapStateToProps, { updatePatient })(ChestXRay);
