import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { Button, Card, CardHeader, Container, Row, Col } from 'reactstrap'
import queryString from 'query-string'
import { useHistory } from 'react-router-dom'
import VolunteerCreate from './VolunteerCreate'
import CustomPagination from 'components/Pagination/Pagination'
import CustomTable from 'components/Table/Table'
import VolunteerDetail from './VolunteerDetail'
import { connect } from 'react-redux'
import { getVolunteers, getVolunteer, getTownships } from 'store/actions'
import FullScreenLoading from 'components/FullScreenLoading/FullScreenLoading'
import { paginationLimit } from 'variables/limits'
import {
  volunteerMiddleware,
  isVolunteerSupervisor,
  CREATE,
  EXPORT,
} from 'utils/middleware'
import { NotificationManager } from 'react-notifications'
import { call } from 'services/api'
import { excelExport } from 'utils/excelExport'
import BackBtn from 'utils/backBtn'

const VolunteerList = ({
  status,
  auth,
  volunteer,
  township,
  getVolunteers,
  getVolunteer,
  getTownships,
}) => {
  const { role } = auth.user
  const router = useHistory()

  const [exportLoading, setExportLoading] = useState(false)
  const [isCreate, setIsCreate] = useState(false)
  const [isDetail, setIsDetail] = useState(false)
  const [selectedTownship, setSelectedTownship] = useState(null)

  const townships = township.townships.map((element) => ({
    value: element.id,
    label: element.name,
  }))

  const handleFilter = () => {
    const query = queryString.parse(router.location.search)
    query.page = 1
    if (selectedTownship) {
      query.township_id = selectedTownship.value
    } else {
      delete query['township_id']
    }

    router.push(`${router.location.pathname}?${queryString.stringify(query)}`)
  }

  useEffect(() => {
    const query = queryString.parse(router.location.search)
    if (!('page' in query)) {
      query.page = 1
    }
    getVolunteers(query)
  }, [getVolunteers, router.location.search])

  useEffect(() => {
    if (role !== undefined && !isVolunteerSupervisor(role)) {
      getTownships()
    }
  }, [getTownships, role])

  if (status.loading) {
    return <FullScreenLoading />
  }

  const handleExport = async () => {
    setExportLoading(true)
    try {
      const query = queryString.parse(router.location.search)
      const response = await call(
        'get',
        `volunteers-export?${new URLSearchParams(query).toString()}`,
      )
      const result = response.data.map((data, index) => ({
        ID: index + 1,
        Name: data.name,
        Code: data.code,
        Plain_Password: data.plain_password,
        Gender: data.gender,
        Age: data.age,
        Address: data.address,
        Phone: data.phone,
        Email: data.email,
        Education_Status: data.education_status,
        Township: data.township.name,
        Past_History_of_TB: data.past_history_of_TB,
        Previous_Volunteer_Experience: data.previous_volunteer_experience,
        Remark: data.remark,
      }))

      let fileName = 'Volunteers'
      if (selectedTownship) {
        fileName += `-${selectedTownship.label}`
      }

      if (response.status === 'success') {
        excelExport(result, fileName)
      }
    } catch (_) {
      NotificationManager.error('Please try again!')
    }
    setExportLoading(false)
  }

  return (
    <>
      <Container className="mt-3" fluid>
        <BackBtn />
        <Card>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-0">Volunteers</h3>
              </Col>
              <Col className="text-right" xs="6">
                {volunteerMiddleware(role, CREATE) && (
                  <Button
                    size="sm"
                    color="success"
                    onClick={() => setIsCreate(true)}
                  >
                    New
                  </Button>
                )}
                {volunteerMiddleware(role, EXPORT) && (
                  <Button
                    size="sm"
                    color="info"
                    onClick={handleExport}
                    disabled={exportLoading}
                  >
                    {exportLoading ? 'Loading' : 'Export'}
                  </Button>
                )}
              </Col>
            </Row>
            <Row>
              {!isVolunteerSupervisor(role) && (
                <>
                  <Col sm={4}>
                    <Select
                      className="mt-3"
                      options={townships}
                      value={selectedTownship}
                      onChange={(value) => setSelectedTownship(value)}
                      placeholder="Filter by township..."
                      isSearchable={true}
                      isClearable={true}
                    />
                  </Col>
                  <Col sm={4}>
                    <Button
                      size="sm"
                      color="success"
                      onClick={handleFilter}
                      className="mt-3"
                    >
                      <i className="fa fa-search" />
                    </Button>
                  </Col>
                </>
              )}
            </Row>
          </CardHeader>

          <CustomTable
            header={
              <tr>
                <th className="fixed-cell left" scope="col">
                  ID
                </th>
                <th className="fixed-cell left" scope="col">
                  Name
                </th>
                <th scope="col">Code</th>
                <th scope="col">Password</th>
                <th scope="col">Address</th>
                <th scope="col">Phone</th>
                <th scope="col">Township</th>
                <th scope="col">Recover Township</th>
                <th scope="col">Action</th>
              </tr>
            }
            body={volunteer.volunteers.map((volunteer, index) => (
              <tr key={volunteer.id}>
                <td className="fixed-cell left">{index + 1}</td>
                <td className="fixed-cell left">{volunteer.name}</td>
                <td>{volunteer.code}</td>
                <td>{volunteer.plain_password}</td>
                <td>{volunteer.address}</td>
                <td>{volunteer.phone}</td>
                <td>{volunteer.township.name}</td>
                <td>{volunteer.recover_township?.name}</td>
                <td>
                  <Button
                    size="sm"
                    color="success"
                    onClick={async () => {
                      await getVolunteer(volunteer.id)
                      setIsDetail(true)
                    }}
                  >
                    Detail
                  </Button>
                </td>
              </tr>
            ))}
          />

          {volunteer.total > paginationLimit && (
            <CustomPagination pageCount={volunteer.total / paginationLimit} />
          )}
        </Card>
      </Container>

      <VolunteerDetail isOpen={isDetail} toggle={setIsDetail} />
      <VolunteerCreate isOpen={isCreate} toggle={setIsCreate} />
    </>
  )
}

const mapStateToProps = (store) => ({
  status: store.status,
  auth: store.auth,
  township: store.township,
  volunteer: store.volunteer,
})

export default connect(mapStateToProps, {
  getVolunteers,
  getVolunteer,
  getTownships,
})(VolunteerList)
