import {
  CHANGE_STATUS_NOAPP,
  CREATE_DOTS,
  CREATE_DOTS_DASHBOARD,
  DELETE_DOT,
  SET_DOT,
  SHOW_DOTS,
} from "../type";

const initialState = {
  DOTs: [],
  DOT: {},
  total: 0,
};

const DOT = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_DOTS:
      return {
        ...state,
        DOTs: action.payload.data,
        total: action.payload.total,
      };
    case CREATE_DOTS:
      return {
        ...state,
        DOTs: [...state.DOTs, ...action.payload],
        total: state.total + action.payload.length,
      };
    case CREATE_DOTS_DASHBOARD:
      return {
        ...state,
        DOTs: [action.payload, ...state.DOTs],
        total: state.total + 1,
      };
    case SET_DOT:
      return {
        ...state,
        DOT: action.payload,
      };
    case CHANGE_STATUS_NOAPP:
      let index = state.DOTs.findIndex((DOT) => DOT.id === action.payload.id);
      state.DOTs[index] = action.payload;
      if (state.DOT.id === action.payload.id) {
        state.DOT = action.payload;
      }
      return {
        ...state,
      };
    case DELETE_DOT:
      return {
        ...state,
        DOTs: state.DOTs.filter((DOT) => DOT.id !== action.payload),
        total: state.total - 1,
      };
    default:
      return state;
  }
};

export default DOT;
