import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import CustomPagination from 'components/Pagination/Pagination'
import CustomTable from 'components/Table/Table'
import { Button, Card, CardHeader, Col, Container, Row } from 'reactstrap'
import HEDetail from './HEDetail'
import queryString from 'query-string'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { getHEs, getHE, getVolunteers, getTownships } from 'store/actions'
import FullScreenLoading from 'components/FullScreenLoading/FullScreenLoading'
import { paginationLimit } from 'variables/limits'
import {
  isVolunteerSupervisor,
  activitiesMiddleware,
  EXPORT,
} from 'utils/middleware'
import { NotificationManager } from 'react-notifications'
import { call } from 'services/api'
import { excelExport } from 'utils/excelExport'
import BackBtn from 'utils/backBtn'

const HEList = ({
  status,
  auth,
  he,
  township,
  volunteer,
  getHEs,
  getHE,
  getVolunteers,
  getTownships,
}) => {
  const { role } = auth.user
  const router = useHistory()

  const [exportLoading, setExportLoading] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  const [monthYear, setMonthYear] = useState('')
  const [selectedTownship, setSelectedTownship] = useState(null)
  const [selectedVolunteer, setSelectedVolunteer] = useState(null)

  const townships = township.townships.map((element) => ({
    value: element.id,
    label: element.name,
  }))

  const volunteers = volunteer.volunteers.map((element) => ({
    value: element.id,
    label: `${element.name}(${element.code})`,
  }))

  const handleFilter = () => {
    const query = queryString.parse(router.location.search)
    query.page = 1
    if (monthYear !== '') {
      query.month_year = monthYear
    } else {
      delete query['month_year']
    }

    if (selectedVolunteer) {
      query.volunteer_id = selectedVolunteer.value
    } else {
      delete query['volunteer_id']
    }

    if (selectedTownship) {
      query.township_id = selectedTownship.value
    } else {
      delete query['township_id']
    }

    router.push(`${router.location.pathname}?${queryString.stringify(query)}`)
  }

  useEffect(() => {
    const query = queryString.parse(router.location.search)
    if (!('page' in query)) {
      query.page = 1
    }
    getHEs(query)
  }, [getHEs, router.location.search])

  useEffect(() => {
    if (role !== undefined && !isVolunteerSupervisor(role)) {
      getTownships()
    }
  }, [getTownships, role])

  useEffect(() => {
    getVolunteers()
  }, [getVolunteers, role])

  if (status.loading) {
    return <FullScreenLoading />
  }

  const handleExport = async () => {
    setExportLoading(true)
    try {
      const query = queryString.parse(router.location.search)
      const response = await call(
        'get',
        `hes-export?${new URLSearchParams(query).toString()}`,
      )
      const result = response.data.map((data, index) => ({
        ID: index + 1,
        Volunteer: `${data.volunteer?.name}(${data.volunteer?.code})`,
        Township: data.volunteer?.township?.name,
        Date: data.date,
        "ကျင်းပသည့်နေရာ": data.place,
        'တက်ရောက်သူအရေအတွက်(ကျား)': data.male,
        'တက်ရောက်သူအရေအတွက်(မ)': data.female,
        'တက်ရောက်သူအရေအတွက်(စုစုပေါင်း)': data.total,
        'ရွှေ့ပြောင်း': data.move,
        'HIV ရောဂါပိုးရှိသူ': data.HIV,
        'ဆီးချိုရောဂါရှိသူ': data.diabetes,
        'သက်ကြီးရွယ်အို': data.elderly,
        'ကိုယ်ဝန်ဆောင်': data.pregnant,
        'သာမန်လူ': data.ordinary_people,
        'အခြား': data.other,
        'ဆွေးနွေးခဲ့သောအကြောင်းအရာ': data.topic_discussed,
        'ဆွေးနွေးခဲ့သောအကြောင်းအရာ(အခြား)': data.topic_discussed_other,
        'တီဘီရောဂါတိုက်ဖျက်ရေးဌာန': `${data.department_TB_male}(Male)|${data.department_TB_female}(Female)|${data.department_TB_total}(Total)`,
        'မိတ်ဖက် ပြင်ပ/NGO ဆေးခန်း': `${data.partner_external_clinic_male}(Male)|${data.partner_external_clinic_female}(Female)|${data.partner_external_clinic_total}(Total)`,
        'ရွေ့လျားတီဘီရှာဖွေရေးဆေးခန်း': `${data.mobile_TB_clinic_male}(Male)|${data.mobile_TB_clinic_female}(Female)|${data.mobile_TB_clinic_total}(Total)`,
      }))

      let fileName = 'HE'
      if(monthYear) {
        fileName += `-${monthYear}`
      }
      if(selectedVolunteer) {
        fileName += `-${selectedVolunteer.label}`
      }
      if(selectedTownship) {
        fileName += `-${selectedTownship.label}`
      }

      if (response.status === 'success') {
        excelExport(result, fileName)
      }
    } catch (_) {
      NotificationManager.error('Please try again!')
    }
    setExportLoading(false)
  }

  return (
    <>
      <Container className="mt-3" fluid>
        <BackBtn />
        <Card>
          <CardHeader className="border-0">
            <Row>
              <Col sm={6}>
                <h3 className="mb-0">ကျန်းမာရေးအသိပညာပေးခြင်း</h3>
              </Col>
              {activitiesMiddleware(role, EXPORT) && (
                <Col sm={6} className="text-right">
                  <Button
                    size="sm"
                    color="info"
                    className="mb-3"
                    onClick={handleExport}
                    disabled={exportLoading}
                  >
                    {exportLoading ? 'Loading' : 'Export'}
                  </Button>
                </Col>
              )}
            </Row>
            <Row>
              <Col sm={3}>
                <label htmlFor="month_year">Month-Year</label>
                <input
                  id="month_year"
                  className="form-control"
                  type="month"
                  value={monthYear}
                  onChange={(event) => setMonthYear(event.target.value)}
                />
              </Col>
              {role !== undefined && !isVolunteerSupervisor(role) && (
                <Col sm={3}>
                  <Select
                    className="mt-4"
                    options={townships}
                    value={selectedTownship}
                    onChange={(value) => setSelectedTownship(value)}
                    placeholder="Filter by township..."
                    isSearchable={true}
                    isClearable={true}
                  />
                </Col>
              )}
              <Col sm={4}>
                <Select
                  className="mt-4"
                  options={volunteers}
                  value={selectedVolunteer}
                  onChange={(value) => setSelectedVolunteer(value)}
                  placeholder="Filter by volunteer..."
                  isSearchable={true}
                  isClearable={true}
                />
              </Col>
              <Col sm={2}>
                <Button
                  size="sm"
                  color="success"
                  onClick={handleFilter}
                  className="mt-4"
                >
                  <i className="fa fa-search" />
                </Button>
              </Col>
            </Row>
          </CardHeader>

          <CustomTable
            header={
              <tr>
                <th className="fixed-cell left" scope="col">
                  စဥ်
                </th>
                <th className="fixed-cell left" scope="col">
                  အမည်
                </th>
                <th scope="col">နေ့စွဲ</th>
                <th scope="col">ကျင်းပသည့်နေရာ</th>
                <th scope="col">တက်ရောက်သူအရေအတွက်(ကျား)</th>
                <th scope="col">တက်ရောက်သူအရေအတွက်(မ)</th>
                <th scope="col">တက်ရောက်သူအရေအတွက်(စုစုပေါင်း)</th>
                <th scope="col">မြို့နယ်</th>
                <th scope="col">Action</th>
              </tr>
            }
            body={he.hes.map((he, index) => (
              <tr key={he.id}>
                <td className="fixed-cell left">{index + 1}</td>
                <td className="fixed-cell left">{he.volunteer.name}</td>
                <td>{he.date}</td>
                <td>{he.place}</td>
                <td>{he.male}</td>
                <td>{he.female}</td>
                <td>{he.total}</td>
                <td>{he.volunteer?.township?.name}</td>
                <td>
                  <Button
                    size="sm"
                    color="success"
                    onClick={async () => {
                      await getHE(he.id)
                      setIsOpen(true)
                    }}
                  >
                    Detail
                  </Button>
                </td>
              </tr>
            ))}
          />

          {he.total > paginationLimit && (
            <CustomPagination pageCount={he.total / paginationLimit} />
          )}
        </Card>
      </Container>

      <HEDetail isOpen={isOpen} toggle={setIsOpen} />
    </>
  )
}

const mapStateToProps = (store) => ({
  status: store.status,
  auth: store.auth,
  township: store.township,
  volunteer: store.volunteer,
  he: store.he,
})

export default connect(mapStateToProps, {
  getHEs,
  getHE,
  getVolunteers,
  getTownships,
})(HEList)
