import {
  CREATE_PATIENT,
  DELETE_PATIENT,
  LATEST_PRESUMPTIVE_TB_NUMBER,
  SET_PATIENT,
  SHOW_PATIENTS,
  UPDATE_PATIENT,
} from '../type'

const initialState = {
  patients: [],
  patient: {},
  total: 0,
  latestPresumptiveTBNumber: null,
}

const patient = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_PATIENTS:
      return {
        ...state,
        patients: action.payload.data,
        total: action.payload.total,
      }
    case CREATE_PATIENT:
      return {
        ...state,
        patients: [action.payload, ...state.patients],
        total: state.total + 1,
      }
    case DELETE_PATIENT:
      return {
        ...state,
        patients: state.patients.filter(
          (patient) => patient.id !== action.payload,
        ),
        total: state.total - 1,
      }
    case SET_PATIENT:
      return {
        ...state,
        patient: action.payload,
      }
    case UPDATE_PATIENT:
      let index = state.patients.findIndex(
        (patient) => patient.id === action.payload.id,
      )
      state.patients[index] = action.payload
      if (state.patient.id === action.payload.id) {
        state.patient = action.payload
      }
      return {
        ...state,
      }
    case LATEST_PRESUMPTIVE_TB_NUMBER:
      return {
        ...state,
        latestPresumptiveTBNumber: action.payload.data,
      }
    default:
      return state
  }
}

export default patient
