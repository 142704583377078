import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Form,
  Row,
  Col,
  CardBody,
  CardFooter,
  CardTitle,
} from "reactstrap";
import { useParams } from "react-router";
import { updatePatient } from "store/actions";
import CustomDateInput from "components/Inputs/CustomDateInput";
import { currentDate } from "utils/currentDate";
import CustomTextArea from "components/Inputs/CustomTextArea";
import { connect } from "react-redux";
import CustomSelect from "components/Inputs/CustomSelect";
import { VDOTOutcomes } from "variables/options";

const VDOTConclusion = ({ patient, updatePatient }) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm();
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [selectedVDOTEndType, setSelectedVDOTEndType] = useState(null);

  useEffect(() => {
    const { VDOT_end_date, VDOT_end_type, VDOT_end_remark } = patient.patient;

    setValue("VDOT_end_date", VDOT_end_date);
    setValue(
      "VDOT_end_type",
      VDOT_end_type ? { value: VDOT_end_type, label: VDOT_end_type } : null
    );
    setSelectedVDOTEndType(
      VDOT_end_type ? { value: VDOT_end_type, label: VDOT_end_type } : null
    );
    setValue("VDOT_end_remark", VDOT_end_remark);
  }, [setValue, patient.patient]);

  const onSubmit = async (data) => {
    setLoading(true);
    await updatePatient(
      id,
      {
        VDOT_end_date: data.VDOT_end_date,
        VDOT_end_type: data.VDOT_end_type?.value,
        VDOT_end_remark: data.VDOT_end_remark,
      },
      "VDOT_end",
      "VDOT Conclusion"
    );
    setLoading(false);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <CardBody>
        <CardTitle>
          <h3>VDOT Conclusion</h3>
        </CardTitle>
        <Row>
          <Col md={4}>
            <CustomDateInput
              id="VDOT_end_date"
              label="Date"
              register={{
                ...register("VDOT_end_date", {
                  required: false,
                }),
              }}
              placeholder="Date"
              errors={errors}
              max={currentDate()}
              isRequired={false}
            />
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <CustomSelect
              id="VDOT_end_type"
              label="VOT Outcomes"
              control={control}
              options={VDOTOutcomes}
              rules={{
                required: false,
              }}
              value={selectedVDOTEndType}
              setData={setSelectedVDOTEndType}
              placeholder="VOT Outcomes"
              isRequired={false}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <CustomTextArea
              id="VDOT_end_remark"
              label="Remark"
              register={{
                ...register("VDOT_end_remark"),
              }}
              placeholder="Enter Remark"
              errors={errors}
              isRequired={false}
            />
          </Col>
        </Row>
      </CardBody>
      <CardFooter className="text-right">
        <Button color="primary" size="sm" disabled={loading} type="submit">
          {loading ? "Loading" : "Update"}
        </Button>
      </CardFooter>
    </Form>
  );
};

const mapStateToProps = (store) => ({
  patient: store.patient,
});

export default connect(mapStateToProps, { updatePatient })(VDOTConclusion);
