import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  Row,
} from 'reactstrap'
import BackBtn from 'utils/backBtn'
import { connect } from 'react-redux'
import {
  getVolunteers,
  getCommunity,
  updateCommunity,
  deleteCommunity,
} from 'store/actions'
import CustomSelect from 'components/Inputs/CustomSelect'
import FullScreenLoading from 'components/FullScreenLoading/FullScreenLoading'
import CustomDateInput from 'components/Inputs/CustomDateInput'
import { currentDate } from 'utils/currentDate'
import { yesOrNo } from 'variables/options'
import CustomNumberInput from 'components/Inputs/CustomNumberInput'
import { referralPlaces } from 'variables/options'
import CustomTable from 'components/Table/Table'
import { NotificationManager } from 'react-notifications'
import { useHistory, useParams } from 'react-router'
import CustomAlert from 'components/Alert/CustomAlert'

const CommunityDetail = ({
  status,
  volunteer,
  community,
  getVolunteers,
  getCommunity,
  updateCommunity,
  deleteCommunity,
}) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = useForm()
  const { id } = useParams()

  const router = useHistory()
  const [alert, setAlert] = useState(null)
  const [loading, setLoading] = useState(false)
  const [selectedVolunteer, setSelectedVolunteer] = useState(null)
  const [referral, setReferral] = useState(null)
  const [optionReferralPlaces, setOptionReferralPlaces] = useState([])
  const [referralPlace, setReferralPlace] = useState(null)
  const [referrals, setReferrals] = useState([])

  const volunteers = volunteer.volunteers.map((element) => ({
    value: element.id,
    label: element.name,
  }))

  useEffect(() => {
    getVolunteers()
  }, [getVolunteers])

  useEffect(() => {
    getCommunity(id)
  }, [getCommunity, id])

  useEffect(() => {
    const { volunteer, date, referral, referrals } = community.community

    setValue('volunteer', { value: volunteer?.id, label: volunteer?.name })
    setSelectedVolunteer({
      value: volunteer?.id,
      label: volunteer?.name,
    })
    setValue('date', date)
    setValue('referral', {
      value: referral ? 'Yes' : 'No',
      label: referral ? 'Yes' : 'No',
    })
    setReferral({
      value: referral ? 'Yes' : 'No',
      label: referral ? 'Yes' : 'No',
    })
    let option = []

    referralPlaces.forEach((refP) => {
      let index = referrals?.findIndex((value) => value.place === refP.value)

      if (index === -1) {
        option.push(refP)
      }
    })
    setOptionReferralPlaces(option)
    setReferrals(referrals)
  }, [community.community, setValue])

  const handleReferrals = () => {
    const male = getValues('male')
    const female = getValues('female')
    const total = getValues('total')
    const place = getValues('place')

    if (male) {
      if (female) {
        if (total) {
          if (place) {
            const addReferral = {
              male,
              female,
              total,
              place: place.value,
            }

            setReferrals([...referrals, addReferral])
            const filter = optionReferralPlaces.filter(
              (value) => place !== value,
            )
            setOptionReferralPlaces(filter)
            setValue('male', '')
            setValue('female', '')
            setValue('total', '')
            setValue('referral_place', null)
            setReferralPlace(null)
          } else {
            NotificationManager.warning('Please select referral place!')
          }
        } else {
          NotificationManager.warning('Please enter total!')
        }
      } else {
        NotificationManager.warning('Please enter female!')
      }
    } else {
      NotificationManager.warning('Please enter male!')
    }
  }

  const onSubmit = async (data) => {
    setLoading(true)
    if (referrals.length === 0 && data.referral.value === 'Yes') {
      NotificationManager.warning('Please insert referral count!')
    } else {
      await updateCommunity(id, {
        volunteer_id: data.volunteer.value,
        date: data.date,
        referral: data.referral.value === 'Yes' ? 1 : 0,
        referrals: referrals,
      })
    }
    setLoading(false)
  }

  const handelDeleteReferral = (obj) => {
    const filter = referrals.filter((value) => obj !== value)
    setReferrals(filter)
    setOptionReferralPlaces([
      ...optionReferralPlaces,
      { value: obj.place, label: obj.place },
    ])
  }

  const handleDelete = () => {
    setAlert(
      <CustomAlert
        onConfirm={() => {
          deleteCommunity(id)
          setAlert(null)
          router.push(`/admin/community-list`)
        }}
        onCancel={() => setAlert(null)}
      />,
    )
  }

  if (status.loading) {
    return <FullScreenLoading />
  }

  return (
    <>
      {alert}
      <Container className="mt-3" fluid>
        <BackBtn />
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Card>
            <CardHeader className="border-0">
              <h3>Detail Community</h3>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md={4}>
                  <CustomSelect
                    id="volunteer"
                    label="Volunteer"
                    control={control}
                    options={volunteers}
                    rules={{
                      required: 'Volunteer is required!',
                    }}
                    value={selectedVolunteer}
                    setData={setSelectedVolunteer}
                    placeholder="Volunteer"
                    isRequired={true}
                  />
                </Col>
                <Col md={4}>
                  <CustomDateInput
                    id="date"
                    label="Date"
                    register={{
                      ...register('date', {
                        required: 'Date is required!',
                      }),
                    }}
                    placeholder="Select Date"
                    errors={errors}
                    isRequired={true}
                    max={currentDate()}
                  />
                </Col>
                <Col md={4}>
                  <CustomSelect
                    id="referral"
                    label="Referral"
                    rules={{
                      required: 'Referral is required!',
                    }}
                    control={control}
                    options={yesOrNo}
                    value={referral}
                    setData={setReferral}
                    placeholder="Referral"
                  />
                </Col>
              </Row>
              {referral?.value === 'Yes' && (
                <>
                  <Row>
                    <h4>ပြည်သူလူထုအတွင်းမှ ညွှန်းပို့သည့်အရေအတွက်</h4>
                  </Row>
                  <Row>
                    <Col md={2}>
                      <CustomNumberInput
                        id="male"
                        label="Male"
                        register={{
                          ...register('male', {
                            required: false,
                          }),
                        }}
                        placeholder="Enter Male"
                        errors={errors}
                        isRequired={false}
                        onChange={(event) => {
                          let male = event.target.value
                          let female = getValues('female')
                          if (male && female) {
                            setValue('total', Number(male) + Number(female))
                          } else {
                            setValue('total', 0)
                          }
                        }}
                      />
                    </Col>
                    <Col md={2}>
                      <CustomNumberInput
                        id="female"
                        label="Female"
                        register={{
                          ...register('female', {
                            required: false,
                          }),
                        }}
                        placeholder="Enter Female"
                        errors={errors}
                        isRequired={false}
                        onChange={(event) => {
                          let female = event.target.value
                          let male = getValues('male')

                          if (male && female) {
                            setValue('total', Number(male) + Number(female))
                          } else {
                            setValue('total', 0)
                          }
                        }}
                      />
                    </Col>
                    <Col md={2}>
                      <CustomNumberInput
                        id="total"
                        label="Total"
                        register={{
                          ...register('total', {
                            required: false,
                          }),
                        }}
                        placeholder="Enter Total"
                        errors={errors}
                        isRequired={false}
                        disabled={true}
                      />
                    </Col>
                    <Col md={4}>
                      <CustomSelect
                        id="place"
                        label="Referral Place"
                        rules={{
                          required: false,
                        }}
                        control={control}
                        options={optionReferralPlaces}
                        value={referralPlace}
                        setData={setReferralPlace}
                        placeholder="Referral Place"
                        isRequired={false}
                      />
                    </Col>
                    <Col md={2}>
                      <Button
                        size="sm"
                        color="success"
                        className="mt-4"
                        onClick={handleReferrals}
                      >
                        Add Referral
                      </Button>
                    </Col>
                  </Row>
                  <Row>
                    <CustomTable
                      header={
                        <tr>
                          <th scope="col">ID</th>
                          <th scope="col">Male</th>
                          <th scope="col">Female</th>
                          <th scope="col">Total</th>
                          <th scope="col">Referral Place</th>
                          <th scope="col">Action</th>
                        </tr>
                      }
                      body={referrals.map((referral, index) => (
                        <tr key={index + referral.place}>
                          <td>{index + 1}</td>
                          <td>{referral.male}</td>
                          <td>{referral.female}</td>
                          <td>{referral.total}</td>
                          <td>{referral.place}</td>
                          <td>
                            <Button
                              size="sm"
                              color="danger"
                              onClick={() => handelDeleteReferral(referral)}
                            >
                              Delete
                            </Button>
                          </td>
                        </tr>
                      ))}
                    />
                  </Row>
                </>
              )}
            </CardBody>
            <CardFooter className="text-right">
              <Button
                color="primary"
                size="sm"
                disabled={loading}
                type="submit"
              >
                {loading ? 'Loading' : 'Update'}
              </Button>
              <Button color="danger" size="sm" onClick={handleDelete}>
                Delete
              </Button>
            </CardFooter>
          </Card>
        </Form>
      </Container>
    </>
  )
}

const mapStateToProps = (store) => ({
  status: store.status,
  volunteer: store.volunteer,
  community: store.community,
})

export default connect(mapStateToProps, {
  getVolunteers,
  getCommunity,
  updateCommunity,
  deleteCommunity,
})(CommunityDetail)
