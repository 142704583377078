import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Col, Form, Row } from "reactstrap";
import CustomModal from "components/Modal/CustomModal";
import { connect } from "react-redux";
import CustomDateInput from "components/Inputs/CustomDateInput";
import { currentDate } from "utils/currentDate";
import CustomTextArea from "components/Inputs/CustomTextArea";
import { createDOTWithVolunteer } from "store/actions";

const DotRemarkCreate = ({
  isOpen,
  toggle,
  status,
  patient,
  createDOTWithVolunteer,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const [loading, setLoading] = useState(false);

  const onSubmit = async (data) => {
    setLoading(true);
    await createDOTWithVolunteer({
      patient_id: patient.patient.id,
      date: data.date,
      remark: data.remark,
    });
    setLoading(false);
  };

  useEffect(() => {
    if (status.success) {
      reset();
    }
  }, [status.success, reset]);

  return (
    <CustomModal
      isOpen={isOpen}
      title="Create VOT"
      onClick={() => {
        toggle(false);
        reset();
      }}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-body">
          <Row>
            <Col>
              <CustomDateInput
                id="date"
                label="Date"
                register={{
                  ...register("date", {
                    required: "Date is required!",
                  }),
                }}
                placeholder="Select Date"
                errors={errors}
                max={currentDate()}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <CustomTextArea
                id="remark"
                label="Remark"
                register={{
                  ...register("remark", {
                    required: "Remark is required!",
                  }),
                }}
                placeholder="Enter Remark"
                errors={errors}
                isRequired={false}
              />
            </Col>
          </Row>
        </div>
        <div className="modal-footer">
          <Button color="primary" size="sm" type="submit" disabled={loading}>
            {loading ? "Loading" : "Save"}
          </Button>
        </div>
      </Form>
    </CustomModal>
  );
};

const mapStateToProps = (store) => ({
  status: store.status,
  patient: store.patient,
});

export default connect(mapStateToProps, {
  createDOTWithVolunteer,
})(DotRemarkCreate);
